import { UNAUTHORIZED } from "constants/auth.constants";
import * as constants from "constants/messages.constants";
import { combineReducers } from "redux";

import { makeListReducer } from "./list";
import pagination from "./pagination";
import { getItemsById } from "./utils";

const initialState = {
  loading: false,
  items: [],
  itemsById: {},
  count: 0,
  allSelected: false,
  anySelected: false,
  columns: {
    subject: true,
    status: true,
    recipients: true,
  },
};

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.MESSAGE_LIST.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.MESSAGE_LIST.FAILURE:
      return {
        ...state,
        items: [],
        itemsById: {},
        count: 0,
        loading: false,
      };
    case constants.MESSAGE_LIST.SUCCESS: {
      const { count, results: items } = action.data;
      return {
        ...state,
        items,
        itemsById: getItemsById(items),
        count: count,
        loading: false,
        allSelected: false,
        anySelected: false,
      };
    }
    case constants.SELECT: {
      let { items } = state;
      items = items.map((it) => {
        if (it.id === action.data.id) {
          it.selected = !it.selected;
        }
        return it;
      });
      return {
        ...state,
        items,
        itemsById: getItemsById(items),
        allSelected: items.every((it) => it.selected === true),
        anySelected: items.some((it) => it.selected === true),
      };
    }
    case constants.SELECT_ALL: {
      let { items, allSelected } = state;

      allSelected = !allSelected;
      items = items.map((it) => {
        it.selected = allSelected;
        return it;
      });

      return {
        ...state,
        items,
        itemsById: getItemsById(items),
        allSelected,
        anySelected: items.some((it) => it.selected === true),
      };
    }
    case constants.SHOW_COLUMNS: {
      const names = action.data;
      const columns = Object.keys(state.columns);

      return {
        ...state,
        columns: columns.reduce(
          (obj, key) => ({ ...obj, [key]: names.includes(key) }),
          {},
        ),
      };
    }
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

const paginationReducer = pagination(constants.PAGINATION, {
  orderBy: "-created",
});

const messageInitialState = {
  id: null,
  title: "",
  body: "",
  scheduled_at: null,
  args: { groups: [] },
  language: null,
  readonly: false,
  found: false,
  error: false,
  loading: false,
}

const currentMessageReducer = (
  state = messageInitialState,
  action,
) => {
  switch (action.type) {
    case constants.MESSAGE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.MESSAGE.FAILURE:
      return {
        ...state,
        found: false,
        error: true,
        loading: false,
      };
    case constants.MESSAGE.SUCCESS: {
      const data = action.data;
      return {
        ...state,
        ...data,
        readonly: data.sent_at !== null,
        found: true,
        error: false,
        loading: false,
      };
    }
    case constants.MESSAGE.RESET: {
      const data = action.data;
      return {
        ...messageInitialState,
        ...data
      };
    }
    case UNAUTHORIZED:
      return messageInitialState;
    default:
      return state;
  }
};


export default combineReducers({
  current: currentMessageReducer,
  list: makeListReducer(messagesReducer, "messages"),
  pagination: paginationReducer,
});
