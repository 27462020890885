import * as constants from "constants/company.constants";
import * as api from "services/api/company";

import { createAction, handleErrors } from "./utils";

export const getCompany =
  (rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.COMPANY.REQUEST));
    return api
      .getCompany()
      .then((company) =>
        dispatch(createAction(constants.COMPANY.SUCCESS, company)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.COMPANY.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const updateCompany =
  (data, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.COMPANY_UPDATE.REQUEST));
    return api
      .updateCompany(data)
      .then((company) =>
        dispatch(createAction(constants.COMPANY_UPDATE.SUCCESS, company)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.COMPANY_UPDATE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const updateCompanyWhiteLabeling =
  (formData, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.COMPANY_UPDATE.REQUEST));
    return api
      .updateCompanyWhiteLabeling(formData)
      .then((company) =>
        dispatch(createAction(constants.COMPANY_UPDATE.SUCCESS, company)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.COMPANY_UPDATE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };
