export const isObject = (obj) => typeof obj === "object" && obj !== null;

export const arrayDiff = (arrA, arrB, symmetric = false) => {
  if (!Array.isArray(arrA) || !Array.isArray(arrB)) {
    throw new Error("One of the arguments is not an array");
  }

  const arrADiff = arrA.filter((it, idx) => {
    if (isObject(it)) {
      return !arrB[idx] || !isEmpty(objDiff(arrA[idx], arrB[idx]));
    }
    return arrB[idx] !== it;
  });

  if (!symmetric) return arrADiff;

  const arrBDiff = arrB.filter((it, idx) => {
    if (isObject(it)) {
      return !arrA[idx] || !isEmpty(objDiff(arrB[idx], arrA[idx]));
    }
    return arrA[idx] !== it;
  });

  return [...arrADiff, ...arrBDiff];
};

export const isEmpty = (obj) =>
  isObject(obj) && Object.keys(obj).length === 0 && obj.constructor === Object;

export const objDiff = (objA, objB, strategy) => {
  let diff = {};

  const getComp = (valueA, valueB) => {
    switch (strategy) {
      case "ours":
        return valueA;
      case "theirs":
        return valueB;
      default:
        return { old: valueA, new: valueB };
    }
  };

  for (let [key, valueA] of Object.entries(objA)) {
    const valueB = objB[key];

    if (Array.isArray(valueA) && Array.isArray(valueB)) {
      const arrDiff = arrayDiff(valueA, valueB, true);
      if (arrDiff.length) {
        diff[key] = getComp(valueA, valueB);
      }
      continue;
    }

    if (valueA instanceof Object && valueB instanceof Object) {
      const valDiff = objDiff(valueA, valueB, strategy);
      if (!isEmpty(valDiff)) diff[key] = valDiff;
      continue;
    }

    if (valueA !== valueB) {
      diff[key] = getComp(valueA, valueB);
    }
  }
  return diff;
};
