import fetch from "lib/fetch";

import { baseUrl, jsonOrReject } from "./utils";

export const getInfo = () => {
  return fetch(`${baseUrl}/info`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};
