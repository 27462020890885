import { UNAUTHORIZED } from "constants/auth.constants";
import * as constants from "constants/certification.constants";
import reduceReducers from "reduce-reducers";
import { combineReducers } from "redux";

import pagination from "./pagination";
import { getItemsById } from "./utils";
import {makeListReducer} from "./list";

const initialState = {
  loading: false,
  items: [],
  itemsById: {},
  count: 0,
  anySelected: false,
  columns: {
    firstName: true,
    lastName: true,
    email: true,
    status: true,
    topic: true,
  },
};

const certificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CERTIFICATION_LIST.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.CERTIFICATION_LIST.FAILURE:
      return {
        ...state,
        items: [],
        itemsById: {},
        count: 0,
        loading: false,
      };
    case constants.CERTIFICATION_LIST.SUCCESS: {
      const { count, results: items } = action.data;
      return {
        ...state,
        items,
        itemsById: getItemsById(items),
        count: count,
        loading: false,
        allSelected: false,
        anySelected: false,
      };
    }
    case constants.SHOW_COLUMNS: {
      const names = action.data;
      const columns = Object.keys(state.columns);

      return {
        ...state,
        columns: columns.reduce(
          (obj, key) => ({ ...obj, [key]: names.includes(key) }),
          {},
        ),
      };
    }
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

const extraPaginationReducer = (
  state = {
    show_all: "false",
  },
  action,
) => {
  switch (action.type) {
    case constants.PAGINATION.TOPIC:
      return {
        ...state,
        page: 0,
        topic: action.data,
      };
    default:
      return state;
  }
};

const basePaginationReducer = pagination(constants.PAGINATION);

const paginationReducer = reduceReducers(
  {
    page: 0,
    perPage: 10,
    orderBy: "topic_name",
    search: "",
    extra: [],
    topic: null,
  },
  extraPaginationReducer,
  basePaginationReducer,
);

const initialTopicState = {
  id: null,
  name: "",
  loading: false,
  error: false,
  found: false,
};

const topicReducer = (state = initialTopicState, action) => {
  switch (action.type) {
    case constants.TOPIC.REQUEST:
      return {
        ...state,
        loading: true,
        found: false,
      };
    case constants.TOPIC.SUCCESS:
      return {
        ...state,
        ...action.data,
        loading: false,
        error: false,
        found: true,
      };
    case constants.TOPIC.FAILURE:
      return {
        ...initialTopicState,
        error: true,
      };
    case constants.CLEAR_TOPIC:
      return {
        ...initialTopicState,
      };
    default:
      return state;
  }
};

export default combineReducers({
  selectedTopic: topicReducer,
  list: makeListReducer(certificationReducer, "certification"),
  pagination: paginationReducer,
});
