import { UNAUTHORIZED } from "constants/auth.constants";
import * as constants from "constants/topics.constants";
import { combineReducers } from "redux";

import pagination from "./pagination";
import { getItemsById } from "./utils";
import {makeListReducer} from "./list";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const mergeEditableField = (it) => {
  const { is_readonly, locked } = it;
  return { ...it, editable: !locked && !is_readonly };
};

export const mergeTypeField = (it) => {
  const { image, video } = it;
  return { ...it, type: image ? "image" : video ? "video" : "text" };
};

const initialState = {
  loading: false,
  items: [],
  itemsById: {},
  count: 0,
  columns: {
    topic: true,
    description: true,
    status: true,
    thumbnail: true,
    questions: true,
    users: true,
    comments: true,
    language: true,
  },
  modals: {
    clone: {
      show: false,
      topic: null,
    },
    publish: {
      show: false,
      topic: null,
    },
    convert: {
      show: false,
      topic: null,
    },
  },
};

const topicsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.TOPICS.REQUEST:
    case constants.TOPIC_UPDATE.REQUEST:
    case constants.TOPIC_DELETE.REQUEST:
    case constants.TOPIC_PUBLISH.REQUEST:
    case constants.TOPIC_UNPUBLISH.REQUEST:
    case constants.TOPICS_DELETE.REQUEST:
    case constants.TOPICS_PUBLISH.REQUEST:
    case constants.TOPICS_UNPUBLISH.REQUEST:
    case constants.TOPIC_IMPORT.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.TOPICS.FAILURE:
      return {
        ...state,
        items: [],
        itemsById: {},
        count: 0,
        loading: false,
      };
    case constants.TOPICS.SUCCESS: {
      let { count, results: topics } = action.data;

      topics = topics.map(mergeEditableField);

      return {
        ...state,
        items: topics,
        itemsById: getItemsById(topics),
        count: count,
        loading: false,
      };
    }
    case constants.TOPIC_DELETE.FAILURE:
    case constants.TOPIC_PUBLISH.FAILURE:
    case constants.TOPIC_UNPUBLISH.FAILURE:
    case constants.TOPIC_IMPORT.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case constants.TOPIC_UPDATE.SUCCESS:
    case constants.TOPIC_DELETE.SUCCESS:
    case constants.TOPIC_PUBLISH.SUCCESS:
    case constants.TOPIC_UNPUBLISH.SUCCESS:
    case constants.TOPIC_IMPORT.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case constants.TOPICS_DELETE.SUCCESS:
    case constants.TOPICS_PUBLISH.SUCCESS:
    case constants.TOPICS_UNPUBLISH.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case constants.SELECT: {
      let { items: topics } = state;
      topics = topics.map((it) => {
        if (it.id === action.data.id) {
          it.selected = !it.selected;
        }
        return it;
      });
      return {
        ...state,
        items: topics,
        itemsById: getItemsById(topics),
        allSelected: topics.every((it) => it.selected === true),
        anySelected: topics.some((it) => it.selected === true),
      };
    }
    case constants.SELECT_ALL: {
      let { items: topics, allSelected } = state;
      allSelected = !allSelected;
      topics = topics.map((it) => {
        it.selected = allSelected;
        return it;
      });
      return {
        ...state,
        items: topics,
        itemsById: getItemsById(topics),
        allSelected,
        anySelected: topics.some((it) => it.selected === true),
      };
    }
    case constants.SHOW_COLUMNS: {
      const names = action.data;
      const columns = Object.keys(state.columns);

      return {
        ...state,
        columns: columns.reduce(
          (obj, key) => ({ ...obj, [key]: names.includes(key) }),
          {},
        ),
      };
    }
    case constants.SHOW_CLONE_MODAL: {
      const topic = action.data;

      return {
        ...state,
        modals: {
          ...state.modals,
          clone: {
            show: true,
            topic,
          },
        },
      };
    }
    case constants.HIDE_CLONE_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          clone: {
            show: false,
            topic: null,
          },
        },
      };
    }
    case constants.SHOW_CONVERT_MODAL: {
      const topic = action.data;

      return {
        ...state,
        modals: {
          ...state.modals,
          convert: {
            show: true,
            topic,
          },
        },
      };
    }
    case constants.HIDE_CONVERT_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          convert: {
            show: false,
            topic: null,
          },
        },
      };
    }
    case constants.SHOW_PUBLISH_MODAL: {
      const topic = action.data;

      return {
        ...state,
        modals: {
          ...state.modals,
          publish: {
            show: true,
            topic,
          },
        },
      };
    }
    case constants.HIDE_PUBLISH_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          publish: {
            show: false,
            topic: null,
          },
        },
      };
    }
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

const initialTopic = {
  loading: false,
  error: null,
  found: false,
  submitted: false,

  is_shared: false,
  locked: false,
  editable: true,
  tags: null,
  title: "",
};

const currentTopic = (state = initialTopic, action) => {
  switch (action.type) {
    case constants.TOPIC.REQUEST:
    case constants.TOPIC_CREATE.REQUEST:
    case constants.TOPIC_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        submitted: false,
      };
    case constants.TOPIC.FAILURE:
      return {
        ...state,
        loading: false,
        found: false,
        error: action.data,
      };
    case constants.TOPIC.SUCCESS: {
      let topic = action.data;
      topic = mergeEditableField(topic);

      return {
        ...state,
        ...topic,
        found: true,
        loading: false,
      };
    }
    case constants.TOPIC_CREATE.SUCCESS:
    case constants.TOPIC_UPDATE.SUCCESS: {
      let topic = action.data;
      topic = mergeEditableField(topic);

      return {
        ...state,
        ...topic,
        submitted: true,
        found: true,
        loading: false,
      };
    }
    case constants.TOPIC_CREATE.FAILURE:
    case constants.TOPIC_UPDATE.FAILURE: {
      return {
        ...state,
        error: action.data,
        submitted: true,
        loading: false,
      };
    }
    case constants.TOPIC_RESET:
      return initialTopic;
    case constants.TOPIC_SET_TAGS: {
      const { tags } = action.data;

      return {
        ...state,
        tags: tags,
      };
    }
    case UNAUTHORIZED:
      return initialTopic;
    default:
      return state;
  }
};

const paginationReducer = pagination(constants.PAGINATION, {
  orderBy: "title",
});

const initialLocalizationListState = {
  loading: false,
  error: false,
  items: [],
  itemsAdded: [],
  itemsRemoved: [],
  itemsById: {},
};

const initialLocalizationState = {
  language: "",
  title: "",
  description: "",
};

const localizationReducer = (state = initialLocalizationListState, action) => {
  switch (action.type) {
    case constants.LOCALIZATION.REQUEST:
      return {
        ...state,
        loading: true,
        ready: false,
      };
    case constants.LOCALIZATION.FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        ready: false,
      };
    case constants.LOCALIZATION.SUCCESS: {
      let items = action.data;

      // Sort items in the way so base topic is always first
      items.sort((a, b) => (a.is_base ? -1 : b.is_base ? 1 : 0));
      const baseTranslation = items.find((it) => it.is_base);
      items = items.map((it) => {
        return {
          ...it,
          use_base_image:
            !it.is_base &&
            Boolean(it.image) &&
            it.image === baseTranslation.image,
        };
      });

      return {
        ...state,
        items,
        itemsById: getItemsById(items),
        itemsAdded: [],
        itemsRemoved: [],
        loading: false,
        ready: true,
      };
    }
    case constants.LOCALIZATION_CREATE.SUCCESS: {
      const data = action.data;
      let { items } = state;

      items = items.map((topic) => {
        if (topic.language === data.language) {
          return data;
        }
        return topic;
      });

      return {
        ...state,
        items,
        itemsById: getItemsById(items),
      };
    }
    case constants.LOCALIZATION_SET_TAGS: {
      const { language, tags } = action.data;

      let { items } = state;

      items = items.map((it) => {
        if (it.language === language) {
          it = { ...it, tags };
        }
        return it;
      });

      return {
        ...state,
        items,
        itemsById: getItemsById(items),
      };
    }
    case constants.LOCALIZATION_RESET:
      return initialLocalizationListState;
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

const currentMeta = (state = initialTopic, action) => {
  switch (action.type) {
    case constants.META_TOPIC.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case constants.META_TOPIC.SUCCESS: {
      let topic = action.data;
      topic = mergeEditableField(topic);

      return {
        ...state,
        ...topic,
        found: true,
        loading: false,
        error: false,
      };
    }
    case constants.META_TOPIC.FAILURE: {
      return {
        ...initialTopic,
        found: false,
        loading: false,
        error: true,
      };
    }
    case constants.META_TOPIC_ADD_TRANSLATION: {
      const data = action.data;
      const { translations } = state;

      return {
        ...state,
        translations: { ...translations, ...data },
      };
    }
    case constants.META_TOPIC_REMOVE_TRANSLATION: {
      const topicId = action.data;
      let { translations } = state;

      Object.keys(translations).forEach((language) => {
        if (translations[language] === topicId) {
          delete translations[language];
        }
      });

      return {
        ...state,
        translations,
      };
    }
    case constants.META_TOPIC_RESET: {
      return {
        ...initialTopic,
      };
    }
    default:
      return state;
  }
};

const initialDefaults = {
  language: undefined,
  translations: undefined
};

const defaultsReducer = (state = initialDefaults, action) => {
  switch (action.type) {
    case constants.SET_DEFAULTS:
      return {
        ...state,
        ...action.data,
      };
      case constants.RESET_DEFAULTS:
      return {
        ...initialDefaults
      };
    default:
      return state;
  }
};

export default combineReducers({
  list: makeListReducer(topicsReducer, "topics"),
  pagination: paginationReducer,
  current: combineReducers({
    topic: currentTopic,
    meta: currentMeta,
    localizations: combineReducers({ list: localizationReducer }),
  }),
  defaults: persistReducer({ key: "topic.defaults", storage: storage}, defaultsReducer)
});
