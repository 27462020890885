import { paginationActions, requestActions } from "../utils";

export const SHARELINKS = requestActions("Shares/Links/List");
export const SHARELINKS_DELETE = requestActions("Shares/Links/List/Delete");
export const PAGINATION = paginationActions("Shares/Links/Paginate");

export const SHARELINK_GET = requestActions("Shares/Links/Get");
export const SHARELINK_CREATE = requestActions("Shares/Links/Create");
export const SHARELINK_UPDATE = requestActions("Shares/Links/Update");
export const SHARELINK_DELETE = requestActions("Shares/Links/Delete");
export const SHARELINK_RESET = "Shares/Links/Reset";

export const SELECT = "Shares/Links/Select";
export const SELECT_ALL = "Shares/Links/Select/All";
export const SHOW_COLUMNS = "Shares/Links/ShowColumns";