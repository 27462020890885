export const requestActions = (name) => ({
  REQUEST: name + "/Request",
  SUCCESS: name + "/Success",
  FAILURE: name + "/Failure",
  RESET: name + "/Reset",
});

export const paginationActions = (name) => ({
  PAGE: name + "/Page",
  PAGE_SIZE: name + "/PageSize",
  ORDER: name + "/Order",
  SEARCH: name + "/Search",
  FILTERS: name + "/Filters",
  RESET: name + "/Reset",
});
