import fetch from "lib/fetch";

import { baseUrl, jsonOrReject } from "./utils";

export const getDashboards = () => {
  return fetch(`${baseUrl}/dashboards`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const createDashboard = (data) => {
  return fetch(`${baseUrl}/dashboards/`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const updateDashboard = (dashboardId, data) => {
  return fetch(`${baseUrl}/dashboards/${dashboardId}`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const deleteDashboard = (dashboardId, data) => {
  return fetch(`${baseUrl}/dashboards/${dashboardId}`, {
    method: "DELETE",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};
