import { paginationActions, requestActions } from "./utils";

export const CERTIFICATION_LIST = requestActions("Certification/List");
export const TOPIC = requestActions("Certification/Topic");
export const CLEAR_TOPIC = "Certification/Topic/Clear";

export const PAGINATION = {
  ...paginationActions("Certification/Paginate"),
  TOPIC: "Certification/Paginate/Topic",
};
export const SELECT = "Certification/Select";
export const SELECT_ALL = "Certification/Select/All";
export const SHOW_COLUMNS = "Certification/ShowColumns";
