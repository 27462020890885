import { combineReducers } from "redux";

import forgotPassword from "./forgotPassword";
import login from "./login";
import logout from "./logout";
import mfa from "./mfa";
import ssoLogin from "./ssoLogin";
import subdomain from "./subdomain";
import user from "./user";

export default combineReducers({
  user,
  login,
  logout,
  forgotPassword,
  ssoLogin,
  subdomain,
  mfa,
});
