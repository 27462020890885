import { UNAUTHORIZED } from "constants/auth.constants";
import * as constants from "constants/groups/groupTrainings.constants";
import { combineReducers } from "redux";

import pagination from "../pagination";
import { getItemsById } from "../utils";

const initialState = {
  loading: false,
  ready: false,
  error: false,

  items: [],
  itemsById: {},
  count: 0,

  indirect: {
    list: [],
    ready: false,
  },

  localList: [],
};

const mergeIndirectTrainings = (trainings, indirectTrainings) => {
  return [
    ...trainings.filter((it) => !it.is_indirect),
    ...indirectTrainings.map((it) => ({ ...it, is_indirect: true })),
  ];
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GROUP_TRAININGS_RESET:
      return {
        ...initialState,
      };
    case constants.GROUP_TRAININGS.REQUEST:
      return {
        ...state,
        loading: true,
        ready: false,
        error: false,
        hasMore: false,
      };
    case constants.GROUP_TRAININGS.FAILURE:
      return {
        ...state,
        loading: false,
        ready: false,
        error: true,
        hasMore: false,
        items: [],
        itemsById: {},
      };
    case constants.GROUP_TRAININGS.SUCCESS: {
      const { count } = action.data;
      let { results: trainings } = action.data;

      trainings = mergeIndirectTrainings(trainings, state.indirect.list);

      return {
        ...state,
        items: trainings,
        itemsById: getItemsById(trainings),
        localList: trainings,
        count: count,
        loading: false,
        ready: true,
        error: false,
      };
    }
    case constants.GROUP_TRAININGS_INDIRECT.SUCCESS: {
      let { results: indirect } = action.data;

      const trainings = mergeIndirectTrainings(state.localList, indirect);

      return {
        ...state,
        indirect: {
          list: indirect,
          ready: true,
        },
        localList: trainings,
      };
    }
    case constants.GROUP_TRAININGS_INDIRECT_RESET: {
      return {
        ...state,
        indirect: {
          list: [],
          ready: false,
        },
        localList: mergeIndirectTrainings(state.localList, []),
      };
    }
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

const paginationReducer = pagination(constants.PAGINATION);

export default combineReducers({
  list: reducer,
  pagination: paginationReducer,
});
