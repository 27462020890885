export default (pagination, defaults = {}) => {
  const initialState = {
    page: 0,
    perPage: 10,
    orderBy: "",
    search: "",
    extra: [],
    timestamp: null,
    ...defaults,
  };

  return (state = initialState, action) => {
    switch (action.type) {
      case pagination.PAGE:
        return {
          ...state,
          page: action.data,
        };
      case pagination.PAGE_SIZE:
        return {
          ...state,
          page: 0,
          perPage: action.data,
        };
      case pagination.ORDER:
        return {
          ...state,
          page: 0,
          orderBy: action.data,
        };
      case pagination.SEARCH:
        return {
          ...state,
          page: 0,
          search: action.data,
        };
      case pagination.FILTERS:
        return {
          ...state,
          page: 0,
          extra: action.data,
        };
      case pagination.RESET:
        return initialState;
      default:
        return state;
    }
  };
};
