/**
 * Generates url based on basename (window.appUrl).
 *
 * It would be better to use Link and useHref provided by react-router-dom,
 * but we still use class components in some places.
 *
 * @param args
 * @returns {string}
 */

export const createUrl = (...args) => {
  const basename = window.appUrl;
  const paths = [];

  [basename, ...args].forEach((arg) => {
    if (!arg) return;
    let path = String(arg);
    if (path.startsWith("/")) path = path.slice(1);
    if (path.endsWith("/")) path = path.slice(0, -1);

    if (path) paths.push(path);
  });

  let url = `/${paths.join("/")}`;
  let qs;

  [url, qs] = url.split("?");

  if (!url.endsWith("/")) {
    url = `${url}/`
  }
  if (qs) {
    url = `${url}?${qs}`
  }
  return url;
};


export const isUrl = (url) => {
  const regex =  /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;
  return regex.exec(url);
}