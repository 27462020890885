import { paginationActions, requestActions } from "./utils";

export const INTRODUCTION = requestActions("Introduction/List");
export const INTRODUCTION_REPLACE_INDEX = requestActions(
  "Introduction/ReplaceIndex",
);
export const INTRODUCTION_PUBLISH = requestActions("Introduction/Publish");
export const INTRODUCTION_UNPUBLISH = requestActions("Introduction/Unpublish");
export const INTRODUCTION_DELETE = requestActions("Introduction/Delete");
export const TRANSLATIONS = requestActions("Introduction/Translations/List");
export const TRANSLATIONS_RESET = "Introduction/Translations/List/Reset";
export const INTRODUCTION_ITEM = requestActions("Introduction/Item/Get");
export const INTRODUCTION_ITEM_UPDATE = requestActions(
  "Introduction/Item/Update",
);
export const INTRODUCTION_ITEM_CREATE = requestActions(
  "Introduction/Item/Create",
);
export const INTRODUCTION_ITEM_PUBLISH = requestActions(
  "Introduction/Item/Publish",
);
export const INTRODUCTION_ITEM_UNPUBLISH = requestActions(
  "Introduction/Item/Unpublish",
);
export const INTRODUCTION_ITEM_DELETE = requestActions(
  "Introduction/Item/Delete",
);
export const INTRODUCTION_ITEM_RESET = "Introduction/Item/Reset";
export const SELECT = "Introduction/Select";
export const SELECT_ALL = "Introduction/Select/All";
export const SHOW_COLUMNS = "Introduction/ShowColumns";
export const PAGINATION = paginationActions("Introduction/Paginate");
export const META_INTRODUCTION = requestActions("Introduction/Meta/Get");
export const META_INTRODUCTION_CREATE = requestActions(
  "Introduction/Meta/Create",
);
export const META_INTRODUCTION_RESET = "Introduction/Meta/Reset";

export const AUTO_TRANSLATE_MODAL = {
  SHOW: "Introduction/Modals/AutoTranslate/Show",
  HIDE: "Introduction/Modals/AutoTranslate/Hide",
};
