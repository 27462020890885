import { getCookie } from "utils/cookies";

const mergeXsrfToken = (options) => {
  return {
    ...options,
    headers: {
      ...(options?.headers || {}),
      "knowingo-xsrf": getCookie("knowingo-xsrf"),
    },
  };
};

export default (
  (originalFetch) => (resource, options) =>
    originalFetch.apply(this, [resource, mergeXsrfToken(options)])
)(fetch);
