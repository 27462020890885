import fetch from "lib/fetch";

import { baseUrl, fetchAll, formatListParams, jsonOrReject } from "./utils";

export const getUsers = (args) => {
  const params = formatListParams(args);
  return fetch(`${baseUrl}/users/${params}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const getUser = (userId) => {
  return fetch(`${baseUrl}/users/${userId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const updateUser = (userId, data) => {
  return fetch(`${baseUrl}/users/${userId}`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const getUserTeams = (userId, args) => {
  const params = formatListParams(args);
  return fetch(`${baseUrl}/users/${userId}/teams/${params}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const getUserTeamsAll = (userId) => {
  return fetchAll((args) => getUserTeams(userId, args));
};

export const getUserCerts = (userId, args) => {
  const params = formatListParams(args);
  return fetch(`${baseUrl}/users/${userId}/certificates/${params}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const getUserCertsAll = (userId) => {
  return fetchAll((args) => getUserCerts(userId, args));
};

export const getUserCertsInfo = (userId) => {
  return fetch(`${baseUrl}/users/${userId}/certificates/info`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const getUserTrainings = (userId, args) => {
  const params = formatListParams(args);
  return fetch(`${baseUrl}/users/${userId}/trainings/${params}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const getUserTrainingsAll = (userId) => {
  return fetchAll((args) => getUserTrainings(userId, args));
};

export const getUserCompliance = (userId, args) => {
  const params = formatListParams(args);
  return fetch(`${baseUrl}/users/${userId}/compliances/${params}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const getUserComplianceAll = (userId) => {
  return fetchAll((args) => getUserCompliance(userId, args));
};

export const setUserRoles = (userId, role) => {
  return updateUser(userId, {
    role,
  });
};

export const enableUser = (userId) => {
  return fetch(`${baseUrl}/users/${userId}/enable`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const sendConfirmation = (userId) => {
  return fetch(`${baseUrl}/users/${userId}/send_confirmation`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const requestConfirmationLink = (userId) => {
  return fetch(`${baseUrl}/users/${userId}/activate`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const disableUser = (userId) => {
  return fetch(`${baseUrl}/users/${userId}/disable`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const deleteUser = (userId) => {
  return fetch(`${baseUrl}/users/${userId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const bulkEnableUsers = (userIds) => {
  return fetch(`${baseUrl}/users/bulk/enable`, {
    method: "POST",
    body: JSON.stringify({ ids: userIds }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const requestPasswordReset = (userId, data) => {
  return fetch(`${baseUrl}/users/${userId}/password_reset`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const bulkDisableUsers = (userIds) => {
  return fetch(`${baseUrl}/users/bulk/disable`, {
    method: "POST",
    body: JSON.stringify({ ids: userIds }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const bulkDeleteUsers = (userIds) => {
  return fetch(`${baseUrl}/users/bulk/delete`, {
    method: "POST",
    body: JSON.stringify({ ids: userIds }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const getUserCertificates = (userId, args) => {
  const params = formatListParams({ ...args, format: "pdf" });
  return fetch(`${baseUrl}/users/${userId}/certificates/download${params}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};
