import { UNAUTHORIZED } from "constants/auth.constants";
import * as constants from "constants/overview.constants";
import { combineReducers } from "redux";

const initialState = {
  loading: false,
  knowledge_level: 0.0,
  learning_progress: 0.0,
  knowledge_multiplier: 0.0,
  mastered: 0,
  num_active_users: 0,
  num_categories: 0,
  num_library_shares: 0,
  num_online_users: 0,
  num_questions: 0,
  num_seats: 0,
  num_sharelink_shares: 0,
  num_used_seats: 0,
  num_users: 0,
  num_answers: 0,
  num_correct_answers: 0,
};

const companyInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.COMPANY_INFO.REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case constants.COMPANY_INFO.SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.data,
      };
    case constants.COMPANY_INFO.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

const companyInfoAlltimeReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.COMPANY_INFO_ALLTIME.REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case constants.COMPANY_INFO_ALLTIME.SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.data,
      };
    case constants.COMPANY_INFO_ALLTIME.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

const companyInfoTodayReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.COMPANY_INFO_TODAY.REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case constants.COMPANY_INFO_TODAY.SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.data,
      };
    case constants.COMPANY_INFO_TODAY.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

export default combineReducers({
  companyInfo: companyInfoReducer,
  companyInfoToday: companyInfoTodayReducer,
  companyInfoAlltime: companyInfoAlltimeReducer,
});
