import fetch from "lib/fetch";

import { baseUrl, formatListParams, jsonOrReject } from "./utils";

export const getCurrentUser = () => {
  return fetch(`${baseUrl}/auth/info`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const updateCurrentUser = (data) => {
  return fetch(`${baseUrl}/auth/info`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const login = (email, password, rememberMe = false) => {
  return fetch(`${baseUrl}/auth/login`, {
    method: "POST",
    body: JSON.stringify({ email, password, remember_me: rememberMe }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const logout = () => {
  return fetch(`${baseUrl}/auth/logout`, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const forgotPassword = (email) => {
  return fetch(`${baseUrl}/auth/reset-password`, {
    method: "POST",
    body: JSON.stringify({ email }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const resendConfirmationRequest = (email) => {
  return fetch(`${baseUrl}/auth/resend-confirmation-request`, {
    method: "POST",
    body: JSON.stringify({ email }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const getLoginProviders = () => {
  return fetch(`${baseUrl}/auth/get-login-providers`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const getLoginProviderURL = (provider) => {
  return fetch(`${baseUrl}/auth/get-login-url?provider=${provider}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const getSubdomainInfo = (subdomain) => {
  return fetch(`${baseUrl}/subdomains/info/${subdomain}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const getModeratedCompanies = (args) => {
  const params = formatListParams({ beta: "true", ...args });

  return fetch(`${baseUrl}/auth/moderated` + params, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const getMfaSetup = () => {
  return fetch(`${baseUrl}/auth/mfa/setup`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const authenticateMFA = (data) => {
  return fetch(`${baseUrl}/auth/mfa`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const getApiKey = () => {
  return fetch(`${baseUrl}/auth/keys`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const createApiKey = (data) => {
  return fetch(`${baseUrl}/auth/keys`, {
    method: "POST",
    body: data,
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const deleteApiKey = () => {
  return fetch(`${baseUrl}/auth/keys`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const getApiConfig = () => {
  return fetch(`${baseUrl}/auth/api-config`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};