import { paginationActions, requestActions } from "../utils";

export const LIBRARY_SHARES = requestActions("Shares/Library/List");
export const PAGINATION = paginationActions("Shares/Library/Paginate");

export const LIBRARY_SHARE_GET = requestActions("Shares/Library/Get");
export const LIBRARY_SHARE_CREATE = requestActions("Shares/Library/Create");
export const LIBRARY_SHARE_UPDATE = requestActions("Shares/Library/Update");

export const SELECT = "Shares/Library/Select";
export const SELECT_ALL = "Shares/Library/Select/All";
export const SHOW_COLUMNS = "Shares/Library/ShowColumns";