import * as constants from "constants/info.constants";
import * as api from "services/api/info";

import { createAction, handleErrors } from "./utils";

export const getInfo =
  (rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.INFO.REQUEST));
    return api
      .getInfo()
      .then((info) => dispatch(createAction(constants.INFO.SUCCESS, info)))
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.INFO.FAILURE));
        if (rethrowOnError) throw error;
      });
  };
