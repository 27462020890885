import { paginationActions, requestActions } from "./utils";

export const MESSAGE_LIST = requestActions("Messages/List");
export const BULK_UPDATE = requestActions("Messages/Bulk/Update");

export const MESSAGE = requestActions("Message/Get");
export const MESSAGE_UPDATE = requestActions("Message/Update");

export const PAGINATION = paginationActions("Messages/Paginate");
export const SELECT = "Messages/Select";
export const SELECT_ALL = "Messages/Select/All";
export const SHOW_COLUMNS = "Messages/ShowColumns";