import * as constants from "constants/auth.constants";
import { MFA_INFO, MFA_LOGIN } from "constants/mfa.constants";
import * as api from "services/api/auth";

import { createAction, handleErrors } from "./utils";

export const getCurrentUser = () => (dispatch) => {
  dispatch(createAction(constants.USER.REQUEST));
  return api
    .getCurrentUser()
    .then((user) => dispatch(createAction(constants.USER.SUCCESS, user)))
    .catch((error) => {
      dispatch(handleErrors(error));
      dispatch(createAction(constants.USER.FAILURE));
    });
};

export const updateCurrentUser =
  (data, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.USER_UPDATE.REQUEST));
    return api
      .updateCurrentUser(data)
      .then((user) =>
        dispatch(createAction(constants.USER_UPDATE.SUCCESS, user)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.USER_UPDATE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const toggleDashboardTheme = () => (dispatch, getState) => {
  const {
    auth: {
      user: { dashboard_theme: dashboardTheme },
    },
  } = getState();

  const newState = dashboardTheme === "light" ? "dark" : "light";
  return dispatch(updateCurrentUser({ dashboard_theme: newState }));
};

export const login =
  (email, password, rememberMe = false, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.LOGIN.REQUEST));
    return api
      .login(email, password, rememberMe)
      .then((data) => {
        dispatch(createAction(constants.LOGIN.SUCCESS, data));
      })
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.LOGIN.FAILURE, error.message || {}));
        if (rethrowOnError) throw error;
      });
  };

export const logout =
  (rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.LOGOUT.REQUEST));

    return api
      .logout()
      .then(() => {
        localStorage.clear();

        dispatch(createAction(constants.LOGOUT.SUCCESS));
        dispatch(createAction(constants.UNAUTHORIZED));
      })
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.LOGOUT.FAILURE, error));
        if (rethrowOnError) throw error;
      });
  };

export const resendConfirmationRequest = (email) => () => {
  return api.resendConfirmationRequest(email);
};

export const forgotPassword =
  (email, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.FORGOT_PASSWORD.REQUEST));
    return api
      .forgotPassword(email)
      .then(() => dispatch(createAction(constants.FORGOT_PASSWORD.SUCCESS)))
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.FORGOT_PASSWORD.FAILURE, error));
        if (rethrowOnError) throw error;
      });
  };

export const getLoginProviders =
  (rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.SSO_PROVIDERS.REQUEST));
    return api
      .getLoginProviders()
      .then((data) =>
        dispatch(createAction(constants.SSO_PROVIDERS.SUCCESS, data)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.SSO_PROVIDERS.FAILURE, error));
        if (rethrowOnError) throw error;
      });
  };

export const getLoginProviderURL =
  (provider, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.SSO_URL.REQUEST));
    return api
      .getLoginProviderURL(provider)
      .then((data) => dispatch(createAction(constants.SSO_URL.SUCCESS, data)))
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.SSO_URL.FAILURE, error));
        if (rethrowOnError) throw error;
      });
  };

export const getSubdomainInfo =
  (subdomain, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.SUBDOMAIN.REQUEST));
    return api
      .getSubdomainInfo(subdomain)
      .then((data) => dispatch(createAction(constants.SUBDOMAIN.SUCCESS, data)))
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.SUBDOMAIN.FAILURE, error));
        if (rethrowOnError) throw error;
      });
  };

export const resetSubdomainInfo = () => (dispatch) => {
  dispatch(createAction(constants.SUBDOMAIN.RESET));
};

export const getMfaSetup =
  (rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(MFA_INFO.REQUEST));
    return api
      .getMfaSetup()
      .then((data) => dispatch(createAction(MFA_INFO.SUCCESS, data)))
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(MFA_INFO.FAILURE, error));
        if (rethrowOnError) throw error;
      });
  };

export const authenticateMFA =
  (data, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(MFA_LOGIN.REQUEST));
    return api
      .authenticateMFA(data)
      .then((data) => {
        dispatch(createAction(MFA_LOGIN.SUCCESS, data));
        return dispatch(getCurrentUser());
      })
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(MFA_LOGIN.FAILURE, error));
        if (rethrowOnError) throw error;
      });
  };

export const getApiKey =
  (rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.API_KEY.REQUEST));
    return api
      .getApiKey()
      .then((res) =>
        dispatch(createAction(constants.API_KEY.SUCCESS, res)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.API_KEY.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const deleteApiKey =
  (rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.DELETE_API_KEY.REQUEST));
    return api
      .deleteApiKey()
      .then(() =>
        dispatch(createAction(constants.DELETE_API_KEY.SUCCESS)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.DELETE_API_KEY.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const createApiKey =
  (rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.CREATE_API_KEY.REQUEST));
    return api
      .createApiKey()
      .then((res) =>
        dispatch(createAction(constants.CREATE_API_KEY.SUCCESS, res)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.CREATE_API_KEY.FAILURE));
        if (rethrowOnError) throw error;
      });
  };
