import { UNAUTHORIZED } from "constants/auth.constants";
import * as constants from "constants/marketplace.constants";
import { combineReducers } from "redux";

import { makeListReducer } from "./list";
import pagination from "./pagination";
import { getItemsById } from "./utils";
import reduceReducers from "reduce-reducers";

const listInitialState = {
  loading: false,
  items: [],
  itemsById: {},
  count: 0,
  tagCloud: [],
  languageCloud: []
};

const listReducer = (state = listInitialState, action) => {
  switch (action.type) {
    case constants.BUNDLE_LIST.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.BUNDLE_LIST.FAILURE:
      return {
        ...listInitialState,
      };
    case constants.BUNDLE_LIST.SUCCESS: {
      const { count, results: items, tag_cloud, language_cloud } = action.data;
      return {
        ...state,
        items,
        itemsById: getItemsById(items),
        count: count,
        loading: false,
        allSelected: false,
        anySelected: false,
        tagCloud: tag_cloud,
        languageCloud: language_cloud
      };
    }
    case UNAUTHORIZED:
      return listInitialState;
    default:
      return state;
  }
};

const itemInitialState = {
  found: false,
  error: false,
  loading: false,
  topics: [],
  courses: [],
  tags: [],
  num_courses: 0,
  num_topics: 0,
  num_questions: 0,
  num_background_information: 0,
  num_introduction_material: 0
};

const itemReducer = (state = itemInitialState, action) => {
  switch (action.type) {
    case constants.BUNDLE.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case constants.BUNDLE.FAILURE:
      return {
        ...state,
        found: false,
        error: true,
        loading: false,
      };
    case constants.BUNDLE.SUCCESS: {
      const data = action.data;
      return {
        ...state,
        ...data,
        found: true,
        loading: false,
      };
    }
    case UNAUTHORIZED:
      return itemInitialState;
    default:
      return state;
  }
};

const extraPaginationReducer = (
  state = {
    show_all: "false",
  },
  action,
) => {
  switch (action.type) {
    case constants.PAGINATION.TAGS:
      return {
        ...state,
        page: 0,
        tags: action.data,
      };
    case constants.PAGINATION.LANGUAGE:
      return {
        ...state,
        page: 0,
        language: action.data,
      };
    default:
      return state;
  }
};

const basePaginationReducer = pagination(constants.PAGINATION);

const paginationReducer = reduceReducers(
  {
    page: 0,
    perPage: 10,
    orderBy: "-created",
    search: "",
    extra: [],
    expand: "all",
    tags: [],
    language: [],
  },
  extraPaginationReducer,
  basePaginationReducer,
);
export default combineReducers({
  list: makeListReducer(listReducer, "marketplace"),
  pagination: paginationReducer,
  current: combineReducers({
    bundle: itemReducer,
  }),
});
