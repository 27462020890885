import { HIDE_MODAL, SHOW_MODAL } from "../constants/modal.constants";

const initialState = {
  type: null,
  props: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        type: action.data.type,
        props: action.data.props,
      };
    case HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
};
