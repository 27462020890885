import { paginationActions, requestActions } from "../utils";

export const GROUP_TOPICS = requestActions("GroupCategories/List/All");
export const GROUP_TOPICS_UPDATE = requestActions(
  "GroupCategories/List/Update",
);
export const GROUP_TOPICS_RESET = "GroupCategories/List/Reset";
export const PAGINATION = paginationActions("GroupCategories/Paginate");
export const GROUP_TOPICS_INDIRECT = requestActions(
  "GroupCategories/Indirect/List/Get",
);
export const GROUP_TOPICS_INDIRECT_RESET =
  "GroupCategories/Indirect/List/Reset";
export const GROUP_TOPICS_LOCAL_ADD = "GroupCategories/Local/List/Add";
export const GROUP_TOPICS_LOCAL_REMOVE = "GroupCategories/Local/List/Remove";
export const GROUP_TOPICS_LOCAL_UPDATE = "GroupCategories/Local/List/Update";
