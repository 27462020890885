import * as constants from "constants/dashboard.constants";
import * as api from "services/api/dashboard";

import { DASHBOARD_ID } from "../localStorage/constants";
import { createAction, handleErrors } from "./utils";

const defaultDashboard = {
  name: "Default",
  readonly: true,
  id: null,
  widgets: [
    {
      i: "knowledgeChart",
      x: 0,
      y: 0,
      w: 6,
      h: 12,
    },
    {
      i: "learningProgressChart",
      x: 6,
      y: 0,
      w: 6,
      h: 12,
    },
    {
      i: "questionsMasteredChart",
      x: 0,
      y: 12,
      w: 6,
      h: 12,
    },
    {
      i: "trainingTimeChart",
      x: 6,
      y: 12,
      w: 6,
      h: 12,
    },
  ],
};

export const toggleSidebar = () => createAction(constants.TOGGLE_SIDEBAR);

export const toggleNotification = (state) =>
  createAction(constants.TOGGLE_NOTIFICATION, state);

export const toggleCompanySelectDialog = (state) =>
  createAction(constants.TOGGLE_COMPANY_SELECTION_DIALOG, state);

export const toggleMobileSidebar = (value) =>
  createAction(constants.TOGGLE_MOBILE_SIDEBAR, value);

export const toggleSelectWidgetsDialog = (value) =>
  createAction(constants.TOGGLE_SELECT_WIDGETS_DIALOG, value);

export const toggleSettingsDialog = (value) =>
  createAction(constants.TOGGLE_SETTINGS_DIALOG, value);

export const createNewDashboard = () =>
  createAction(constants.CREATE_NEW_DASHBOARD);

export const copyDashboard = () => createAction(constants.COPY_DASHBOARD);

export const toggleEditable = (state) =>
  createAction(constants.TOGGLE_EDITABLE, state);

export const selectDashboard = (dashboardId) => (dispatch, getState) => {
  const {
    dashboard: {
      list: { itemsById },
    },
  } = getState();

  if (!itemsById[dashboardId]) {
    throw new Error("Invalid dashboard id");
  }

  localStorage.setItem(DASHBOARD_ID, dashboardId);

  return dispatch(
    createAction(constants.SELECT_DASHBOARD, itemsById[dashboardId]),
  );
};

export const selectDefault = () =>
  createAction(constants.SELECT_DASHBOARD, defaultDashboard);

export const resetDashboard = () => (dispatch, getState) => {
  const {
    dashboard: {
      current: { id: currentDashboardId },
    },
  } = getState();

  if (currentDashboardId) {
    dispatch(selectDashboard(currentDashboardId));
  } else {
    dispatch(selectDefault());
  }
};

export const updateDashboard = (data) =>
  createAction(constants.UPDATE_DASHBOARD, data);

export const addWidget = (data) => createAction(constants.ADD_WIDGET, data);

export const removeWidget = (index) =>
  createAction(constants.REMOVE_WIDGET, index);

export const getDashboards =
  (rethrowOnError = false) =>
  (dispatch) => {
    createAction(constants.GET_DASHBOARDS.REQUEST);
    return api
      .getDashboards()
      .then((response) =>
        dispatch(createAction(constants.GET_DASHBOARDS.SUCCESS, response)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.GET_DASHBOARDS.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const saveDashboard =
  (rethrowOnError = false) =>
  (dispatch, getState) => {
    const {
      dashboard: { current: currentDashboard },
    } = getState();

    let req = currentDashboard?.id
      ? () => api.updateDashboard(currentDashboard.id, currentDashboard)
      : () => api.createDashboard(currentDashboard);

    createAction(constants.SAVE_DASHBOARD.REQUEST);
    return req()
      .then((response) =>
        dispatch(createAction(constants.SAVE_DASHBOARD.SUCCESS, response)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.SAVE_DASHBOARD.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const deleteDashboard =
  (rethrowOnError = false) =>
  (dispatch, getState) => {
    const {
      dashboard: { current: currentDashboard },
    } = getState();

    createAction(constants.DELETE_DASHBOARD.REQUEST);
    return api
      .deleteDashboard(currentDashboard.id)
      .then(() => dispatch(createAction(constants.DELETE_DASHBOARD.SUCCESS)))
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.DELETE_DASHBOARD.FAILURE));
        if (rethrowOnError) throw error;
      });
  };
