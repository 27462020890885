import { paginationActions, requestActions } from "./utils";

export const PAGINATION = {
  ...paginationActions("Questions/Paginate"),
  FILTER_TAGS: "Questions/Paginate/FilterTags",
};

export const QUESTION_LIST_GET = requestActions("Questions/List/Get");
export const QUESTION_LIST_UPDATE = requestActions("Questions/List/Update");
export const QUESTION_LIST_UPDATE_ITEM = requestActions("Question/List/Update/Item");

export const QUESTION = requestActions("Question/Get");
export const QUESTION_CREATE = requestActions("Question/Create");
export const QUESTION_UPDATE = requestActions("Question/Update");
export const QUESTION_DELETE = requestActions("Question/Delete");
export const QUESTION_RESET = "Question/Reset";

export const META_QUESTION = requestActions("Question/Meta/Get");
export const META_QUESTION_CREATE = requestActions("Question/Meta/Create");
export const META_QUESTION_RESET = "Question/Meta/Reset";

export const TRANSLATION_LIST = requestActions("Question/Translation/List");
export const TRANSLATION_LIST_RESET = "Question/Translation/List/Reset";

export const TRANSLATION_UPDATE = requestActions("Question/Translation/Update");

export const SELECT = "Questions/Select";
export const SELECT_ALL = "Questions/Select/All";
export const SHOW_COLUMNS = "Questions/ShowColumns";

export const QUESTIONS_SHOW_PANEL = "Questions/Panel/Show";
export const QUESTIONS_HIDE_PANEL = "Questions/Panel/Hide";

export const COPY_MODAL = {
  SHOW: "Questions/Modals/Copy/Show",
  HIDE: "Questions/Modals/Copy/Hide",
};

export const MOVE_MODAL = {
  SHOW: "Questions/Modals/Move/Show",
  HIDE: "Questions/Modals/Move/Hide",
};

export const ADD_TAGS_MODAL = {
  SHOW: "Questions/Modals/AddTags/Show",
  HIDE: "Questions/Modals/AddTags/Hide",
};

export const REMOVE_TAGS_MODAL = {
  SHOW: "Questions/Modals/RemoveTags/Show",
  HIDE: "Questions/Modals/RemoveTags/Hide",
};

export const AUTO_TRANSLATE_MODAL = {
  SHOW: "Questions/Modals/AutoTranslate/Show",
  HIDE: "Questions/Modals/AutoTranslate/Hide",
};

export const QUESTION_COMMENTS = requestActions("Question/Comments/List");
export const QUESTION_COMMENTS_RESET = "Question/Comments/Reset";
export const QUESTION_COMMENT_CREATE = requestActions(
  "Question/Comment/Create",
);
export const QUESTION_COMMENT_DELETE = requestActions(
  "Question/Comment/Delete",
);
export const QUESTION_COMMENTS_PAGINATION = paginationActions(
  "Question/Comments/Paginate",
);
