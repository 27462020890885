import * as constants from "constants/overview.constants";
import * as api from "services/api/company-info.js";

import { createAction, handleErrors } from "./utils";

export const getCompanyInfo =
  (rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.COMPANY_INFO.REQUEST));
    return api
      .getCompanyInfo()
      .then((company) =>
        dispatch(createAction(constants.COMPANY_INFO.SUCCESS, company)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.COMPANY_INFO.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const getCompanyInfoToday =
  (rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.COMPANY_INFO_TODAY.REQUEST));
    return api
      .getCompanyInfoToday()
      .then((company) =>
        dispatch(createAction(constants.COMPANY_INFO_TODAY.SUCCESS, company)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.COMPANY_INFO_TODAY.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const getCompanyInfoAlltime =
  (rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.COMPANY_INFO_ALLTIME.REQUEST));
    return api
      .getCompanyInfoAlltime()
      .then((company) =>
        dispatch(createAction(constants.COMPANY_INFO_ALLTIME.SUCCESS, company)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.COMPANY_INFO_ALLTIME.FAILURE));
        if (rethrowOnError) throw error;
      });
  };
