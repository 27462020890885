import { UNAUTHORIZED } from "constants/auth.constants";
import * as constants from "constants/groups/groupTopics.constants";
import {
  getDaysToCertify,
  getDeadlineSettings,
  getRecommendedDeadline,
} from "pages/groups/details/learningPlan/utils";
import { combineReducers } from "redux";

import pagination from "../pagination";
import { getItemsById } from "../utils";

const groupTopicsInitial = {
  loading: false,
  ready: false,
  error: false,

  items: [],
  itemsById: {},
  count: 0,

  localList: [],
  indirect: {
    list: [],
    ready: false,
  },

  changed: false,
};

const updateRecommendedDeadlines = (topics) => {
  return topics.map((it, idx) => {
    if (!it.is_mandatory) return it;

    return {
      ...it,
      recommended_deadline: getRecommendedDeadline(
        it,
        topics.filter((it) => it.is_mandatory).slice(0, idx),
      ),
    };
  });
};

const flagCourseTopics = (items) => {
  const courses = items.filter(it => it.type === "course")
  let topicsInCourses = [];

  courses.forEach(course => {
    if (course?.topics?.length) {
      topicsInCourses = [...topicsInCourses, ...course.topics.map(topic => topic.id)]
    }
  });

  return items.map(it => ({
    ...it,
    isPresentInCourse: topicsInCourses.includes(it.id)
  }));
}

const groupByCourses = (content) => {
  const courseTopics = {};

  content.forEach((item) => {
    const { course_id: courseId } = item;

    if (courseId) {
      if (!courseTopics[courseId]) courseTopics[courseId] = [];
      courseTopics[courseId].push(item);
    }
  });

  const groupedContent = [];

  content.forEach((item) => {
    const { course_id: courseId, type } = item;

    if (type === "course") {
      groupedContent.push({
        ...item,
        topics: courseTopics[item.id] || [],
      });
    }

    if (type === "topic" && !courseId) {
      groupedContent.push(item);
    }
  });

  return groupedContent;
};

const mergeIndirectTopics = (topics, indirectTopics) => {
  indirectTopics = indirectTopics.map((it) => ({
    ...it,
    is_indirect: true,
  }));

  topics = topics.filter((it) => !it.is_indirect);
  topics = topics.map((topic) => {
    const conflicting = indirectTopics.find((it) => it.id === topic.id && !it.is_compliance);
    if (conflicting) {
      indirectTopics.splice(indirectTopics.indexOf(conflicting), 1);
    }
    return {
      ...topic,
      conflicting,
    };
  });

  return [...topics, ...indirectTopics];
};

const sortByImportance = (a, b) =>
  a.days_to_certify - b.days_to_certify || a.title.localeCompare(b.title);

const groupTopicsReducer = (state = groupTopicsInitial, action) => {
  switch (action.type) {
    case constants.GROUP_TOPICS_RESET:
      return {
        ...groupTopicsInitial,
      };
    case constants.GROUP_TOPICS.REQUEST:
      return {
        ...state,
        loading: true,
        ready: false,
        error: false,
        hasMore: false,
      };
    case constants.GROUP_TOPICS.FAILURE:
      return {
        ...state,
        loading: false,
        ready: false,
        error: true,
        hasMore: false,
        items: [],
        itemsById: {},
      };
    case constants.GROUP_TOPICS.SUCCESS: {
      let { count, results } = action.data;

      results = groupByCourses(results);

      let localList = mergeIndirectTopics(results, state.indirect.list);
      localList = updateRecommendedDeadlines(localList);
      localList = flagCourseTopics(localList);
      localList.sort(sortByImportance);

      return {
        ...state,
        items: results,
        localList: localList,
        itemsById: getItemsById(results),
        count: count,
        loading: false,
        ready: true,
        error: false,
      };
    }
    case constants.GROUP_TOPICS_LOCAL_ADD: {
      let topic = action.data;

      if (topic.is_mandatory) {
        const recommendedDeadline = getRecommendedDeadline(
          topic,
          state.localList.filter((it) => it.is_mandatory),
        );
        const deadlineSettings = getDeadlineSettings(recommendedDeadline);

        topic = {
          ...topic,
          ...deadlineSettings,
          recommended_deadline: recommendedDeadline,
          days_to_certify: getDaysToCertify(
            deadlineSettings.text_amount,
            deadlineSettings.text_period,
          ),
        };
      }

      let localList = state.localList.filter((it) => it.id !== topic.id);
      localList = [...localList, topic];
      localList = mergeIndirectTopics(localList, state.indirect.list);
      localList = updateRecommendedDeadlines(localList);
      localList = flagCourseTopics(localList);
      localList.sort(sortByImportance);

      return {
        ...state,
        changed: true,
        localList,
      };
    }
    case constants.GROUP_TOPICS_LOCAL_REMOVE: {
      const topic = action.data;

      let localList = state.localList.filter((it) => it.id !== topic.id);
      localList = mergeIndirectTopics(localList, state.indirect.list);
      localList = updateRecommendedDeadlines(localList);
      localList = flagCourseTopics(localList);
      localList.sort(sortByImportance);

      return {
        ...state,
        changed: true,
        localList,
      };
    }
    case constants.GROUP_TOPICS_LOCAL_UPDATE: {
      const { topicId, data } = action.data;

      let localList = state.localList.map((it) => {
        if (it.id !== topicId) return it;

        const updatedTopic = { ...it, ...data };
        return {
          ...updatedTopic,
          days_to_certify: getDaysToCertify(
            updatedTopic.text_amount,
            updatedTopic.text_period,
          ),
        };
      });
      localList = updateRecommendedDeadlines(localList);
      localList = flagCourseTopics(localList);
      localList.sort(sortByImportance);

      return {
        ...state,
        changed: true,
        localList,
      };
    }
    case constants.GROUP_TOPICS_INDIRECT.SUCCESS: {
      let { results } = action.data;

      results = groupByCourses(results);

      let localList = mergeIndirectTopics(state.localList, results);
      localList = updateRecommendedDeadlines(localList);
      localList.sort(sortByImportance);

      return {
        ...state,
        loading: false,
        localList: localList,
        indirect: {
          list: results,
          ready: true,
        },
      };
    }
    case constants.GROUP_TOPICS_UPDATE.SUCCESS: {
      return {
        ...state,
        changed: false,
      };
    }
    case UNAUTHORIZED:
      return groupTopicsInitial;
    default:
      return state;
  }
};

const paginationReducer = pagination(constants.PAGINATION);

export default combineReducers({
  list: groupTopicsReducer,
  pagination: paginationReducer,
});
