import { combineReducers } from "redux";

import {
  externalTrainingListReducer,
  externalTrainingReducer,
  paginationReducer,
} from "./training.reducer";
import {
  externalTrainingUsersPagination,
  externalTrainingUsersReducer,
} from "./users.reducer";
import {makeListReducer} from "../list";

export default combineReducers({
  current: combineReducers({
    training: externalTrainingReducer,
    users: combineReducers({
      list: makeListReducer(externalTrainingUsersReducer, "externalTrainingUsers"),
      pagination: externalTrainingUsersPagination,
    }),
  }),
  list: makeListReducer(externalTrainingListReducer, "externalTraining"),
  pagination: paginationReducer,
});
