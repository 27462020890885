import dot from "dot-object";

import { objDiff } from "../utils";

export class BaseModel {
  constructor() {
  }

  diff(obj, strategy) {
    return objDiff(this.json(), obj, strategy);
  }

  json() {
    return JSON.parse(JSON.stringify(this));
  }

  serialize({ omit = [], only = [] } = {}) {
    let json = this.toJSON();

    if (only.length) {
      const oldJson = { ...json };
      json = {};
      only.forEach((key) => dot.copy(key, key, oldJson, json));
    }

    omit.forEach((key) => dot.delete(key, json));
    return json;
  }

  toJSON() {
    //  Include getters in toJSON output

    const jsonObj = Object.assign({}, this);
    const proto = Object.getPrototypeOf(this);
    for (const key of Object.getOwnPropertyNames(proto)) {
      const desc = Object.getOwnPropertyDescriptor(proto, key);
      const hasGetter = desc && typeof desc.get === 'function';

      if (hasGetter) {
        jsonObj[key] = this[key];
      }
    }
    return jsonObj;
  }
}
