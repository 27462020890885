import React from "react";
import styles from "./dialog.module.scss";
import classNames from "classnames";

const ModalDialog = ({ children, position, dialogClass }) => {
  return (
    <div
      className={classNames("modal-dialog", dialogClass, styles.dialog, {
        [styles.positionedDialog]: position,
      })}
      style={position}
    >
      {children}
    </div>
  );
};

export default ModalDialog;
