import classNames from "classnames";
import strings from "l10n";
import React from "react";

import { Button } from "../button";
import "./confirm.scss";

let containerRef;

class ConfirmationItem extends React.Component {
  render() {
    const trans = strings.components.confirm;

    const { header, content, onConfirm } = this.props;

    const handleConfirmation = () => {
      onConfirm();
      closeConfirmation();
    };

    return (
      <div className="confirmation-item">
        <div className="confirmation-heading">
          <span>{header}</span>
          <Button
            accent={"transparent"}
            className="close-btn"
            onClick={closeConfirmation}
            icon={"times"}
          />
        </div>

        <hr className="mt-0" />

        <div className="confirmation-body">{content}</div>

        <div className="confirmation-footer">
          <Button accent={"primary"} onClick={handleConfirmation}>
            {trans.confirm}
          </Button>

          <Button accent={"transparent"} onClick={closeConfirmation}>
            {trans.cancel}
          </Button>
        </div>
      </div>
    );
  }
}

class ConfirmContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmation: null,
    };
  }

  componentDidMount() {
    containerRef = this;
  }

  componentWillUnmount() {
    containerRef = undefined;
  }

  show(props) {
    this.setState({ confirmation: props });
  }

  close() {
    this.setState({ confirmation: null });
  }

  render() {
    const { confirmation } = this.state;
    return (
      <div
        className={classNames("confirmation-container", {
          "d-none": !confirmation,
        })}
      >
        <div className="confirmation-backdrop" />
        <ConfirmationItem {...confirmation} />
      </div>
    );
  }
}

export const showConfirmation = (props) => {
  containerRef.show(props);
};

export const closeConfirmation = () => {
  containerRef.close();
};

export default ConfirmContainer;
