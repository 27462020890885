import { paginationActions, requestActions } from "./utils";

export const WEBHOOKS = requestActions("Webhooks/List");

export const PAGINATION = paginationActions("Webhooks/Paginate");
export const SELECT = "Webhooks/List/Select";
export const SHOW_COLUMNS = "Webhooks/ShowColumns";

export const WEBHOOK = requestActions("Webhook/Get");
export const WEBHOOK_CREATE = requestActions("Webhooks/Create");
export const WEBHOOK_DELETE = requestActions("Webhooks/Delete");
export const WEBHOOK_UPDATE = requestActions("Webhooks/Update");
export const WEBHOOK_RESET = "Webhook/Reset";
export const WEBHOOK_NOTIFY = requestActions("Webhook/Notify");
