import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";

import ConfirmContainer from "components/confirm";
import { FadeInAndOut } from "components/toastify/animations";

import "./page-common.scss";
import BaseRouter from "./router";

const appUrl = window.appUrl || "/";

export default ({ store, persistor }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter basename={appUrl}>
        <BaseRouter />

        <ToastContainer
          position={"bottom-right"}
          hideProgressBar
          autoClose={3500}
          transition={FadeInAndOut}
          closeButton={({ closeToast }) => (
            <i className={"far fa-times"} onClick={closeToast} />
          )}
          toastStyle={{
            backgroundColor: "var(--background-primary-color)",
            color: "var(--background-text-color)",
          }}
        />
      </BrowserRouter>

      <ConfirmContainer />
    </PersistGate>
  </Provider>
);
