import {paginationActions, requestActions} from "./utils";

export const REPORT_LIST = requestActions("Reporting/List/Request");
export const REPORT_LIST_ITEM_UPDATE = requestActions("Reporting/List/Item/Update");
export const REPORT_ITEM = requestActions("Reporting/Item/Request");
export const REPORT_ITEM_CREATE = requestActions("Reporting/Item/Create");
export const REPORT_ITEM_UPDATE = requestActions("Reporting/Item/Update");
export const SELECT = "Reporting/List/Select";
export const SELECT_ALL = "Reporting/List/Select/All";
export const SHOW_COLUMNS = "Reporting/List/ShowColumns";

export const PAGINATION = paginationActions("Reporting/List/Paginate");


