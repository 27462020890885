import { LOGOUT, UNAUTHORIZED } from "constants/auth.constants";
import { MFA_INFO, MFA_LOGIN } from "constants/mfa.constants";

const initialState = {
  required: false,
  configured: null,
  secret: null,
  uri: null,
  loading: false,
};

const mfaErorCodes = [101, 102];

export default (state = initialState, action) => {
  switch (action.type) {
    case MFA_LOGIN.REQUEST:
    case MFA_INFO.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MFA_INFO.SUCCESS:
      return {
        ...state,
        ...action.data,
        loading: false,
      };
    case MFA_LOGIN.FAILURE:
    case MFA_INFO.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case MFA_LOGIN.SUCCESS:
      return {
        ...state,
        secret: null,
        uri: null,
        loading: false,
      };
    case LOGOUT.REQUEST: {
      return {
        ...initialState,
      };
    }
    case UNAUTHORIZED: {
      const data = action.data;
      const code = data?.message?.message?.code;

      return {
        ...initialState,
        required: mfaErorCodes.includes(code),
      };
    }
    default:
      return state;
  }
};
