import { paginationActions, requestActions } from "./utils";

export const USERS = requestActions("Users/List");
export const USERS_ALL = requestActions("Users/All");
export const PAGINATION = paginationActions("Users/Paginate");
export const SELECT = "Users/Select";
export const SELECT_ALL = "Users/Select/All";
export const SHOW_COLUMNS = "Users/ShowColumns";
export const USER_DISABLE = requestActions("User/Disable");
export const USER_DELETE = requestActions("User/Delete");
export const USER_ENABLE = requestActions("User/Enable");
export const USER = requestActions("User/Get");
export const USER_CONFIRMATION_REQUEST = requestActions(
  "User/ConfirmationRequest",
);
export const USER_PASSWORD_RESET = requestActions("User/PasswordResetRequest");
export const USER_UPDATE = requestActions("User/Update");
export const USER_CERTS_INFO = requestActions("User/CertsInfo/Get");
export const USERS_ENABLE = requestActions("Users/Enable");
export const USERS_DISABLE = requestActions("Users/Disable");
export const USERS_DELETE = requestActions("Users/Delete");