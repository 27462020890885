import { paginationActions, requestActions } from "../utils";

export const GROUP_USERS = requestActions("GroupUsers/List");
export const GROUP_USERS_MORE = requestActions("GroupUsers/List/More");
export const GROUP_USERS_ADD = requestActions("GroupUsers/Add");
export const GROUP_USERS_REMOVE = requestActions("GroupUsers/Remove");
export const GROUP_USERS_STORE_ADD = "GroupUsers/List/Add";
export const GROUP_USERS_STORE_REMOVE = "GroupUsers/List/Remove";
export const GROUP_USERS_RESET = "GroupUsers/List/Reset";

export const PAGINATION = paginationActions("GroupUsers/Paginate");
