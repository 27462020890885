import { UNAUTHORIZED } from "constants/auth.constants";
import * as constants from "constants/questions.constants";
import reduceReducers from "reduce-reducers";
import { combineReducers } from "redux";

import { makeListReducer } from "./list";
import pagination from "./pagination";
import { mergeEditableField } from "./topics";
import { getItemsById } from "./utils";

const initialState = {
  loading: false,
  items: [],
  itemsById: {},
  count: 0,
  isPanelShown: false,
  showCopyModal: false,
  showMoveModal: false,
  showAddTagsModal: false,
  showRemoveTagsModal: false,
  showAutoTranslateModal: false,
  allSelected: false,
  anySelected: false,
  columns: {
    thumbnail: true,
    question: true,
    status: true,
    tags: true,
    backgroundInfo: true,
    comments: true,
  },
};

const questionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.QUESTION_LIST_GET.REQUEST:
    case constants.QUESTION_LIST_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.QUESTION_LIST_GET.FAILURE:
      return {
        ...state,
        items: [],
        itemsById: {},
        count: 0,
        loading: false,
        timestamp: null,
      };
    case constants.QUESTION_LIST_GET.SUCCESS: {
      const { count, results, timestamp } = action.data;
      const questions = results.map(mergeEditableField);

      return {
        ...state,
        items: questions,
        itemsById: getItemsById(questions),
        count: count,
        loading: false,
        allSelected: false,
        anySelected: false,
        timestamp,
      };
    }
    case constants.QUESTION_LIST_UPDATE.SUCCESS:
    case constants.QUESTION_LIST_UPDATE.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case constants.SELECT: {
      let { items: questions } = state;
      questions = questions.map((it) => {
        if (it.id === action.data.id) {
          it.selected = !it.selected;
        }
        return it;
      });
      return {
        ...state,
        items: questions,
        itemsById: getItemsById(questions),
        allSelected: questions.every((it) => it.selected === true),
        anySelected: questions.some((it) => it.selected === true),
      };
    }
    case constants.SELECT_ALL: {
      let { items: questions, allSelected } = state;

      allSelected = !allSelected;
      questions = questions.map((it) => {
        it.selected = allSelected;
        return it;
      });

      return {
        ...state,
        items: questions,
        itemsById: getItemsById(questions),
        allSelected,
        anySelected: questions.some((it) => it.selected === true),
      };
    }
    case constants.SHOW_COLUMNS: {
      const names = action.data;
      const columns = Object.keys(state.columns);

      return {
        ...state,
        columns: columns.reduce(
          (obj, key) => ({ ...obj, [key]: names.includes(key) }),
          {},
        ),
      };
    }
    case constants.QUESTIONS_SHOW_PANEL: {
      return {
        ...state,
        isPanelShown: true,
      };
    }
    case constants.QUESTIONS_HIDE_PANEL: {
      return {
        ...state,
        isPanelShown: false,
      };
    }
    case constants.COPY_MODAL.SHOW: {
      return {
        ...state,
        showCopyModal: true,
      };
    }
    case constants.COPY_MODAL.HIDE: {
      return {
        ...state,
        showCopyModal: false,
      };
    }
    case constants.MOVE_MODAL.SHOW: {
      return {
        ...state,
        showMoveModal: true,
      };
    }
    case constants.MOVE_MODAL.HIDE: {
      return {
        ...state,
        showMoveModal: false,
      };
    }
    case constants.ADD_TAGS_MODAL.SHOW: {
      return {
        ...state,
        showAddTagsModal: true,
      };
    }
    case constants.ADD_TAGS_MODAL.HIDE: {
      return {
        ...state,
        showAddTagsModal: false,
      };
    }
    case constants.REMOVE_TAGS_MODAL.SHOW: {
      return {
        ...state,
        showRemoveTagsModal: true,
      };
    }
    case constants.REMOVE_TAGS_MODAL.HIDE: {
      return {
        ...state,
        showRemoveTagsModal: false,
      };
    }
    case constants.AUTO_TRANSLATE_MODAL.SHOW: {
      return {
        ...state,
        showAutoTranslateModal: true,
      };
    }
    case constants.AUTO_TRANSLATE_MODAL.HIDE: {
      return {
        ...state,
        showAutoTranslateModal: false,
      };
    }
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

const makeDefaultAnswers = ({ answers }) => {
  const minAnswers = 4;
  if (answers.length >= minAnswers) return answers;

  const usedIds = answers.map((it) => it.id);
  const availableIds = [...Array(minAnswers).keys()].filter(
    (x) => !usedIds.includes(x),
  );

  return [...answers, ...Array(minAnswers - answers.length)].map((it) => {
    if (!it) {
      return {
        id: availableIds.shift(),
        text_html: "",
        correct: false,
      };
    }
    return it;
  });
};

const initialQuestionState = {
  found: false,
  error: false,
  loading: false,

  title_html: "",
  answers: [
    { id: "0", text_html: "", correct: false },
    { id: "1", text_html: "", correct: false },
    { id: "2", text_html: "", correct: false },
    { id: "3", text_html: "", correct: false },
  ],
  image: null,
  tip: null,
  tags: [],
  type: "image",
  is_shared: false,
  locked: false,
  editable: true,
};

const currentQuestionReducer = (state = initialQuestionState, action) => {
  switch (action.type) {
    case constants.QUESTION_CREATE.REQUEST:
    case constants.QUESTION.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.QUESTION_CREATE.FAILURE:
    case constants.QUESTION.FAILURE:
      return {
        ...state,
        found: false,
        error: true,
        loading: false,
      };
    case constants.QUESTION_CREATE.SUCCESS:
    case constants.QUESTION.SUCCESS: {
      let question = action.data;
      question = mergeEditableField(question);

      return {
        ...state,
        ...question,
        answers: makeDefaultAnswers(question),
        found: true,
        error: false,
        loading: false,
      };
    }
    case constants.QUESTION_UPDATE.SUCCESS: {
      const update = action.data;

      if (!update || !state.id || state.id !== update.id) {
        return state;
      }

      return {
        ...state,
        ...update,
      };
    }
    case constants.QUESTION_RESET: {
      const data = action.data || {};

      return {
        ...initialQuestionState,
        ...data,
        found: false,
        error: false,
        loading: false,
      };
    }
    case UNAUTHORIZED:
      return initialQuestionState;
    default:
      return state;
  }
};

const extraPaginationReducer = (
  state = {
    filter_tags: [],
  },
  action,
) => {
  switch (action.type) {
    case constants.PAGINATION.FILTER_TAGS: {
      return {
        ...state,
        filter_tags: action.data,
      };
    }
    default:
      return state;
  }
};

const basePaginationReducer = pagination(constants.PAGINATION);

const paginationReducer = reduceReducers(
  { page: 0, perPage: 10, orderBy: "", search: "", extra: [], filter_tags: [] },
  extraPaginationReducer,
  basePaginationReducer,
);

const initialCommentsState = {
  loading: false,
  items: [],
  itemsById: {},
  count: 0,
};

const questionCommentsReducer = (state = initialCommentsState, action) => {
  switch (action.type) {
    case constants.QUESTION_COMMENTS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.QUESTION_COMMENTS.FAILURE:
      return {
        ...state,
        items: [],
        itemsById: {},
        count: 0,
        loading: false,
      };
    case constants.QUESTION_COMMENTS.SUCCESS: {
      const { count, results: items } = action.data;
      return {
        ...state,
        items: items,
        itemsById: getItemsById(items),
        count: count,
        loading: false,
      };
    }
    case constants.QUESTION_COMMENTS_RESET:
      return {
        ...state,
        items: [],
        itemsById: {},
        count: 0,
        loading: false,
      };
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

const questionCommentsPaginationReducer = pagination(
  constants.QUESTION_COMMENTS_PAGINATION,
  {
    orderBy: "-updated",
    perPage: 5,
  },
);

const initialLocalizationListState = {
  loading: false,
  error: false,
  items: [],
  itemsById: {},
};

const localizationReducer = (state = initialLocalizationListState, action) => {
  switch (action.type) {
    case constants.TRANSLATION_LIST.REQUEST:
      return {
        ...state,
        loading: true,
        ready: false,
      };
    case constants.TRANSLATION_LIST.FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        ready: false,
      };
    case constants.TRANSLATION_UPDATE.SUCCESS: {
      // Update translation item with incoming data
      const update = action.data;

      if (!update || !state.itemsById[update.id]) {
        return state;
      }

      const items = state.items.map((item) => {
        if (item.id === update.id) {
          return {
            ...item,
            ...update,
          };
        }
        return item;
      });

      return {
        items,
        itemsById: getItemsById(items),
      };
    }
    case constants.TRANSLATION_LIST.SUCCESS: {
      let items = action.data;

      // Sort items in the way so base question is always first
      items.sort((a, b) => (a.is_base ? -1 : b.is_base ? 1 : 0));
      const baseTranslation = items.find((it) => it.is_base);

      // TODO: Clean up this mess
      items = items.map((it) => {
        return {
          ...it,
          answers: makeDefaultAnswers(it),
          use_base_image: Boolean(
            !it.is_base && it.image && it.image === baseTranslation.image,
          ),
          use_base_tip_image: !it.is_base && Boolean(
            (it?.tip?.image &&
              it?.tip?.image === baseTranslation?.tip?.image) ||
              (it?.tip?.video &&
                it?.tip?.video === baseTranslation?.tip?.video &&
                it?.tip?.video_thumbnail ===
                  baseTranslation?.tip?.video_thumbnail),
          ),
        };
      });

      return {
        ...state,
        items,
        itemsById: getItemsById(items),
        loading: false,
        ready: true,
      };
    }
    case constants.TRANSLATION_LIST_RESET: {
      return {
        ...initialLocalizationListState,
      };
    }
    case UNAUTHORIZED:
      return initialLocalizationListState;
    default:
      return state;
  }
};

const currentMetaReducer = (state = initialQuestionState, action) => {
  switch (action.type) {
    case constants.META_QUESTION_CREATE.REQUEST:
    case constants.META_QUESTION.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case constants.META_QUESTION_CREATE.SUCCESS:
    case constants.META_QUESTION.SUCCESS: {
      let question = action.data;
      question = mergeEditableField(question);

      return {
        ...state,
        ...question,
        found: true,
        loading: false,
        error: false,
      };
    }
    case constants.META_QUESTION_CREATE.FAILURE:
    case constants.META_QUESTION.FAILURE: {
      return {
        ...initialQuestionState,
        found: false,
        loading: false,
        error: true,
      };
    }
    case constants.META_QUESTION_RESET: {
      const data = action.data || {};

      return {
        ...initialQuestionState,
        ...data,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  list: makeListReducer(questionsReducer, "questions"),
  pagination: paginationReducer,
  current: combineReducers({
    question: currentQuestionReducer,
    meta: currentMetaReducer,
    translations: combineReducers({
      list: localizationReducer,
    }),
    comments: combineReducers({
      list: questionCommentsReducer,
      pagination: questionCommentsPaginationReducer,
    }),
  }),
});
