import React, { Component } from "react";

import { Loading } from "../components";
import strings from "../l10n";

const NotFound = ({ title }) => (
  <section className="page">
    <div className="row">
      <div className="col-lg-12">
        <h1 className="page-header">{title ? title : strings.pageNotFound}</h1>
      </div>
    </div>
  </section>
);

const getDisplayName = (WrappedComponent) =>
  WrappedComponent.displayName || WrappedComponent.name || "Component";

export const withNotFound = (WrappedComponent, title) => {
  class WithNotFound extends Component {
    componentDidMount() {
      this.props.onLoad();
    }
    render() {
      const { loading, found } = this.props;
      if (loading) return <Loading active />;
      if (!found) return <NotFound title={title} />;
      return <WrappedComponent {...this.props} />;
    }
  }
  WithNotFound.displayName = `WithNotFound(${getDisplayName(
    WrappedComponent,
  )})`;
  return WithNotFound;
};

export default NotFound;
