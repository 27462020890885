import { paginationActions, requestActions } from "../utils";

export const LIST = requestActions("ExternalTraining/Users/List");
export const ITEM = requestActions("ExternalTraining/Users/Item");
export const ITEM_RESET = "ExternalTraining/Users/Item/Reset";
export const PAGINATION = paginationActions("ExternalTraining/Users/Paginate");
export const SELECT = "ExternalTraining/Users/Select";

export const SELECT_ONE = "ExternalTraining/Users/Select/One";
export const SELECT_ALL = "ExternalTraining/Users/Select/All";
export const SHOW_COLUMNS = "ExternalTraining/Users/ShowColumns";
export const SHOW_PANEL = "ExternalTraining/Users/Panel/Show";
export const HIDE_PANEL = "ExternalTraining/Users/Panel/Hide";

export const TOGGLE_UPDATE_MODAL = "ExternalTraining/Users/UpdateModal/Toggle";
