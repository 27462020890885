import * as Sentry from "@sentry/react";
import classNames from "classnames";
import strings from "l10n";
import React from "react";
import * as api from "services/api/auth";

import { Button } from "./components/button";

import { createUrl } from "./utils";

const translations = strings.errorBoundary;

const UpdateAvailableComponent = () => {
  return (
    <>
      <h2>{translations.updateAvailableHeader}</h2>
      <p>{translations.updateAvailableContent}</p>

      <Button
        accent={"primary"}
        onClick={() => (window.location = createUrl("/"))}
      >
        {translations.reloadButton}
      </Button>
    </>
  );
};

const UnexpectedErrorComponent = () => {
  return (
    <>
      <h2>{translations.somethingWentWrongHeader}</h2>
      <p>{translations.somethingWentWrongContent}</p>
      <p>
        {strings.formatString(
          translations.pleaseReload,
          <a href="mailto:support@knowingo.com">support@knowingo.com</a>,
        )}
      </p>

      <Button
        accent={"primary"}
        onClick={() => (window.location = createUrl("/"))}
      >
        {translations.reloadButton}
      </Button>
    </>
  );
};

class FallbackComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      hasConnection: null,
    };
  }

  async componentDidMount() {
    let hasConnection;

    try {
      const user = await api.getCurrentUser();
      hasConnection = user && Object.prototype.hasOwnProperty.call(user, "id");
    } catch (e) {
      hasConnection = false;
    } finally {
      this.setState({
        loading: false,
        hasConnection,
      });
    }
  }

  render() {
    const { loading, hasConnection } = this.state;
    const { error, eventId } = this.props;

    const isChunkLoadError = error && error.name === "ChunkLoadError";

    return (
      <section className={classNames("page", { loading: loading })}>
        <section className="page-body shadow-custom">
          <div className="row">
            <div className="col">
              {!loading &&
                (hasConnection && isChunkLoadError ? (
                  <UpdateAvailableComponent />
                ) : (
                  <UnexpectedErrorComponent />
                ))}

              {eventId && (
                <p className="mt-3">
                  {translations.eventIdentifier}: {eventId}
                </p>
              )}
            </div>
          </div>
        </section>
      </section>
    );
  }
}

let ErrorBoundary = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={(props) => <FallbackComponent {...props} />}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
