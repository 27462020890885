import fetch from "lib/fetch";

import { baseUrl, jsonOrReject } from "./utils";

export const getCompany = () => {
  return fetch(`${baseUrl}/info/company`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const updateCompany = (data) => {
  return fetch(`${baseUrl}/info/company`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const updateCompanyWhiteLabeling = (formData) => {
  return fetch(`${baseUrl}/info/company/white_labeling`, {
    method: "POST",
    body: formData,
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};
