import { combineReducers } from "redux";

import { UNAUTHORIZED } from "../constants/auth.constants";
import * as constants from "../constants/contentLibrary.constants.js";
import { getItemsById } from "./utils";

const initialState = {
  loading: false,
  items: [],
  itemsById: {},
  count: 0,
};

const contentLibraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CONTENT_LIBRARY.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.CONTENT_LIBRARY.FAILURE:
      return {
        ...state,
        items: [],
        itemsById: {},
        count: 0,
        loading: false,
      };
    case constants.CONTENT_LIBRARY.SUCCESS: {
      const { count, results: items } = action.data;
      return {
        ...state,
        items: items,
        itemsById: getItemsById(items),
        count: count,
        loading: false,
      };
    }
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

const paginationInitialState = {
  page: 0,
  perPage: 10,
  orderBy: "-updated",
  search: "",
  extra: [],
};

const paginationReducer = (state = paginationInitialState, action) => {
  switch (action.type) {
    case constants.PAGINATION.PAGE:
      return {
        ...state,
        page: action.data,
      };
    case constants.PAGINATION.PAGE_SIZE:
      return {
        ...state,
        page: 0,
        perPage: action.data,
      };
    case constants.PAGINATION.ORDER:
      return {
        ...state,
        page: 0,
        orderBy: action.data,
      };
    case constants.PAGINATION.SEARCH:
      return {
        ...state,
        page: 0,
        search: action.data,
      };
    case constants.PAGINATION.FILTERS:
      return {
        ...state,
        page: 0,
        extra: action.data,
      };
    case constants.PAGINATION.LANGUAGE:
      return {
        ...state,
        page: 0,
        language: action.data,
      };
    default:
      return state;
  }
};

export default combineReducers({
  list: contentLibraryReducer,
  pagination: paginationReducer,
});
