import classNames from "classnames";
import React from "react";

import Button from "./button";
import styles from "./dropdownToggle.module.scss";

const DropdownToggle = React.forwardRef(
  ({ children, onClick, className, ...props }, ref) => (
    <Button
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      type={"button"}
      className={classNames(styles.dropdownToggle, className)}
      {...props}
    >
      {children}
    </Button>
  ),
);

export default DropdownToggle;
