import { requestActions } from "./utils";

export const LOGIN = requestActions("Auth/Login");
export const SSO_PROVIDERS = requestActions("Auth/SSO/Providers");
export const SSO_URL = requestActions("Auth/SSO/Url");
export const LOGOUT = requestActions("Auth/Logout");
export const FORGOT_PASSWORD = requestActions("Auth/ForgotPassword");
export const UNAUTHORIZED = "Auth/Unauthorized";
export const USER = requestActions("Auth/User/Get");
export const USER_UPDATE = requestActions("Auth/User/Update");
export const SUBDOMAIN = {
  ...requestActions("Auth/Subdomain"),
  RESET: "Auth/Subdomain/Reset",
};
export const API_KEY = requestActions("Auth/ApiKey/Get");
export const CREATE_API_KEY = requestActions("Auth/ApiKey/Create");
export const DELETE_API_KEY = requestActions("Auth/ApiKey/Delete");