import { SSO_PROVIDERS, SSO_URL } from "constants/auth.constants";

const initialState = {
  loading: false,
  success: null,
  error: null,
  url: null,
  available_providers: [],
  disabled_providers: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SSO_PROVIDERS.REQUEST:
    case SSO_URL.REQUEST:
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case SSO_PROVIDERS.SUCCESS: {
      const { available_providers, disabled_providers } = action.data;

      return {
        ...state,
        loading: false,
        success: true,
        available_providers,
        disabled_providers,
      };
    }
    case SSO_URL.SUCCESS: {
      const { url } = action.data;
      return {
        ...state,
        loading: false,
        success: true,
        url,
      };
    }
    case SSO_PROVIDERS.FAILURE:
    case SSO_URL.FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.data,
        url: null,
        available_providers: [],
        disabled_providers: [],
      };
    default:
      return state;
  }
};
