import { requestActions } from "./utils";

export const TOGGLE_SIDEBAR = "Dashboard/ToggleSidebar";
export const TOGGLE_NOTIFICATION = "Dashboard/ToggleNotification";
export const TOGGLE_MOBILE_SIDEBAR = "Dashboard/ToggleMobileSidebar";
export const TOGGLE_COMPANY_SELECTION_DIALOG =
  "Dashboard/ToggleCompanySelectionDialog";
export const TOGGLE_SELECT_WIDGETS_DIALOG =
  "Dashboard/ToggleSelectWidgetsDialog";
export const TOGGLE_SETTINGS_DIALOG = "Dashboard/ToggleSettingsDialog";
export const SHOW_LOADING_BAR = "Dashboard/ShowLoadingBar";
export const CHANGE_LANGUAGE = "Dashboard/ChangeLanguage";

export const CREATE_NEW_DASHBOARD = "Dashboard/Create";
export const COPY_DASHBOARD = "Dashboard/Copy";
export const UPDATE_DASHBOARD = "Dashboard/Update";
export const ADD_WIDGET = "Dashboard/AddWidget";
export const REMOVE_WIDGET = "Dashboard/RemoveWidget";
export const TOGGLE_EDITABLE = "Dashboard/ToggleEditable";

export const SELECT_DASHBOARD = "Dashboard/Select";

export const GET_DASHBOARDS = requestActions("Dashboard/List/Get");
export const SAVE_DASHBOARD = requestActions("Dashboard/Save");
export const DELETE_DASHBOARD = requestActions("Dashboard/Delete");
export const CANCEL_DASHBOARD_EDIT = "Dashboard/CancelEdit";
