import classNames from "classnames";
import React from "react";

import "./loading.scss";

const Loading = ({ active }) => (
  <div id="loading" className={classNames({ active })}>
    <span className="fa fa-sync spin" />
  </div>
);

export default Loading;
