import classnames from "classnames";
import React from "react";

const ComponentOrChildren = ({ component, children, ...props }) => {
  const Component = component;

  return <>{component ? <Component {...props} /> : { children }}</>;
};

const TableElement = (props) => {
  return (
    <div className="row">
      <div className="col-12">
        <ComponentOrChildren {...props} />
      </div>
    </div>
  );
};

const TableBody = ({ loading, ...props }) => {
  return (
    <div
      className={classnames("table-responsive table-spaced", {
        loading,
      })}
    >
      <ComponentOrChildren {...props} />
    </div>
  );
};

const TableContainer = ({ children }) => {
  return <>{children}</>;
};

TableContainer.Controls = TableElement;
TableContainer.Body = TableBody;
TableContainer.Pagination = TableElement;

export default TableContainer;
