export const getItemsById = (items) =>
  items.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});

export const selectAll = (items) =>
  items.map((it) => ({ ...it, selected: true }));

export const unselectAll = (items) =>
  items.map((it) => ({ ...it, selected: false }));

export const toggleSelect = (item, items) =>
  items.map((it) => {
    if (it.id === item.id) {
      return {
        ...it,
        selected: !it.selected,
      };
    }
    return it;
  });

export const getListProps = (items) => ({
  items: items,
  itemsById: getItemsById(items),
  allSelected: items.every((it) => it.selected),
  anySelected: items.some((it) => it.selected),
});
