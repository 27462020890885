const es = {
  btnLoading: "Cargando...",
  filterNotification: "Buscaste {0}",
  general: {
    or: "o",
    next: "siguiente",
    wait: "Por favor, espera...",
    warning: "¡Advertencia!",
    demo: "Demo",
  },

  messages: {
    unknownError:
      "Ocurrió un error. Por favor, inténtelo nuevamente o contacte al soporte.",
    formError:
      "Ocurrió un error. Por favor, verifique el formulario e inténtelo nuevamente.",
    saved: "Guardado",
    success: "¡Éxito!",
    textCopied: "Texto copiado al portapapeles",
    textCopyError:
      "Se produjo un error al copiar el texto. Por favor, seleccione el texto y cópielo manualmente.",
    csrfError:
      "Token CSRF no válido o faltante. Por favor, intente enviar su solicitud nuevamente. Si el problema persiste, por favor, póngase en contacto con el soporte.",
  },

  labels: {
    beta: "Beta",
  },

  fields: {
    email: {
      placeholder: "Dirección de correo electrónico",
    },
    password: {
      placeholder: "Contraseña",
      description: "Mínimo 8 caracteres, con 1 mayúscula y 1 número",
    },
    repeatPassword: {
      placeholder: "Repetir contraseña",
    },
    domain: {
      errors: {
        invalid: "Nombre de dominio faltante o no válido",
        duplicated: "Nombre de dominio duplicado",
      },
    },
    errors: {
      incorrectValue: "Valor incorrecto",
      fieldRequired: "El campo es obligatorio",
      invalidDate: "Fecha inválida",
      overlappingDate: "Fecha superpuesta",
    },
  },

  buttons: {
    downloadExcel: "Descargar XLSX",
    downloadCsv: "Descargar CSV",
    generate: "Generar",
    save: "Guardar",
    submit: "Enviar",
    dismiss: "Descartar",
    confirm: "Confirmar",
    clear: "Limpiar",
    loading: "Cargando",
    addMore: "Agregar más",
    copy: "Copiar",
    create: "Crear",
    undo: "Deshacer",
    close: "Cerrar",
    remove: "Eliminar",
    continue: "Continuar",
    upload: "Subir",
  },

  pages: {
    activityLog: {
      activityLog: "Registro de actividad",
      table: {
        columns: {
          activity: "Actividad",
          user: "Usuario",
          created: "Creado",
        },
      },
    },
    auth: {
      clickToLogout: "Haz clic aquí para cerrar sesión",
      sso: {
        signIn: "Iniciar sesión en Knowingo{0}",
        ssoUnavailable:
          "Single Sign-On no disponible. Contacta a tu administrador para obtener más información.",
        redirect: {
          redirectionInProgress: "Serás redirigido en {0} segundos.",
          redirectManually:
            "Si no eres redirigido automáticamente, haz clic aquí.",
          pleaseWait: "Por favor espera",
        },
      },
      mfa: {
        authentication: {
          tokenInputLabel: "Token de autenticación",
          tokenInputPlaceholder:
            "Ingresa el código de 6 dígitos que ves en la aplicación de autenticación",
          verify: "Verificar",
        },
        setup: {
          multiFactorAuthentication:
            "Configuración de autenticación de múltiples factores",
          step1: "Paso 1: Descarga una aplicación de autenticación",
          step1Description:
            "Descarga e instala cualquier aplicación de autenticación desde una tienda (Google Authenticator, Microsoft Authenticator, Authy). Puedes omitir este paso si ya lo tienes instalado.",
          step2: "Paso 2: Escanea el código QR",
          step2Description:
            "Abre la aplicación de autenticación y escanea el código QR a continuación para agregar Knowingo a la lista.",
          step3: "Paso 3: Ingresa el token",
          step3Description:
            "Abre la aplicación de autenticación y ingresa el token generado en el campo a continuación:",
          typeManually:
            'Si no puedes escanear el código QR, toca "Ingresar una clave de configuración" en la aplicación de autenticación y usa la siguiente configuración:',
          continue: "Continuar",
          secret: "Secreto",
          keyType: "Tipo de clave",
          timeBased: "Basado en tiempo",
          accountName: "Nombre de la cuenta",
          showManualConfiguration:
            "Haz clic aquí si no puedes escanear el código QR",
        },
      },
      forgotPassword: {
        resetPassword: "Restablecer contraseña",
        form: {
          successMessage:
            "Revisa tu correo electrónico. Recibirás un enlace de restablecimiento de contraseña en unos minutos. ¿No recibiste el correo electrónico? Por favor verifica si la dirección de correo ingresada es correcta.",
          emailAddress: "Dirección de correo electrónico",
        },
        goBack: "Volver a la página de inicio de sesión",
      },
      login: {
        form: {
          emailAddress: "Dirección de correo electrónico",
          password: "Contraseña",
          rememberMe: "Recuérdame",
          forgotPassword: "¿Olvidaste tu contraseña?",
          login: "Iniciar sesión",
          ssoLogin: "Iniciar sesión con SSO",
        },
        signIn: "Inicia sesión en Knowingo{0}",
      },
    },
    confirmation: {
      forms: {
        passwordReset: {
          header: "Restablecer contraseña",
          enterPassword: "Ingrese nueva contraseña",
          repeatPassword: "Repetir nueva contraseña",
          errors: {
            notSamePasswords: "Las contraseñas no son iguales",
          },
          submit: "Guardar nueva contraseña",
          passwordChanged: "Su contraseña ha sido cambiada",
          goBack: "Volver a la página de inicio de sesión",
        },
        emailChange: {
          header: "Cambio de dirección de correo electrónico",
          emailChangedTo:
            "Su dirección de correo electrónico ha sido cambiada a {0}",
          contactSupport:
            "Si tiene problemas para iniciar sesión en su cuenta, póngase en contacto con el soporte de Knowingo en {0}",
          goBack: "Volver a la página de inicio de sesión",
        },
        emailConfirmation: {
          emailConfirmed:
            "La dirección de correo electrónico ha sido confirmada",
        },
        passwordWasReset: "Su contraseña ha sido restablecida exitosamente",
        loginToDashboard: "Puede continuar iniciando sesión en {0}",
        loginToApp:
          "Si estaba usando la aplicación Knowingo, puede continuar jugando volviendo a la aplicación móvil o utilizando {0}",
        knowingoDashboard: "Tablero de Knowingo",
        knowingoWebApplication: "Aplicación web Knowingo",
      },
      errors: {
        invalidConfirmation: {
          header: "Enlace de confirmación inválido",
          paragraph: "La URL que ingresó es inválida o ha caducado.",
          contactAdministrator:
            "Por favor, póngase en contacto con su administrador para obtener más detalles",
        },
        unknownError: {
          header: "Se produjo un error",
          contactSupport:
            "Se produjo un error y no pudimos procesar su solicitud. Por favor, póngase en contacto con el soporte en {0}",
        },
      },
    },
    trainingConfirmation: {
      thankYouForFeedback: "Gracias por tus comentarios",
      giveFeedback: "Por favor, avísanos si asististe a continuación:",
      yes: "Sí",
      no: "No",
    },
    signup: {
      errors: {
        outOfSeats: {
          header: "Sin asientos",
          paragraph:
            "No puedes crear una cuenta, ya que excederá el número máximo de usuarios permitidos para unirse a esta organización en Knowingo",
          contactAdministrator:
            "Por favor, contacta a tu administrador para obtener más detalles.",
        },
        invalidCode: {
          header: "Enlace de registro no válido",
          paragraph: "La URL que ingresaste no es válida o ha expirado",
          contactAdministrator:
            "Por favor, contacta a tu administrador para obtener más detalles.",
        },
        unknownError: {
          header: "Ha ocurrido un error",
          contactAdministrator:
            "Por favor, contacta a tu administrador para obtener más detalles.",
        },
        logoutRequired: {
          header: "Se requiere cerrar sesión",
          paragraph:
            "Estás intentando acceder a un recurso disponible solo para nuevos usuarios. Cierra sesión e intenta nuevamente",
          logout: "Cerrar sesión",
        },
        signupNotAllowed: {
          header: "Registro no permitido",
          paragraph:
            "Esta empresa ha cerrado el registro. Si deseas unirte a esta empresa en Knowingo, contacta al administrador.",
        },
      },
      forms: {
        fillInFields: "Por favor, completa los campos a continuación",
        personalInformation: "Información personal",
        accountInformation: "Información de la cuenta",
        errors: {
          notSamePasswords: "Las contraseñas no son iguales",
        },
        firstName: "Nombre",
        lastName: "Apellido",
        workEmailAddress: "Correo electrónico comercial",
        password: "Contraseña",
        repeatPassword: "Repetir contraseña",
        getStarted: "Empezar",
        consentCheckbox:
          "Marca aquí para confirmar que has leído y aceptado {0} y {1}",
        termsOfService: "Términos de servicio",
        privacyPolicy: "Política de privacidad",
        allowedDomains:
          "Por razones de seguridad, solo se aceptan direcciones de correo electrónico de dominios aprobados. Póngase en contacto con su administrador de cuenta o el soporte de Knowingo para obtener más detalles.",
      },
      accountActivated:
        "Tu cuenta de Knowingo ha sido activada y está lista para usar.",
      startLearning:
        "¡Ahora solo tienes que descargar la aplicación y empezar a aprender!",
      confirmationSent: "Acabamos de enviarte un correo de confirmación.",
      clickOnLink: "Haz clic en el enlace interno y ¡comienza a aprender!",
      usedEmail: "Dirección de correo electrónico utilizada para el registro:",
      step: "Paso",
      createAccount: "Crea tu cuenta de Knowingo",
      downloadApp: "Descarga la aplicación Knowingo",
      appLinks:
        "Haz clic en uno de estos enlaces para visitar tu tienda de aplicaciones, o busca Knowingo en la tienda.",
      readyToPlay: "Listo para jugar",
      haveFunLearning: "¡Diviértete aprendiendo!",
      broughtBy: "Presentado por",
    },
    dashboard: {
      betaNotification:
        "Estás utilizando la versión beta del panel de control de Knowingo.",
      modals: {
        selectCompany: {
          changeCompany: "Cambiar empresa",
          open: "Abrir",
          cancel: "Cancelar",
        },
      },
    },
    enterpriseStatistics: {
      loading: "Cargando",
      enterpriseStatistics: "Estadísticas de la empresa",
      beta: "Beta",
      expirationBanner:
        "Debido a las mejoras en nuestros widgets del tablero y en los informes, Knowingo ha decidido eliminar las Estadísticas Empresariales. Hemos trasladado la información de la página de Estadísticas Empresariales a otras fuentes. Esta página permanecerá en línea hasta {0}.",
      adoption: {
        adoption: "Adopción",
        adoptionPerGroup: "Adopción por grupo",
        joined: "Uniéndose",
        joinedLastWeek: "Uniéndose en los últimos 7 días",
        missing: "Faltando",
        joinedStatus:
          "{0} usuarios que se han registrado y jugado su primer cuestionario.",
        joinedLastWeekStatus:
          "{0} usuarios que se han registrado y jugado en los últimos 7 días. Esto se superpone con Uniéndose.",
        missingStatus:
          "{0} usuarios son personas que han sido invitadas pero aún no han registrado su cuenta.",
        status: "Estado",
        loading: "Cargando",
        error: "Error",
        noGroupSelected: "Ningún grupo seleccionado",
        noData: "Sin datos",
        showMore: "Mostrar más",
        email: "Correo electrónico",
        firstName: "Nombre de pila",
        lastName: "Apellido",
        users: "Usuarios",
      },
      certification: {
        certificates: "Certificados",
        certification: "Certificación",
        certificationPerGroup: "Certificación por grupo",
        fullyCertified: "Completamente certificado",
        onTrack: "En camino",
        expired: "Vencido",
        overdue: "Atrasado",
        missing: "Faltante",
        fullyCertifiedStatus:
          "{0} usuarios han obtenido un certificado válido.",
        onTrackStatus:
          "{0} usuarios están pasando por su viaje de aprendizaje.",
        expiredStatus:
          "{0} certificados de usuarios han superado su tiempo de validez.",
        overdueStatus:
          "{0} usuarios han superado la fecha límite de sus temas obligatorios.",
        missingStatus:
          "{0} usuarios son personas que han sido invitadas pero aún no han registrado su cuenta.",
        noGroupSelected: "Ningún grupo seleccionado",
        noData: "Sin datos",
        showMore: "Mostrar más",
        email: "Correo electrónico",
        firstName: "Nombre de pila",
        lastName: "Apellido",
        topic: "Tema",
        status: "Estado",
        downloadAll: "Descargar todo",
      },
      experts: {
        initialKnowledgeScore: "Puntaje de conocimiento inicial",
        selectTopic: "Seleccionar tema",
        topic: "Tema",
        expert: "Experto",
        noData: "Sin datos",
        yourExperts: "Tus expertos",
        loading: "Cargando",
        error: "Error",
        collapse: "Colapsar",
        expand: "Expandir",
      },
      groupSelection: {
        description:
          "Esto agregará un filtro de grupo sobre todas las estadísticas a continuación y la descarga en Excel.",
        groupFilter: "Filtro de grupo",
      },
      misconception: {
        misconception: "Concepto erróneo",
        selectTopic: "Seleccionar tema",
        topic: "Tema",
        question: "Pregunta",
        correctAnswer: "Respuesta correcta",
        noData: "Sin datos",
        error: "Error",
        loading: "Cargando",
      },
      modal: {
        download: "Descargar",
        close: "Cerrar",
      },
    },
    overview: {
      header: "Tablero de instrumentos",
      cards: {
        answers: "Respuestas correctas",
        users: "Usuarios activos",
        multiplier: "Multiplicador de conocimiento",
      },
      charts: {
        knowledge: {
          header: "Conocimiento",
          legend: {
            initialKnowledge: "Conocimiento inicial",
            currentKnowledge: "Conocimiento actual",
            potentialKnowledge: "Conocimiento potencial",
          },
          inputs: {
            groups: "Todos los grupos",
          },
        },
        trainingTime: {
          header: "Tiempo de entrenamiento",
          legend: {
            minutes: "Minutos",
          },
          inputs: {
            groups: "Todos los grupos",
            topics: "Todos los temas",
            topicsInLearningPlan: "Todos los temas en el plan de aprendizaje",
          },
        },
        questionsMastered: {
          header: "Preguntas dominadas",
          legend: {
            questionsMastered: "Preguntas dominadas",
          },
          inputs: {
            groups: "Todos los grupos",
          },
        },
        learningProgress: {
          header: "Progreso de aprendizaje del usuario",
          legend: {
            users: "Usuarios",
          },
          inputs: {
            groups: "Todos los grupos",
            topics: "Todos los temas",
          },
        },
      },
      components: {
        dropdown: {
          default: "Predeterminado",
          companyDashboards: "Tableros de la empresa",
          createNew: "Crear nuevo tablero",
          copy: "Copiar tablero",
          edit: "Editar tablero",
          delete: "Eliminar tablero",
        },
        toolbar: {
          addWidget: "Agregar widget",
          settings: "Configuración",
          save: "Guardar tablero",
          cancel: "Cancelar edición",
        },
        settings: {
          header: "Configuración del tablero",
          dashboardName: "Nombre del tablero",
          companyDefault: "Predeterminado de la compañía",
        },
        widgets: {
          header: "Seleccionar widgets",
          add: "Agregar",
          remove: "Eliminar",
          charts: "Gráficos",
          knowledge: {
            header: "Conocimiento",
            description:
              "Vea el conocimiento inicial, actual y potencial de los usuarios activos de Knowingo aquí.",
            initialKnowledge:
              "El conocimiento inicial (rojo) muestra cuánto conocimiento tenían los usuarios antes de comenzar a aprender con Knowingo",
            currentKnowledge:
              "El conocimiento actual (verde) muestra cuánto conocimiento han aprendido actualmente los usuarios",
            potentialKnowledge:
              "El conocimiento potencial (azul) es la cantidad de conocimiento que los usuarios podrían lograr según lo que hay en su plan de aprendizaje",
          },
          masteredQuestions: {
            header: "Preguntas dominadas",
            description:
              "¡La cantidad total de preguntas que Knowingo ha enseñado con éxito a sus usuarios!",
          },
          trainingTime: {
            header: "Tiempo de entrenamiento",
            description:
              "Este gráfico muestra la cantidad de minutos jugados por período de tiempo seleccionado, grupo de usuarios y plan de aprendizaje.",
            minutes: "Minutos",
          },
          learningProgress: {
            header: "Progreso de aprendizaje",
            description:
              "Este gráfico muestra cuánto progreso han hecho sus empleados en su plan de aprendizaje. Los nuevos usuarios comienzan en un 0% y los usuarios que han completado todo su plan de aprendizaje se pueden ver en la columna del 90-100%.",
          },
          leaderBoard: {
            header: "Tablero de clasificación",
            description:
              "El tablero de clasificación muestra su base de jugadores clasificados según los minutos jugados, la mayoría de los certificados y su experiencia. Puede filtrar estos según grupos y temas. Este widget muestra qué jugadores son los mejores aprendices en su organización.",
          },
          timeToCertify: {
            header: "Tiempo para certificar",
            description:
              "El tiempo para certificar muestra el tiempo promedio que los usuarios tardan en obtener su certificado para cada tema en minutos.",
            minutes: "Minutos",
          },
          knowledgeGain: {
            header: "Aumento de conocimiento",
            description:
              "El gráfico de Aumento de conocimiento muestra cómo se cierra la brecha de conocimiento en su población con el tiempo. El Aumento de conocimiento muestra la cantidad de conocimiento que han ganado sus jugadores durante cualquier cantidad de tiempo.",
            knowledgeGain: "Aumento de conocimiento",
            knowledgeDecrease: "Disminución de conocimiento",
          },
          certificateStatus: {
            header: "Estado del certificado",
            description:
              "Este gráfico muestra un desglose de los estados de los certificados por tema. Úsalo para ver cómo avanzan sus usuarios hacia la obtención de su certificado.",
            isCertified: "Certificado",
            isExpired: "Certificados vencidos",
            isMissing: "Usuarios no registrados",
            isMissed: "Fecha límite perdida",
            isOnTrack: "Dentro de la fecha límite",
            isUncertified: "No certificado",
            detailedView: "Vista simple/detallada",
          },
          questionAnalytics: {
            header: "Analítica de preguntas",
            description:
              "Este widget muestra una descripción general de cuántas preguntas han alcanzado los umbrales de advertencia en las diferentes métricas: malentendido, complejidad, obviedad y puntuación de error.",
            metric: "Métrica",
            questions: "Preguntas",
            highMisconception: "Malentendido alto",
            highObviousness: "Obviedad alta",
            highComplexity: "Complejidad alta",
            highErrorScore: "Puntuación de error alta",
          },
          adoption: {
            header: "Adopción",
            description:
              "Este gráfico muestra un desglose del estado de adopción global de su población. Esto le ayuda a identificar si sus usuarios han completado el proceso de registro y han comenzado a jugar su primer cuestionario.",
            invited: "Invitado",
            registered: "Registrado",
            activated: "Activado",
          },
          overallCompliance: {
            header: "Cumplimiento general",
            compliant: "Cumple",
            notCompliant: "No cumple",
            current: "Actual",
            allTime: "Todo el tiempo",
          },
          error: {
            header: "Se produjo un error",
            reload: "Haga clic aquí para recargar",
          },
          noData: "No hay datos para mostrar",
        },
        dialog: {
          deleteDashboard: {
            header: "Eliminar tablero",
            content: "¿Seguro que desea eliminar el tablero {0}?",
          },
        },
      },
      messages: {
        dashboardDeleted: "El tablero ha sido eliminado",
        dashboardSaved: "El tablero ha sido guardado",
      },
    },
    profile: {
      form: {
        basicInformation: "Información básica",
        firstName: "Nombre",
        lastName: "Apellido",
        email: "Correo electrónico",
        passwordSettings: "Configuración de contraseña",
        currentPassword: "Contraseña actual",
        newPassword: "Nueva contraseña",
        confirmNewPassword: "Confirmar nueva contraseña",
        languageSettings: "Configuración de idioma",
        dashboardLanguage: "Idioma del panel de control",
        appLanguage: "Idioma de la aplicación",
        default: "Predeterminado",
        errors: {
          missingUppercase: "La contraseña no tiene caracteres en mayúscula.",
          missingLowercase: "La contraseña no tiene caracteres en minúscula.",
          missingDigit: "La contraseña no tiene un dígito.",
        },
      },
      profile: "Mi perfil",
      api: {
        header: "Configuración de la API",
        buttons: {
          showKey: "Mostrar clave",
          hideKey: "Ocultar clave",
        },
        form: {
          generateKey: "Generar clave de API",
          manageKeys: "Gestionar acceso a la API",
          keysDescription:
            "Genere una clave única para acceder de forma segura a nuestra API. Tenga en cuenta que cada usuario solo tiene permitida una clave de API activa a la vez. Para generar una nueva clave de API, simplemente haga clic en el botón de arriba. Su clave activa actual, si la hay, se invalidará al generar una nueva clave.",
          value: "Valor",
          expiration: "Caduca en",
          deleteKey: "Eliminar clave de API",
          deleteConfirmationPrompt:
            "Está a punto de eliminar una clave de API. Cualquier servicio que esté utilizando actualmente esta clave perderá el acceso inmediatamente. Asegúrese de haber actualizado todos los servicios relevantes con una clave alternativa antes de continuar con la eliminación.",
        },
        dialogs: {
          delete: {
            header: "Invalidar clave",
            confirm: "La clave actual se invalidará. ¿Desea continuar?",
          },
        },
        rateLimits: "Límites de tasa",
        rateLimitDescription:
          "Los límites de tasa de la API se utilizan para prevenir el abuso, garantizar un uso justo y mantener la estabilidad y el rendimiento del sistema. Si tiene alguna pregunta sobre el uso de la API o los límites de tasa no son suficientes, póngase en contacto con el soporte en {0}.",
        userRateLimit: "Límite de tasa de usuario",
        companyRateLimit: "Límite de tasa de la empresa",
        requestsPerMinute: "solicitudes por minuto",
        docs: "La documentación de OpenAPI, que proporciona información completa y especificaciones para interactuar con la API, se puede encontrar en {0}",
        authentication: "Autenticación de API",
        authenticationDescription:
          "Las solicitudes posteriores a la API deben incluir la clave de API en el encabezado {0} para autenticar al usuario y autorizar el acceso a los recursos solicitados.",
        exampleRequest: "Solicitud de ejemplo",
        gettingStarted: "Empezar",
      },
    },
    users: {
      header: "Usuarios",
      cards: {
        totalUsers: "Usuarios totales",
        availableSeats: "Asientos disponibles",
        registeredUsers: "Usuarios registrados",
        unregisteredUsers: "Usuarios no registrados",
        onlineUsers: "Usuarios en línea",
      },
      table: {
        messages: {
          usersActivated: "Los usuarios seleccionados han sido activados",
          usersDeactivated: "Los usuarios seleccionados han sido desactivados",
          invitationSent: "La invitación ha sido enviada",
          invitationsSent: "Las invitaciones han sido enviadas",
        },
        controls: {
          inviteButton: "Invitar",
          importButton: "Importar",
          downloadTemplate: "Descargar plantilla",
          actionsButton: {
            label: "Acciones",
            items: {
              activate: "Activar",
              deactivate: "Desactivar",
              resendInvitations: "Reenviar invitaciones",
              delete: "Eliminar",
            },
          },
          filtersDropdown: {
            registered: "Registrado",
            unregistered: "No registrado",
            enabled: "Habilitado",
            disabled: "Desactivado",
            notInvited: "No invitado",
          },
        },
        columns: {
          select: "Seleccionar",
          firstname: "Nombre",
          lastname: "Apellido",
          email: "Correo electrónico",
          learningProgress: "Progreso de aprendizaje",
          trainingTime: "Minutos de entrenamiento",
          certificationStatus: "Estado de certificación",
          lastSeenOnline: "Última vez conectado",
          moderator: "Moderador",
          disabled: "Desactivado",
          invitationDate: "Invitado",
          invitationStatus: "Estado de invitación",
          actions: {
            edit: "Editar",
            activate: "Activar",
            deactivate: "Desactivar",
            delete: "Eliminar",
            resendInvitation: "Reenviar invitación",
          },
          sso: "SSO",
        },
        warnings: {
          invitation: {
            title: "Invitación no enviada",
            description:
              "Esta cuenta de usuario ha sido creada pero no se envió la invitación. Por favor, envíe la invitación usando el botón de acciones para permitir el acceso a Knowingo.",
          },
        },
        dialogs: {
          bulkDelete: {
            header: "Eliminar usuarios",
            content:
              "¿Estás seguro de que deseas eliminar los usuarios seleccionados?",
            confirmationMessage:
              "¡Los usuarios seleccionados han sido eliminados!",
          },
          activationConfirmation: {
            header: "Activar usuario",
            content: "¿Estás seguro de que deseas activar al usuario {0}?",
            confirmationMessage: "¡El usuario ha sido activado!",
          },
          deactivationConfirmation: {
            header: "Desactivar usuario",
            content: "¿Estás seguro de que deseas desactivar al usuario {0}?",
            confirmationMessage: "¡El usuario ha sido desactivado!",
          },
          deleteConfirmation: {
            header: "Eliminar usuario",
            content: "¿Estás seguro de que deseas eliminar al usuario {0}?",
            confirmationMessage: "¡El usuario ha sido eliminado!",
          },
        },
      },
      components: {
        certification: {
          certified: "Certificado",
          notCertified: "Aún no certificado",
          expired: "Vencido",
          expires: "Vence",
          columns: {
            mandatoryContent: "Contenido obligatorio",
            optionalContent: "Contenido recomendado",
            complianceContent: "Contenido de cumplimiento",
            status: "Estado",
            startDate: "Fecha de inicio",
            endDate: "Fecha de finalización",
            certifiedAt: "Fecha de obtención",
            expiresAt: "Validez",
            certifyTo: "Fecha límite",
          },
          certificateStatus: "Estado del certificado",
          downloadAll: "Descargar todo",
        },
        roles: {
          user: "Usuario",
          moderator: "Moderador",
          admin: "Administrador",
          user_manager: "Administrador de usuarios",
          content_creator: "Creador de contenido",
          group_leader: "Administrador de grupos",
        },
      },
      details: {
        editUser: "Editar usuario",
        inviteUsers: "Invitar usuarios",
        userInformation: "Información del usuario",
        certification: "Certificación",
        trainings: "Entrenamientos",
        password: "Contraseña",
        passwordForm: {
          label: "Restablecer contraseña de usuario",
          sendEmail:
            "Enviar enlace de restablecimiento de contraseña por correo electrónico",
          generate: "Generar enlace de restablecimiento de contraseña",
          resetPassword: "Restablecer contraseña",
          userDisabledError:
            "No se puede restablecer la contraseña de un usuario desactivado",
          userNotConfirmedError:
            "No se puede restablecer la contraseña de un usuario no confirmado",
          userProviderError:
            "No se puede restablecer la contraseña de un usuario basado en SSO",
          linkGeneratedMessage:
            "Se ha generado el enlace de restablecimiento de contraseña",
          linkSentMessage:
            "Se ha enviado el enlace de restablecimiento de contraseña al usuario",
        },
        form: {
          firstName: "Nombre",
          lastName: "Apellido",
          email: "Correo electrónico",
          groups: "Grupos",
          role: "Rol",
          dyslexic: "Dislexia",
          registered: "Registrado",
          status: "Estado",
          confirmed: "Confirmado",
          deactivated: "Desactivado",
          waitingForConfirmation: "Esperando confirmación",
          lastOnline: "Última conexión",
          totalPlaytime: "Tiempo total de juego",
          resendConfirmation: "Reenviar correo de confirmación",
          resendInvitation: "Reenviar invitación",
          generateLink: "Generar enlace de confirmación",
          invitationSent: "La invitación ha sido enviada",
          confirmationRequestSent:
            "La solicitud de confirmación ha sido enviada",
          confirmationLink: "Enlace de confirmación",
          emailStatus: "Estado del correo electrónico",
          minutes: "minutos",
          dialogs: {
            linkConfirmation: {
              header: "Solicitar enlace de confirmación",
              content:
                "El enlace de confirmación permite al usuario confirmar su cuenta y comenzar a utilizar Knowingo. El enlace es único y confidencial. Compártelo solo con el titular de la cuenta seleccionada.",
            },
          },
          metaFields: "Campos de metadatos",
          fieldName: "Nombre del campo",
          fieldData: "Datos de campo",
        },
      },
      invitation: {
        form: {
          email: "Correo electrónico",
          emails: "Correos electrónicos",
          groups: "Grupos",
          role: "Rol",
          addMore: "Agregar más",
          groupsDescription:
            "Los usuarios invitados se convertirán en miembros de los grupos seleccionados.",
          groupManagerNote:
            "Los usuarios invitados deberán ser asignados manualmente a un grupo en la página de Grupos después de enviar la invitación.",
        },
        successMessage: "Las invitaciones han sido enviadas",
        submitButton: "Enviar",
      },
      import: {
        header: "Importar usuarios",
        selectFile: "Seleccionar archivo",
        currentlySelectedFile: "Archivo seleccionado actualmente",
        successMessage: "¡Carga completa!",
        usersCreated: "Los usuarios han sido creados",
        loadingText: "Cargando datos...",
        step1: "Paso 1: Seleccionar archivo",
        step2: "Paso 2: Seleccionar hoja de trabajo",
        step3: "Paso 3: Verificar datos",
        step4: "Paso 4: Cargar",
        headers: {
          email: "Correo electrónico",
          groups: "Grupos",
          firstname: "Nombre",
          lastname: "Apellido",
          disable_after: "Desactivar después de",
        },
        sendInvitations: "Enviar invitaciones",
        upload: "Cargar",
        back: "Volver a la lista de usuarios",
      },
      warnings: {
        no_managed_groups:
          "Visita la página de grupos y asigna a este usuario como administrador de al menos un grupo o cambia el rol.",
      },
    },
    groups: {
      header: "Grupos",
      createGroup: "Crear grupo",
      group: "Grupo",
      editGroup: "Editar grupo",
      errors: {
        teamNotEmpty: "El equipo no está vacío",
      },
      modals: {
        move: {
          title: "Mover grupos",
          moving: "Moviendo",
          newParent: "Seleccionar nuevo padre",
          move: "Mover",
        },
      },
      details: {
        form: {
          group: "Grupo",
          description: "Descripción",
          parent: "Padre",
          name: "Nombre",
          parentManager: "Gerente principal",
          parentManagerDescription:
            "Este gerente está asignado como gerente en uno de los grupos principales y no se puede eliminar aquí.",
          noManagersAssigned: "No se han asignado gerentes",
        },
        groupInformation: "Información del grupo",
        learningPlan: {
          course: "Curso",
          label: "Plan de aprendizaje",
          deadlineOptions: {
            days: "Días",
            weeks: "Semanas",
            months: "Meses",
            years: "Años",
          },
          table: {
            title: "Título",
            status: "Estado",
            deadline: "Fecha límite",
            startDate: "Fecha de inicio",
            occurrences: "Ocurrencias",
          },
          deadline: "Fecha límite",
          questions: "preguntas",
          published: "Publicado",
          unpublished: "No publicado",
          delete: "Eliminar",
          change: "Cambiar",
          complianceContent: "Contenido de cumplimiento",
          mandatoryContent: "Contenido obligatorio",
          optionalContent: "Contenido recomendado",
          indirectTopic: "Tema heredado",
          indirectTopicInfo:
            "Este tema se hereda de uno de los padres y no se puede cambiar ni eliminar en este lugar",
          conflictingTopic: "Tema en conflicto",
          conflictingTopicInfo:
            "La fecha límite o la importancia de este tema pueden ser anuladas por uno de los padres.",
          topicPresentInCourse: "El tema ya existe",
          topicPresentInCourseInfo:
            "Este tema forma parte de un curso asignado a este plan de aprendizaje",
          noOccurrences: "No hay ocurrencias",
          noOccurrencesInfo:
            "Este contenido de cumplimiento no tiene ocurrencias establecidas. Se requiere al menos una ocurrencia.",
          accept: "Aceptar",
          cancel: "Cancelar",
          complianceSettings: "Configuración de cumplimiento",
          startDate: "Fecha de inicio",
          endDate: "Fecha de finalización",
          cutoffDate: "No comenzar después de",
          name: "Nombre",
          addOccurrence: "Agregar ocurrencia",
          occurrence: "Ocurrencia",
          tooltips: {
            compliance: {
              title: "¿Qué es el cumplimiento?",
              description:
                "Los temas de cumplimiento deben ser completados por los usuarios en su rango de tiempo establecido. Las preguntas solo deben responderse una vez y proporcionarán una forma de auditar su base de usuarios.",
              startDate: "Fecha de inicio",
              startDateDescription:
                "Esta es la fecha en que el tema se pone a disposición de las poblaciones",
              endDate: "Fecha de finalización",
              endDateDescription:
                "Esta es la fecha límite para que los usuarios alcancen su fecha de cumplimiento",
              dontStartAfter: "No comenzar después de",
              dontStartAfterDescription:
                "Esta fecha impide que el tema aparezca para los usuarios agregados al plan de aprendizaje después de esta fecha. Esto lo hará disponible para ellos en la próxima fecha de inicio.",
            },
          },
        },
        trainings: {
          label: "Entrenamientos",
          indirectTraining: "Entrenamiento indirecto",
          indirectTrainingInfo:
            "Este entrenamiento se hereda de uno de los padres",
        },
        settings: {
          groupLeaders: "Líderes del grupo",
          reporting: "Reporte",
          label: "Ajustes",
          groupRanking: "Clasificación del grupo",
          impact: "Impacto",
          adoption: "Adopción",
          certification: "Certificación",
          enableReporting: "Habilitar reporte",
          reportType: "Tipo de reporte",
          reportFormat: "Formato de reporte",
          startDate: "Fecha de inicio",
          hour: "Hora",
          endDate: "Fecha de finalización",
          frequency: "Frecuencia",
          dayOfTheWeek: "Día de la semana",
          azureSelectDescription:
            "Los miembros del grupo Azure seleccionado se sincronizarán automáticamente durante la autenticación de SSO",
        },
        users: {
          label: "Usuarios",
          table: {
            registered: "Registrado",
            invited: "Invitado",
            columns: {
              user: "Usuario",
              member: "Miembro",
              status: "Estado",
              actions: "Acciones",
            },
            controls: {
              filters: {
                registered: "Registrado",
                invited: "Invitado",
                member: "Miembro",
                notMember: "No es miembro",
              },
            },
          },
        },
      },
      table: {
        columns: {
          select: "Seleccionar",
          group: "Grupo",
          description: "Descripción",
          users: "Usuarios",
          topics: "Temas",
          actions: {
            edit: "Editar",
            delete: "Eliminar",
            openInTab: "Abrir en nueva pestaña",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Eliminar grupo",
              content: "¿Estás seguro de que quieres eliminar el grupo {0}?",
              confirmationMessage: "¡El grupo ha sido eliminado!",
              dataLostWarning:
                "Se perderán todos los datos relacionados con este grupo y todos sus subgrupos, como la membresía en el grupo y los planes de aprendizaje.",
            },
          },
        },
        controls: {
          createButton: "Crear grupo",
          editGroupButton: "Cambiar ajustes para {0}",
          actionsButton: "Acciones",
          deleteButton: "Eliminar",
          moveButton: "Mover",
          dialogs: {
            deleteConfirmation: {
              header: "Eliminar grupos",
              content:
                "¿Estás seguro de que quieres eliminar los grupos seleccionados?",
              confirmationMessage:
                "¡Los grupos seleccionados han sido eliminados!",
              dataLostWarning:
                "Se perderán todos los datos relacionados con los grupos seleccionados y todos sus subgrupos, como la membresía en el grupo y los planes de aprendizaje.",
            },
          },
        },
        tooltips: {
          defaultGroup: {
            header: "Grupo predeterminado",
            content:
              "El grupo predeterminado está formado por todos los usuarios. Los miembros no pueden ser cambiados, pero puedes administrar el plan de aprendizaje que se aplicará a todos los miembros de tu organización.",
          },
        },
      },
    },
    topics: {
      header: "Temas",
      import: {
        successMessage: "El tema ha sido añadido a tu biblioteca",
        errors: {
          ownTopic: "El tema ya existe en tu biblioteca",
          invalidCode: "Código de compartición inválido",
        },
        form: {
          enterCode: "Ingresa el código de compartición",
        },
      },
      generate: {
        header: "Crear tema generado por IA",
        form: {
          fields: {
            input: {
              label: "Entrada",
              placeholder: "¿De qué debería tratar el tema? ",
            },
            numQuestions: {
              label: "Cantidad de preguntas",
            },
            numIntroduction: {
              label: "Cantidad de material de introducción",
            },
            baseLanguage: {
              label: "Idioma base",
            },
            context: {
              label: "Contexto",
              description:
                "Seleccione el archivo que será utilizado por el modelo para generar contenido.",
            },
          },
          questions: "Preguntas",
          introduction: "Introducción",
          introductionMaterials: "Materiales de introducción",
          review: "Revisión",
          advanced: "Avanzado",
        },
        generatingContent: "Generando contenido...",
      },
      details: {
        multiLanguage: {
          details: {
            topic: "Tema",
            localization: "Localización",
            info: "Información",
            createdMessage: "El tema ha sido creado",
            updatedMessage: "El tema ha sido actualizado",
          },
          form: {
            useBaseImage: "Usar imagen base",
            title: "Título",
            description: "Descripción",
            baseLanguage: "Idioma base",
            certified: "Certificado",
            questions: "preguntas",
            backgroundInfo: "información de fondo",
            introduction: "introducción",
            users: "usuarios",
            selectLanguage: "Seleccionar idioma",
            delete: "Eliminar",
            tooltips: {
              baseTopic: {
                header: "Idioma base",
                content: "No se puede eliminar el idioma base",
              },
            },
          },
          localizationForm: {
            language: "Idioma",
            base: "Idioma predeterminado",
            baseDescription:
              "La localización establecida como predeterminada es el idioma en el que se muestra el tema en el tablero. También se utilizará como fuente para la traducción automática. Y también se utilizará como recurso de respaldo en la aplicación de Knowingo si el idioma preferido por el usuario no está disponible.",
            addLocalization: "Añadir localización",
          },
        },
        standard: {
          details: {
            createdMessage: "El tema ha sido creado",
            updatedMessage: "El tema ha sido actualizado",
          },
          form: {
            useBaseImage: "Usar imagen base",
            title: "Título",
            description: "Descripción",
            certified: "Certificado",
            questions: "preguntas",
            backgroundInfo: "información de fondo",
            introduction: "introducción",
            users: "usuarios",
            delete: "Eliminar",
          },
        },
      },
      modals: {
        clone: {
          header: "Clonar tema",
          label: "Ingresa el nombre del tema",
          copy: "copiar",
          clone: "Clonar",
          cancel: "Cancelar",
          enterName: "Ingresa el nombre del tema",
        },
        convert: {
          header: "Convertir a tema multi-idioma",
          description:
            "Después de la conversión, los usuarios mantendrán sus estadísticas y certificación.",
          label: "Idioma",
          convert: "Convertir",
          cancel: "Cancelar",
          selectLanguage: "Seleccionar el idioma base",
        },
        publish: {
          header: "Publicar tema",
          publish: "Publicar",
          cancel: "Cancelar",
          multiLanguageTopicInfo:
            "Estás a punto de publicar el tema multi-idioma {0}.",
          regularTopicInfo:
            "Estás a punto de publicar {0} preguntas en el tema {1}.",
          publishableQuestionsInfo: "{0} preguntas serán publicadas",
          unpublishableQuestionsInfo:
            "{0} preguntas incompletas no pueden ser publicadas",
          contentFetchingError:
            "No pudimos obtener los datos. Por favor, intenta de nuevo más tarde.",
          publishedNotification: "El tema ha sido publicado",
          publishTopic: "Estás a punto de publicar el tema {0}",
          addMoreQuestions: "Añade {0} preguntas más para publicar este tema",
        },
      },
      table: {
        header: "Temas",
        createTopic: "Crear tema",
        importTopic: "Importar tema",
        editTopic: "Editar tema",
        status: {
          published: "Publicado",
          publishedDescription:
            "Este tema está publicado y contiene {0} preguntas",
          unpublished: "No publicado",
          metaUnpublishedDescription:
            "Al menos una de las traducciones contiene preguntas no publicadas",
          unpublishedDescription:
            "Hay {0} preguntas no publicadas en este tema",
          changed: "Cambios no publicados",
          metaChangedDescription:
            "Al menos una de las traducciones contiene cambios no publicados",
          changedDescription:
            "Hay {0} preguntas cambiadas y {1} preguntas no publicadas en este tema",
          shared: "Compartido",
          sharedDescription:
            "Este tema te ha sido compartido por otra organización",
          locked: "Bloqueado",
          lockedDescription: "Este tema está bloqueado y no se puede cambiar",
          users: "Tema no asignado",
          usersDescription:
            "Este tema no ha sido asignado a ningún plan de aprendizaje",
          meta: "Tema multi-idioma",
          metaDescription:
            "Este tema está preparado para ser traducido a varios idiomas",
          cannotPublish: "Añade {0} preguntas más para publicar este tema.",
        },
        controls: {
          filters: {
            published: "Publicado",
            unpublished: "No publicado",
            changed: "Cambios no publicados",
          },
          actions: {
            label: "Acciones",
            publish: "Publicar",
            unpublish: "No publicar",
            delete: "Eliminar",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Eliminar temas",
              content:
                "¿Estás seguro que deseas eliminar los temas seleccionados?",
              successMessage: "Los temas seleccionados han sido eliminados.",
            },
          },
          enterShareCode: "Ingresa el código de compartición",
          createTopic: "Crear tema",
          createTopicAI: "Crear tema con IA",
          createMultiLanguageTopic: "Crear tema multi-idioma",
        },
        components: {
          languageList: {
            tooltip: {
              title: "Localizaciones",
              description:
                "Agrega traducciones al contenido por defecto para asegurarte de que todos aprenden la misma cantidad",
              unpublishedQuestions: "{0} preguntas no publicadas",
              changedQuestions: "{0} preguntas cambiadas",
              noQuestions: "No hay preguntas",
              fullyTranslated: "Completamente traducido",
              translationMissing: "Traducción faltante",
            },
          },
        },
        columns: {
          select: "Seleccionar",
          topic: "Título",
          description: "Descripción",
          questions: "Preguntas",
          users: "Usuarios",
          status: "Estado",
          thumbnail: "Imagen",
          comments: "Comentarios",
          language: "Idioma",
          actions: {
            openQuestions: "Abrir preguntas",
            openIntroduction: "Abrir material de introducción",
            edit: "Editar",
            publish: "Publicar",
            unpublish: "No publicar",
            delete: "Eliminar",
            clone: "Clonar",
            convert: "Convertir a multi-idioma",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Eliminar tema",
              content:
                "¿Estás seguro que deseas eliminar el tema {0} y todas sus preguntas?",
              successMessage: "El tema ha sido eliminado",
            },
          },
        },
        publishedSuccessMessage: "El tema ha sido publicado",
        incompleteItemsWarning:
          "Algunos de los contenidos no pudieron ser publicados debido a un error. Por favor, verifique si todo el contenido está completo y vuelva a intentarlo.",
        unpublishedSuccessMessage: "El tema ha sido no publicado",
        removedSuccessMessage: "El tema ha sido eliminado",
        conversionStarted: "El tema será convertido pronto",
        errors: {
          deleteSharedTopic:
            "No se puede eliminar un tema compartido. Revoca todas las comparticiones existentes e intenta de nuevo.",
        },
      },
      components: {
        tooltips: {
          status: {
            published: "Publicado",
            unpublished: "No publicado",
            changed: "Cambios no publicados",
          },
        },
      },
    },
    courses: {
      header: "Cursos",
      table: {
        controls: {
          create: "Crear curso",
          shareCode: "Código de curso compartido",
          actions: {
            label: "Acciones",
            delete: "Eliminar",
          },
          filters: {
            published: "Publicado",
            unpublished: "No publicado",
          },
        },
        dialogs: {
          deleteConfirmation: {
            header: "Eliminar cursos",
            content:
              "¿Estás seguro de que quieres eliminar los cursos seleccionados?",
          },
        },
        columns: {
          select: "Seleccionar",
          thumbnail: "Imagen",
          name: "Nombre",
          description: "Descripción",
          num_published_topics: "# Temas publicados",
          languages: "Idiomas",
          num_topics: "# Temas",
          num_questions: "# Preguntas",
          num_users: "# Usuarios",
          actions: {
            label: "Acciones",
            edit: "Editar",
            delete: "Eliminar",
          },
        },
      },
      details: {
        header: "Curso",
        create: "Crear",
        navigation: {
          course: "Curso",
          localization: "Localización",
          topics: "Temas",
          info: "Información",
        },
        form: {
          inputs: {
            name: {
              label: "Nombre",
            },
            description: {
              label: "Descripción",
            },
            defaultLanguage: {
              label: "Idioma predeterminado",
            },
            courseSettings: {
              label: "Configuración del curso",
              text: "Ordenamiento y bloqueo de temas",
            },
          },
        },
        topicsForm: {
          topics: "Temas",
          selectTopic: "Seleccionar tema",
          openTopic: "Abrir tema en una nueva pestaña",
          showTranslations: "Mostrar traducciones",
          hideTranslations: "Ocultar traducciones",
          noTopics: "No hay temas",
        },
        localizationForm: {
          language: "Idioma",
          default: "Predeterminado",
          addLocalization: "Añadir localización",
        },
        localizationModal: {
          header: "Configurar localización",
          selectDefaultLanguage: "Seleccionar idioma predeterminado",
          selectTranslations: "Seleccionar traducciones",
          saveSettings: "Guardar estos ajustes para cursos recién creados",
          changesPossible:
            "Aún puedes cambiar el idioma predeterminado y añadir o eliminar traducciones después de cerrar este diálogo.",
          optional: "Opcional",
        },
      },
      dialogs: {
        deleteConfirmation: {
          header: "Eliminar curso",
          content: "¿Estás seguro de que quieres eliminar el curso {0}?",
        },
      },
      tooltips: {
        languageList: {
          translations: "Traducciones",
        },
        publishedTopics: {
          publishedTopics: "Temas publicados",
          noPublishedTopics: "Este curso no contiene temas publicados",
          hasUnpublishedTopics: "Este curso contiene temas no publicados",
        },
      },
    },
    shares: {
      library: {
        header: "Biblioteca compartida",
        createLibraryShare: "Crear compartición de biblioteca",
        editLibraryShare: "Editar compartición de biblioteca",
        details: {
          form: {
            required: "Se requiere el tema",
            mustBePublished:
              "El tema debe ser publicado para agregarlo a la biblioteca de contenidos",
            imageRequired:
              "Se requiere la imagen del tema para agregarlo a la biblioteca de contenidos",
            sharingExplanation:
              "Compartir en la biblioteca de contenidos permite a otras empresas utilizar tu contenido en sus planes de aprendizaje",
            submissionWarning:
              "Las preguntas, la información de fondo y los materiales de introducción no se podrán editar hasta que se haya completado el envío",
          },
        },
        table: {
          declined: "rechazado",
          outdated: "obsoleto",
          published: "publicado",
          inReview: "en revisión",
          columns: {
            select: "Seleccionar",
            topic: "Tema",
            holders: "Empresas",
            created: "Compartido",
            hide_questions: "Ocultar preguntas",
            num_users: "Usuarios",
            num_total_certificates: "Certificados obtenidos",
            training_minutes: "Minutos de capacitación",
            status: "Estado",
            actions: {
              label: "Acciones",
              update: "Actualizar",
            },
          },
          controls: {
            createShare: "Crear compartición",
            actions: {
              label: "Acciones",
              updateSelected: "Actualizar seleccionado",
            },
          },
        },
      },
      sharelinks: {
        header: "Enlaces de compartición",
        createShare: "Crear enlace de compartición",
        editShare: "Editar enlace de compartición",
        details: {},
        table: {
          yes: "Sí",
          no: "No",
          controls: {
            createShare: "Crear compartición",
            actions: {
              label: "Acciones",
              revoke: "Revocar",
            },
            dialogs: {
              revokeConfirmation: {
                header: "Revocar enlaces de compartición",
                content:
                  "¿Estás seguro de que deseas revocar los enlaces de compartición seleccionados?",
                successMessage:
                  "Los enlaces de compartición seleccionados se han revocado",
              },
            },
          },
          columns: {
            select: "Seleccionar",
            topic: "Tema",
            holders: "Empresas",
            created: "Compartido",
            hide_questions: "Ocultar preguntas",
            num_users: "Usuarios",
            num_total_certificates: "Certificados obtenidos",
            training_minutes: "Minutos de capacitación",
            actions: {
              label: "Acciones",
              edit: "Editar",
              unhide: "Mostrar preguntas",
              hide: "Ocultar preguntas",
              revoke: "Revocar",
            },
            dialogs: {
              revokeConfirmation: {
                header: "Revocar enlace de compartición",
                content:
                  "¿Estás seguro de que deseas revocar el enlace de compartición seleccionado?",
                successMessage: "El enlace de compartición se ha revocado",
              },
            },
          },
        },
      },
    },
    signupURLs: {
      header: "URLs de registro",
      createUrl: "Crear URL de registro",
      editUrl: "Editar URL de registro",
      details: {
        urlCreated: "La URL de registro ha sido creada",
        form: {
          signupURL: "URL de registro",
          groups: "Grupos",
        },
      },
      settings: {
        form: {
          domainPlaceholder: "Nombre de dominio, por ejemplo knowingo.com",
          add: "Agregar",
          enableNotifications: "Habilitar notificaciones",
          frequency: "Frecuencia",
          dayOfTheWeek: "Día de la semana",
          dayOfTheWeekDescription:
            "Los informes se enviarán automáticamente en la última ocurrencia del día seleccionado cada mes.",
          hour: "Hora",
          allowedDomains: "Dominios permitidos",
          allowedDomainsDescription:
            "Si ingresas uno o más dominios en el campo proporcionado, solo se aceptarán direcciones de correo electrónico asociadas con esos dominios para registrarse. Los usuarios con direcciones de correo electrónico fuera de estos dominios especificados no podrán acceder a la plataforma. Si el campo de lista de dominios se deja vacío, los usuarios con direcciones de correo electrónico de cualquier dominio podrán registrarse sin restricciones.",
          notifications: "Notificaciones",
          save: "Guardar",
          addDomain: "Agregar dominio",
        },
      },
      table: {
        controls: {
          createLink: "Crear nuevo enlace",
          settings: "Configuración",
          actions: {
            label: "Acciones",
            refresh: "Actualizar",
            delete: "Eliminar",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Eliminar URLs de registro",
              content:
                "¿Estás seguro de que deseas eliminar las URLs de registro seleccionadas?",
              successMessage:
                "Las URLs de registro seleccionadas se han eliminado",
            },
          },
        },
        columns: {
          code: "Código",
          groups: "Grupos",
          created: "Creado",
          actions: {
            copyLink: "Copiar enlace",
            edit: "Editar",
            refresh: "Actualizar",
            delete: "Eliminar",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Eliminar URL de registro",
              content:
                "¿Estás seguro de que deseas eliminar la URL de registro seleccionada?",
              successMessage: "La URL de registro ha sido eliminada",
            },
          },
        },
      },
    },
    feedback: {
      header: "Comentarios",
      details: {
        commentDeleted: "Comentario eliminado",
        cannotDeleteComment:
          "No se pudo eliminar el comentario. Por favor, inténtelo de nuevo más tarde",
        delete: "Eliminar",
        status: "Estado",
        new: "Nuevo",
        resolved: "Resuelto",
        closed: "Cerrado",
        responseSaved: "Respuesta guardada",
        yourResponse: "Tu respuesta",
        addResponse: "Agregar respuesta",
        notifyFeedbackAuthor: "Notificar al autor del comentario",
      },
      table: {
        filters: {
          new: "Nuevo",
          resolved: "Resuelto",
          closed: "Cerrado",
        },
        controls: {
          actions: {
            label: "Acciones",
            delete: "Eliminar",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Eliminar comentario",
              content:
                "¿Estás seguro de que quieres eliminar los elementos de comentarios seleccionados?",
              successMessage:
                "Los elementos de comentarios seleccionados han sido eliminados",
            },
          },
        },
        columns: {
          select: "Seleccionar",
          firstname: "Nombre",
          lastname: "Apellido",
          email: "Correo electrónico",
          title: "Título",
          text: "Texto",
          status: "Estado",
          created: "Creado",
          responses: "Respuestas",
          actions: {
            label: "Acciones",
            delete: "Eliminar",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Eliminar comentario",
              content:
                "¿Estás seguro de que quieres eliminar el comentario {0}?",
              successMessage: "El comentario ha sido eliminado",
            },
          },
          deletedUser: "Usuario eliminado",
        },
      },
    },
    questionAnalytics: {
      header: "Analítica de preguntas",
      details: {
        components: {
          cards: {
            misconception: "Malentendido",
            misconceptionDescription:
              "Un malentendido ocurre cuando un segmento significativo de tus estudiantes proporciona la misma respuesta incorrecta. Utiliza esta métrica para descubrir dónde tus estudiantes tienen grandes malentendidos.",
            complexity: "Complejidad",
            complexityDescription:
              "Las preguntas se consideran complejas cuando tus estudiantes tardan más tiempo del esperado para responderlas. Tus estudiantes podrían considerar esta pregunta compleja si es demasiado larga, contiene jerga desconocida o no pertenece a este tema. Edita las preguntas altamente complejas para obtener mejores resultados.",
            obviousness: "Evidencia",
            obviousnessDescription:
              "Las preguntas se consideran evidentes cuando tus estudiantes pueden responder correctamente sin ningún aprendizaje adicional. Si Knowingo+ nota que un segmento significativo de tus estudiantes responde correctamente esta pregunta la primera vez que la ven, la puntuación de evidencia de esta pregunta aumenta.",
            error: "Error",
            errorDescription:
              "La puntuación de error indica si los expertos parecen estar en desacuerdo con la respuesta correcta a esta pregunta. Cuando la mayoría de los expertos en un tema seleccionan la misma respuesta incorrecta, es muy probable que haya problemas con tu pregunta, o que la respuesta incorrecta que han elegido tus expertos sea en realidad la correcta.",
            checkmarkDescription:
              "Puedes hacer clic en la marca de verificación para que la puntuación de error no aparezca en la lista de Analítica de preguntas",
            hideErrorScoreWarning:
              "Desactivar advertencia de puntuación de error",
          },
        },
        answerContribution:
          "El {0} de tu base de usuarios ha seleccionado {1} como la respuesta correcta",
        expertsAgree:
          "La mayoría de los expertos están de acuerdo en que esta es la respuesta correcta",
        expertsContribution:
          "{0} de los expertos creen que {1} es la respuesta correcta",
        expertsNotAvailable:
          "Las estadísticas de expertos no están disponibles todavía para esta pregunta",
        sec: "seg",
        avgAnsweringSpeed: "Velocidad promedio de respuesta",
        usersIntroduced: "Usuarios introducidos",
        answerStatistics: "Estadísticas de respuestas",
        openQuestion: "Pregunta abierta",
      },
      table: {
        published: "Publicado",
        changed: "Cambios no publicados",
        unpublished: "No publicado",
        incomplete: "Incompleto",
        details: "Detalles",
        goToQuestion: "Ir a la pregunta",
        columns: {
          category_name: "Tema",
          title: "Pregunta",
          status: "Estado",
          misconception_score: "Malentendido",
          complexity_score: "Complejidad",
          obviousness_score: "Evidencia",
          content_error_score: "Error de contenido",
          num_users_seen: "Visto por",
          num_users: "Disponible para",
        },
        controls: {
          showWithoutMetrics: "Mostrar preguntas sin métricas",
          filters: {
            published: "Publicado",
            unpublished: "No publicado",
            changed: "Cambios no publicados",
            high_misconception: "Alto malentendido",
            high_obviousness: "Alta evidencia",
            high_complexity: "Alta complejidad",
            high_error_score: "Alta puntuación de error",
          },
          allTopics: "Todos los temas",
          allGroups: "Todos los grupos",
          allLanguages: "Todos los idiomas",
        },
      },
    },
    contentLibrary: {
      header: "Biblioteca de contenido",
      grid: {
        questions: "preguntas",
        backgroundInformation: "información de fondo",
        introductionMaterial: "material de introducción",
        owned: "Propio",
        add: "Agregar",
        languages: "Idiomas disponibles",
        topicAdded: "El tema ha sido agregado a tu biblioteca",
      },
    },
    reporting: {
      newReport: "Nuevo informe",
      table: {
        actions: {
          delete: "Eliminar",
          run: "Ejecutar",
          edit: "Editar",
        },
        controls: {
          actions: {
            label: "Acciones",
            delete: "Eliminar",
            run: "Ejecutar",
          },
          create: "Crear línea de informe",
        },
        columns: {
          name: "Nombre",
          report: "Informe",
          users: "Destinatarios",
          creator: "Creado por",
          frequency: "Frecuencia",
          enabled: "Habilitado",
        },
        filters: {
          ownReports: "Mis informes",
        },
      },
      dialogs: {
        delete: {
          header: "Eliminar informe",
          content: "¿Estás seguro de que quieres eliminar el informe {0}?",
        },
        deleteMany: {
          header: "Eliminar informes seleccionados",
          content:
            "¿Estás seguro de que quieres eliminar los informes seleccionados?",
        },
      },
      components: {
        allTeams: "Todos los equipos",
        allTopics: "Todos los temas",
        selected: "Seleccionados",
        reportFormat: "Formato del informe",
        selectGroups: "Seleccionar grupos",
        selectCompliance: "Seleccionar cumplimiento",
      },
      adoption: "Adopción",
      groupRanking: "Ranking de grupos",
      questionAnalytics: "Analítica de preguntas",
      impact: "Impacto",
      certificates: "Certificados",
      userDump: "Volcado de usuarios",
      compliance: "Cumplimiento",
      header: "Informes",
      selectReportType: "Seleccionar tipo de informe",
      details: {
        reportSettings: "Configuración del informe",
        recipients: "Destinatarios",
      },
      oneTimeReport: "Informe único",
      reoccurringReport: "Informe recurrente",
    },
    settings: {
      account: {
        header: "Información de cuenta",
        form: {
          basicInformation: "Información básica",
          companyName: "Nombre de la empresa",
          street: "Calle",
          postcode: "Código postal",
          city: "Ciudad",
          state: "Estado/Provincia",
          country: "País",
          phone: "Teléfono",
          vat: "Número VAT/GST",
          contact: "Contacto",
          firstName: "Nombre",
          lastName: "Apellido",
          email: "Dirección de correo electrónico",
        },
      },
      general: {
        header: "Configuraciones generales",
        form: {
          languageSettings: "Configuraciones de idioma",
          description:
            "Las configuraciones de idioma se aplican a los nuevos usuarios y pueden sobrescribirse en el perfil de usuario.",
          dashboardLanguage: "Idioma del tablero",
          dashboardLanguageDescription:
            "Determinar el idioma predeterminado de la interfaz de usuario.",
          appLanguage: "Idioma de la aplicación",
          appLanguageDescription:
            "Determinar el idioma predeterminado de la aplicación y los correos electrónicos que envía.",
        },
      },
      whiteLabeling: {
        header: "Personalización de marca",
        form: {
          companyLogo: "Logotipo de la empresa",
          openPreview: "Abrir vista previa",
          personalizeParagraph:
            "Personaliza Knowingo con tu logotipo. El logotipo aparecerá en la aplicación en diferentes pantallas con un mensaje opcional. En la página de registro, el tablero y diferentes correos electrónicos.",
          uploadLogoDark: "Cargar tu logotipo para fondos oscuros",
          uploadLogoLight: "Cargar tu logotipo para fondos claros",
          additionalText: "Texto adicional del logotipo",
          additionalTextDescription:
            "El texto adicional del logotipo se mostrará en la aplicación Knowingo a todos tus empleados.",
          additionalEmailText: "Texto adicional del correo electrónico",
          additionalEmailTextDescription:
            "El texto adicional del correo electrónico se incluirá en todas las invitaciones y recordatorios que Knowingo envíe a tus empleados.",
          appCustomisation: "App customisation",
          invitationCustomisation: "Invitation email customisation",
          certificateCustomisation: "Certificate customisation",
          additionalCertificateText: "Additional certificate text",
          additionalCertificateTextDescription:
            "Additional certificate text will be included in all certificates issued to users after completing a topic.",
          customFooterImage: "Custom footer image",
          customFooterImageDescription:
            "Custom footer image may contain company stamp or additional logos. Company logo is included by default in certificate header.",
          uploadImage: "Upload image",
          downloadExampleCertificate: "Download certificate preview",
          downloadExampleCertificateDisclaimer:
            "Please save any changes to the form before downloading the certificate preview.",
          includeCompanyLogo: "Include company logo",
          includeCompanyLogoDescription:
            "Company logo will be included in downloaded certificate and placed in top-right corner.",
        },
        preview: {
          appPreview: "Vista previa de la aplicación",
          registrationPreview: "Vista previa del registro",
          toggle: "Alternar entre vistas previas de modo claro y oscuro",
        },
      },
      webhooks: {
        header: "Webhooks",
        table: {
          controls: {
            create: "Crear nuevo webhook",
            shareCode: "Webhook share code",
            actions: {
              label: "Acciones",
              delete: "Eliminar",
            },
            filters: {
              published: "Publicados",
              unpublished: "No publicados",
            },
          },
          dialogs: {
            createConfirmation: {
              successMessage: "Webhook creado",
            },
            updateConfirmation: {
              successMessage: "Webhook actualizado",
            },
            deleteConfirmation: {
              header: "Eliminar Webhook",
              content: "¿Estás seguro de que quieres eliminar este Webhook?",
              successMessage: "Webhook eliminado.",
            },
            notifyConfirmation: {
              successMessage: "URL Valida",
              invalidUrl: "URL no válida",
              urlValiderrorMessage: "URL Válida. Método POST no permitido",
              urlValiderrorNotFound: "URL Válida. Método POST no encontrado",
            },
          },
          columns: {
            name: "Nombre",
            event_names: "Nombres de eventos",
            callback_url: "URL de devolución de llamada",
            enabled: "Habilitado",
            num_failures: "# Fallos",
            actions: {
              label: "Acciones",
              delete: "Eliminar",
              edit: "Editar",
              trigger: "Activar webhook",
            },
          },
        },
        details: {
          webhookCreated: "Webhook creado",
          webhookUpdated: "Webhook actualizado",
          form: {
            name: "Nombre",
            eventName: "Evento",
            callbackURL: "URL",
            notifyWebhook: "Pruébame!",
            invalidUrl: "URL no válida",
          },
        },
        tooltips: {
          languageList: {
            translations: "Traducciones",
          },
          publishedWebhooks: {
            publishedWebhooks: "Webhooks publicados",
            noPublishedWebhooks: "Este curso no contiene Webhooks publicados",
            hasUnpublishedWebhooks:
              " Este curso contiene Webhooks sin publicar",
          },
        },
        createWebhook: "Crear Webhook",
        editWebhook: "Editar Webhook",
      },
      api: {
        header: "Configuración de la API",
        form: {
          manageKeys: "Administrar claves de la API",
          createApiKey: "Crear clave de la API",
          description: "Descripción",
          value: "Valor",
          created: "Creado",
          lastUsed: "Último uso",
          deleteKey: "Eliminar clave de la API",
          deleteConfirmationPrompt:
            "Estás a punto de eliminar una clave de la API. Cualquier servicio que esté utilizando actualmente esta clave perderá el acceso de inmediato. Asegúrate de haber actualizado todos los servicios relevantes con una clave alternativa antes de proceder con la eliminación.",
        },
      },
    },
    questions: {
      header: "Preguntas",
      topics: "Temas",
      questions: "Preguntas",
      introductionMaterial: "Material introductorio",
      createQuestion: "Crear pregunta",
      editQuestion: "Editar pregunta",
      modals: {
        copy: {
          header: "Copiar preguntas",
          successMessage: "Las preguntas han sido copiadas",
          metaTopicError:
            "El tema de destino debe tener el mismo conjunto de idiomas que el tema de origen",
          save: "Copiar",
          cancel: "Cancelar",
        },
        move: {
          header: "Mover preguntas",
          successMessage: "Las preguntas han sido movidas",
          metaTopicError:
            "El tema de destino debe tener el mismo conjunto de idiomas que el tema de origen",
          save: "Mover",
          cancel: "Cancelar",
        },
        addTags: {
          header: "Agregar etiquetas",
          save: "Guardar",
          cancel: "Cancelar",
        },
        removeTags: {
          header: "Eliminar etiquetas",
          save: "Guardar",
          cancel: "Cancelar",
        },
        autoTranslate: {
          header: "Traducción automática",
          translate: "Traducir",
          cancel: "Cancelar",
          warning:
            "Esta acción sobrescribirá el contenido existente en las preguntas seleccionadas para los idiomas seleccionados.",
          generatingTranslations: "Generando traducciones",
          incompleteTranslations:
            "La traducción ha terminado, pero algunas partes del contenido no se tradujeron debido a un error. Revise estas piezas de contenido y vuelva a intentarlo.",
        },
      },
      table: {
        status: {
          published: "Publicado",
          publishedDescription:
            "Esta pregunta está publicada y disponible para jugar",
          unpublished: "No publicado",
          unpublishedDescription: "Esta pregunta no está publicada",
          changed: "Cambios no publicados",
          changedDescription: "Esta pregunta contiene cambios no publicados",
          incomplete: "Incompleto",
          incompleteDescription:
            "Esta pregunta está incompleta y no se puede publicar",
          backgroundInfo: "Información de fondo",
          backgroundInfoDescription:
            "Esta pregunta contiene información de fondo",
          noBackgroundInfoDescription:
            "Esta pregunta no contiene información de fondo",
        },
        controls: {
          createQuestion: "Crear pregunta",
          importQuestions: "Importar preguntas",
          downloadTemplate: "Descargar plantilla",
          actions: {
            label: "Acciones",
            publish: "Publicar",
            unpublish: "Quitar publicación",
            copy: "Copiar",
            move: "Mover",
            delete: "Eliminar",
            addTags: "Agregar etiquetas",
            removeTags: "Eliminar etiquetas",
            autoTranslate: "Traducción automática",
          },
          filters: {
            published: "Publicado",
            unpublished: "No publicado",
            changed: "Cambios no publicados",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Eliminar preguntas",
              content:
                "¿Estás seguro de que deseas eliminar las preguntas seleccionadas?",
              successMessage: "Las preguntas seleccionadas han sido eliminadas",
            },
          },
        },
        columns: {
          select: "Seleccionar",
          thumbnail: "Imagen",
          question: "Pregunta",
          tags: "Etiquetas",
          status: "Estado",
          comments: "Comentarios",
          backgroundInfo: "Información de fondo",
          actions: {
            edit: "Editar",
            publish: "Publicar",
            publishAll: "Publicar todas las traducciones",
            unpublish: "Quitar publicación",
            unpublishAll: "Quitar publicación a todas las traducciones",
            delete: "Eliminar",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Eliminar pregunta",
              content: '¿Estás seguro de que deseas eliminar la pregunta "{0}"',
              successMessage: "La pregunta ha sido eliminada",
            },
          },
        },
      },
      details: {
        components: {
          comments: {
            deletedMessage: "El comentario ha sido eliminado",
            savedMessage: "El comentario ha sido guardado",
            delete: "Eliminar",
            yourComment: "Tu comentario",
            addComment: "Agregar comentario",
          },
        },
        form: {
          useBaseImage: "Usar medio base",
          question: "Pregunta",
          answers: "Respuestas",
          tags: "Etiquetas",
          backgroundInformation: "Información de fondo",
          enableBackgroundInformation: "Habilitar información de fondo",
          majorChange: "Cambio importante",
          majorChangeDescription:
            "Activa esto y vuelve a publicar esta pregunta si crees que los cambios que hiciste en esta pregunta son lo suficientemente importantes como para restablecer las estadísticas asociadas con esta pregunta. Esto asegurará que incluso los jugadores que ya hayan dominado esta pregunta aprendan los nuevos cambios.",
          lastUpdate: "Última actualización",
          title: "Título",
          description: "Descripción",
          comments: "Comentarios",
          preview: "Vista previa",
          createdMessage: "La pregunta ha sido creada",
          updatedMessage: "La pregunta ha sido actualizada",
          commentsUnavailable:
            "La sección de comentarios no está disponible en temas compartidos o bloqueados",
        },
        errors: {
          itemChanged:
            "El elemento ha cambiado en un servidor mientras se editaba. Actualiza la página y vuelve a enviar los cambios.",
          problemsOccurred:
            "Han ocurrido problemas al guardar las siguientes traducciones:",
        },
      },
      import: {
        header: "Import questions",
        selectFile: "Select file",
        selectedFile: "Selected file",
        downloadTemplate: "Descargar plantilla",
        step1: "Step 1: Select file",
        step2: "Step 2: Preview content",
        step3: "Step 3: Upload questions",
        selectWorksheet: "Select worksheet",
        errors: {
          invalidSheetNames:
            "Selected workbook contains invalid sheet names. Valid sheet names are: {0}.",
          missingTranslation:
            "Translations for following languages are missing: {0}. Empty questions will be created.",
        },
        uploadComplete: "Upload complete",
        questionsCreated: "Questions have been created",
        backToQuestionList: "Back to question list",
      },
      messages: {
        questionPublished: "La pregunta ha sido publicada",
        questionsPublished: "Las preguntas seleccionadas han sido publicadas",
        questionUnpublished: "La pregunta no ha sido publicada",
        questionsUnpublished:
          "Las preguntas seleccionadas no han sido publicadas",
        incompleteQuestionsNotPublished:
          "No se pudieron publicar {0} preguntas incompletas",
        incompleteQuestionNotPublished:
          "No se pudo publicar {0} pregunta incompleta",
      },
    },
    introduction: {
      header: "Introducción",
      topics: "Temas",
      questions: "Preguntas",
      introductionMaterial: "Material de introducción",
      createIntroduction: "Crear introducción",
      editIntroduction: "Editar introducción",
      table: {
        status: {
          published: "Publicado",
          publishedDescription:
            "La introducción está publicada y disponible para jugar",
          unpublished: "No publicado",
          unpublishedDescription: "La introducción no está publicada",
          changed: "Cambiado",
          changedDescription: "La introducción contiene cambios no publicados",
          incomplete: "Incompleto",
          incompleteDescription:
            "La introducción está incompleta y no se puede publicar",
        },
        controls: {
          createIntroduction: "Crear introducción",
          importIntroduction: "Importar introducción",
          downloadTemplate: "Descargar plantilla",
          actions: {
            label: "Acciones",
            publish: "Publicar",
            unpublish: "No publicar",
            delete: "Eliminar",
            autoTranslate: "Traducción automática",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Eliminar introducción",
              content:
                "¿Estás seguro de que quieres eliminar los materiales de introducción seleccionados?",
              successMessage: "El material de introducción ha sido eliminado",
            },
          },
          messages: {
            selectedPublished:
              "Los materiales de introducción seleccionados han sido publicados",
            selectedUnpublished:
              "Los materiales de introducción seleccionados no han sido publicados",
          },
        },
        messages: {
          published: "El material de introducción ha sido publicado",
          unpublished: "El material de introducción no ha sido publicado",
        },
        published: "Publicado",
        changed: "Cambiado",
        unpublished: "No publicado",
        incomplete: "Incompleto",
        columns: {
          select: "Seleccionar",
          title: "Introducción",
          thumbnail: "Imagen",
          status: "Estado",
          actions: {
            edit: "Editar",
            moveUp: "Mover hacia arriba",
            setIndex: "Establecer índice",
            moveDown: "Mover hacia abajo",
            publish: "Publicar",
            unpublish: "No publicar",
            publishAll: "Publicar todas las traducciones",
            unpublishAll: "No publicar todas las traducciones",
            delete: "Eliminar",
          },
          dialogs: {
            replaceIndex: {
              header: "Cambiar índice",
              save: "Guardar",
              successMessage: "El índice de introducción ha sido reemplazado",
            },
            deleteConfirmation: {
              header: "Eliminar introducción",
              content:
                '¿Estás seguro de que quieres eliminar la introducción "{0}"?',
              successMessage: "El material de introducción ha sido eliminado",
            },
          },
        },
      },
      details: {
        introduction: "Introducción",
        text: "Texto",
        form: {
          useBaseImage: "Usar media de base",
        },
        createdMessage: "Se ha creado el elemento de introducción",
        updatedMessage: "Se ha actualizado el elemento de introducción",
        errors: {
          itemChanged:
            "El elemento ha cambiado en el servidor mientras se editaba. Actualiza la página y envía los cambios nuevamente.",
          problemsOccurred:
            "Se produjeron problemas al guardar las siguientes traducciones:",
        },
      },
      modals: {
        autoTranslate: {
          header: "Traducción automática",
          translate: "Traducir",
          cancel: "Cancelar",
          warning:
            "Esta acción sobrescribirá el contenido existente en los elementos de introducción seleccionados para los idiomas seleccionados.",
          generatingTranslations: "Generando traducciones",
          incompleteTranslations:
            "La traducción ha terminado, pero algunas partes del contenido no se tradujeron debido a un error. Revise estas piezas de contenido y vuelva a intentarlo.",
        },
      },
      import: {
        header: "Importar materiales de introducción",
        selectFile: "Seleccionar archivo",
        selectedFile: "Archivo seleccionado",
        downloadTemplate: "Descargar plantilla",
        step1: "Paso 1: Seleccionar archivo",
        step2: "Paso 2: Previsualizar contenido",
        step3: "Paso 3: Subir materiales de introducción",
        selectWorksheet: "Seleccionar hoja de cálculo",
        errors: {
          invalidSheetNames:
            "El libro de trabajo seleccionado contiene nombres de hojas no válidos. Los nombres válidos de hojas son: {0}.",
          missingTranslation:
            "Faltan traducciones para los siguientes idiomas: {0}. Se crearán materiales de introducción vacíos.",
        },
        uploadComplete: "Carga completa",
        introductionCreated: "Los materiales de introducción han sido creados",
        backToIntroductionList: "Volver a la lista de introducción",
      },
      messages: {
        introductionItemPublished:
          "El elemento de introducción ha sido publicado",
        introductionItemsPublished:
          "Los elementos de introducción seleccionados han sido publicados",
        introductionItemUnpublished:
          "El elemento de introducción seleccionado no ha sido publicado",
        introductionItemsUnpublished:
          "Los elementos de introducción seleccionados no han sido publicados",
        incompleteIntroductionItemsNotPublished:
          "No se pudieron publicar {0} elementos de introducción incompletos",
        incompleteIntroductionItemNotPublished:
          "No se pudo publicar el elemento de introducción incompleto {0}",
      },
    },
    certification: {
      header: "Certificación",
      table: {
        filters: {
          certified: "Certificado",
          onTrack: "En curso",
          uncertified: "No certificado",
          expired: "Caducado",
          missed: "Plazo incumplido",
          mandatory: "Obligatorio",
          optional: "Recomendado",
        },
        columns: {
          firstName: "Nombre",
          lastName: "Apellido",
          email: "Email",
          status: "Estado del certificado",
          topic: "Tema",
          isMandatory: "Obligatorio",
          deadline: "Fecha límite",
          certifiedAt: "Fecha de obtención",
          expiresAt: "Validez",
        },
      },
    },
    externalTraining: {
      header: "Entrenamiento externo",
      table: {
        controls: {
          actions: {
            label: "Acciones",
            delete: "Eliminar",
            edit: "Editar",
          },
          create: "Crear entrenamiento",
        },
        columns: {
          name: "Nombre del entrenamiento externo",
          num_users: "# Usuarios",
          num_invited: "# Usuarios invitados",
          num_completed: "# Usuarios completados",
          tags: "Etiquetas",
        },
      },
      details: {
        createTraining: "Crear entrenamiento",
        editTraining: "Editar entrenamiento",
        form: {
          group: "Grupo",
          users: "Usuarios",
          noGroupsSelected: "Ningún grupo seleccionado",
          inputs: {
            name: {
              label: "Nombre del entrenamiento",
            },
            groups: {
              placeholder: "Agregar grupo",
            },
          },
        },
      },
      dialogs: {
        deleteConfirmation: {
          header: "Eliminar entrenamiento",
          content:
            "¿Estás seguro de que quieres eliminar el entrenamiento {0}?",
        },
        deleteManyConfirmation: {
          header: "Eliminar entrenamientos",
          content:
            "¿Estás seguro de que quieres eliminar los entrenamientos seleccionados?",
          dataLostWarning:
            "Esta acción es permanente. Se perderán todos los datos relacionados con los entrenamientos seleccionados.",
        },
      },
      users: {
        externalTraining: "Entrenamiento externo",
        table: {
          controls: {
            actions: {
              label: "Acciones",
              invite: "Invitar",
              setStatus: "Cambiar estado",
            },
            filters: {
              readyForInvite: "Listos para invitar",
              invited: "Invitados",
              completed: "Completados",
              incomplete: "Incompletos",
            },
            downloadList: "Descargar lista",
          },
          columns: {
            email: "Correo electrónico",
            start: "Invitado para",
            location: "Ubicación",
            status: "Estado",
            tags: "Etiquetas",
          },
        },
        modals: {
          update: {
            header: "Actualizar seleccionados",
            selectStatus: "Seleccionar estado",
          },
        },
        invite: {
          header: "Invitar usuarios",
          form: {
            createNewEvent: "Crear evento nuevo",
            selectExistingEvent: "Seleccionar evento existente",
            selectEvent: "Seleccionar evento",
            event: "Evento",
            invite: "Invitar",
            startDate: "Fecha de inicio",
            selectStartDate: "Seleccionar fecha de inicio",
            endDate: "Fecha de finalización",
            selectEndDate: "Seleccionar fecha de finalización",
            location: "Ubicación",
            enableCustomInvitationMessage:
              "Mensaje personalizado de invitación",
            customInvitationMessage:
              "Mensaje personalizado para el correo de invitación",
            enableCustomCompletionMessage:
              "Mensaje personalizado de finalización",
            customCompletionMessage:
              "Mensaje personalizado para el correo de finalización",
            errors: {
              startInPast: "La fecha de inicio no puede estar en el pasado",
              endBeforeStart:
                "La fecha de finalización no puede ser anterior a la fecha de inicio",
            },
          },
        },
      },
    },
    marketplace: {
      header: "Mercado",
      bundle: {
        lastPublished: "Última publicación",
        availableIn: "Disponible en",
        tags: "Etiquetas",
        getQuote: "Obtener una cotización",
        bundleIncludes: "Este paquete incluye",
        numCourses: "{0} cursos",
        numTopics: "Total de {0} temas",
        numBackgroundInformation: "{0} información de fondo",
        numIntroductionMaterial: "{0} material de introducción",
        numQuestions: "{0} preguntas",
        continuousSupport: "Soporte continuo de Knowingo",
        certifiedContent: "Contenido certificado por Knowingo",
        openBundle: "Abrir paquete",
        owned: "Propio",
        topics: "Temas",
        courses: "Cursos",
        quoteModal: {
          header: "Solicitar una cotización",
          content:
            "Al enviar esto, se alertará al equipo de ventas de Knowingo para proporcionar una cotización. Se pondrán en contacto contigo para establecer los términos de acceso a este contenido.",
          confirmation: "La solicitud de cotización ha sido enviada",
        },
      },
      components: {
        tagFilter: {
          tags: "Etiquetas",
        },
        languageFilter: {
          languages: "Idiomas",
        },
        contentFilter: {
          generalContent: "Contenido general",
          privateContent: "Contenido privado",
        },
        ownedFilter: {
          bundles: "Paquetes",
          all: "Todos los paquetes",
          owned: "Solo paquetes propios",
          not_owned: "Solo paquetes no propios",
        },
      },
    },
    messages: {
      header: "Mensajes",
      table: {
        controls: {
          createMessage: "Crear mensaje",
        },
        columns: {
          select: "Seleccionar",
          subject: "Asunto",
          status: "Estado",
          recipients: "Destinatarios",
        },
        scheduledFor: "Programado para",
        processing: "Procesando",
        sentAt: "Enviado a las",
        more: "+{0} más",
      },
      details: {
        editMessage: "Editar mensaje",
        createMessage: "Crear mensaje",
        now: "Ahora",
        scheduleFor: "Programar para",
        notifications: {
          messageSent: "El mensaje se ha guardado y se enviará pronto.",
          messageScheduled: "El mensaje se ha guardado y se enviará a las: {0}",
        },
        messageSubject: "Asunto del mensaje",
        subject: "Asunto",
        messageBody: "Cuerpo del mensaje",
        body: "Cuerpo",
        recipientGroups: "Grupos de destinatarios",
        send: "Enviar",
        scheduledFor: "Programado para",
        language: "Idioma",
        languageDescription:
          "El idioma del mensaje se utiliza para determinar el formato de texto en la aplicación cliente. El idioma no afecta a los destinatarios seleccionados.",
        readonlyWarning:
          "Los mensajes que ya han sido enviados no se pueden cambiar.",
      },
      dialogs: {
        deleteMessage: {
          header: "Eliminar mensaje",
          content:
            "¿Estás seguro de que quieres eliminar el mensaje {0}? Tras la eliminación, el mensaje no será visible en el buzón de la aplicación.",
        },
        deleteMessages: {
          header: "Eliminar mensajes seleccionados",
          content:
            "¿Estás seguro de que quieres eliminar los mensajes seleccionados? Tras la eliminación, los mensajes no serán visibles en el buzón de la aplicación.",
        },
      },
      notifications: {
        messageDeleted: "El mensaje ha sido eliminado",
        messagesDeleted: "Los mensajes seleccionados han sido eliminados",
      },
    },
  },

  components: {
    header: {
      change: "cambio",
      tryBetaDashboard: "Prueba nuestro nuevo panel beta",
    },
    trainingStatus: {
      notReady: "Aún no está listo para invitación",
      ready: "Listo para invitación",
      invited: "Invitado",
      completed: "Completado",
      incomplete: "Incompleto",
    },
    select: {
      report: {
        reportType: "Tipo de informe",
        adoption: {
          name: "Adopción",
          description:
            "El informe de adopción muestra exactamente cuál es el estado de su población en términos de registrarlos y hacerlos jugar. Esto le ayudará a dirigirse a los diferentes grupos de usuarios y obtener una idea de lo que hace que su proceso de adopción funcione. Los grupos de este informe se combinan para crear una única lista de usuarios.",
        },
        group_ranking: {
          name: "Ranking de grupos",
          description:
            "El informe de grupo le ayuda a identificar a las personas que han aprendido más el mes pasado para cada grupo. Si hay alguna recompensa pendiente, de esta manera podrá descubrir quién ha estado haciendo lo mejor.",
        },
        question_analytics: {
          name: "Análisis de preguntas",
          description:
            "El informe de análisis de preguntas muestra todas las métricas para cada pregunta. Esto le ayudará a determinar qué tipos de preguntas funcionan bien y qué tipo de preguntas son más difíciles de aprender.",
        },
        impact: {
          name: "Impacto",
          description:
            "El informe de impacto de Knowingo destaca qué tipo de impacto ha tenido Knowingo en sus usuarios. Podrá ver el aumento en el conocimiento de su población. Estos aumentos se muestran a través del conocimiento inicial, el conocimiento actual y el conocimiento potencial.",
        },
        certificates: {
          name: "Certificados",
          description:
            "El informe de certificación muestra el estado de los usuarios con respecto a sus certificados. Podrá determinar cuál es el estado actual de su población en cuanto a su progreso.",
        },
        user_dump: {
          name: "Exportación de usuarios (detallada)",
          description:
            "El informe contiene diferentes datos sobre la certificación, el conocimiento y los grupos de los usuarios.",
        },
        user_dump2: {
          name: "Exportación de usuarios (general)",
          description:
            "El informe contiene una lista de todos los usuarios con métricas generales sobre el tiempo de juego, el conocimiento y el número de certificados obtenidos.",
        },
        group_certificates: {
          name: "Certificados de grupo",
          description:
            "El informe de certificación muestra el estado de los usuarios en relación con sus certificados. Podrá determinar cuál es el estado actual de su población en cuanto a su progreso.",
        },
        certificates_postnl: {
          name: "Certificados (PostNL)",
          description:
            "El informe de certificación muestra el estado de los usuarios con respecto a sus certificados. Podrás determinar cuál es el estado actual de tu población con su progreso.",
        },
        compliance: {
          name: "Cumplimiento",
          description:
            "El informe de cumplimiento muestra los datos recopilados de los usuarios que tienen temas de cumplimiento asignados. Esto le ayudará a evaluar los niveles de cumplimiento de su población.",
        },
      },
      group: {
        selectGroup: "Seleccionar grupo",
        loading: "Cargando",
        noResults: "No hay resultados de búsqueda para {0}",
      },
      tags: {
        selectTags: "Seleccionar etiquetas",
        successMessage: "La etiqueta {0} se ha creado",
        createOption: "Crear etiqueta",
      },
      company: {
        selectCompany: "Seleccionar compañía",
      },
      language: {
        selectLanguage: "Seleccionar idioma",
        en: "Inglés",
        nl: "Holandés",
        de: "Alemán",
        fr: "Francés",
        ru: "Ruso",
        ar: "Árabe",
        pt: "Portugués",
        es: "Español",
        cs: "Checo",
        he: "Hebreo",
        ur: "Urdu",
        th: "Tailandés",
        zh: "Chino",
        hi: "Hindi",
        it: "Italiano",
        pl: "Polaco",
      },
      topic: {
        selectTopic: "Seleccionar tema",
        selectTopics: "Seleccionar temas",
      },
      content: {
        placeholder: "Seleccionar contenido",
        course: "Curso",
        topic: "Tema",
        publishedQuestions: "preguntas publicadas",
      },
      compliance: {
        selectCompliance: "Seleccionar cumplimiento",
      },
      user: {
        notConfirmed: "No confirmado",
        selectUser: "Seleccionar usuario",
      },
      webhook: {
        placeholder: "Seleccionar evento",
      },
      timezone: {
        timezone: "Zona horaria",
      },
      leaders: {
        groupLeaders: "Gerente de grupo",
        selectGroupManager: "Seleccionar gerente de grupo",
      },
      role: {
        label: "Rol",
        placeholder: "Seleccionar rol",
        userRoles: "Roles de usuario",
        whatRoles: "¿Cuáles son los roles?",
        user: {
          header: "Usuario",
          description:
            "Acceso completo a la aplicación móvil y web (Knowingo App) y sin acceso al Dashboard.",
        },
        moderator: {
          header: "Moderador",
          description:
            "Acceso completo a la Knowingo App y al Dashboard. El moderador tiene los permisos y derechos de acceso combinados de los roles de Creador de Contenidos y Administrador de Usuarios, pudiendo acceder a todas las páginas del Dashboard y editar cualquier información o contenido en el Dashboard. Los moderadores pueden editar, crear o eliminar otros moderadores de la plataforma.",
        },
        user_manager: {
          header: "Administrador de usuarios",
          description:
            "Acceso completo a la Knowingo App y acceso parcial al Dashboard. Los administradores pueden ver estadísticas de aprendizaje y gestionar Usuarios, Grupos y sus Planes de Aprendizaje, pero no pueden crear ni editar contenido.",
        },
        group_manager: {
          header: "Administrador de grupos",
          description:
            "Acceso completo a la Knowingo App y acceso parcial al Dashboard. Los administradores de grupos pueden ver estadísticas de aprendizaje y gestionar Grupos asignados, Planes de Aprendizaje y sus Usuarios. Los administradores de grupos también pueden generar informes para sus grupos y tienen acceso de solo lectura a todo el contenido. Los grupos administrados se pueden asignar en la página de grupos después de que el usuario sea invitado.",
        },
        content_creator: {
          header: "Creador de contenido",
          description:
            "Acceso completo a la Knowingo App y acceso parcial al Dashboard. Los creadores de contenido pueden crear y editar contenido de Temas y tienen acceso a análisis a nivel de preguntas, pero no pueden realizar cambios en Usuarios y Planes de Aprendizaje.",
        },
      },
      authenticationMethod: {
        label: "Método de autenticación",
        options: {
          password: "Contraseña",
          "azure-saml": "Inicio de sesión único (Azure)",
          "keyclock-saml": "Inicio de sesión único (Keyclock)",
        },
        disclaimer:
          "Invitar a usuarios de inicio de sesión único es opcional. La cuenta de usuario se crea automáticamente en el primer inicio de sesión. La invitación se puede usar para asignar grupos de usuarios antes del primer inicio de sesión.",
      },
    },
    clipboard: {
      successMessage: "Copiado al portapapeles",
      errorMessage:
        "No se pudo copiar. Por favor, copie el texto seleccionado manualmente",
    },
    spanDropdown: {
      day: "Días",
      week: "Semanas",
      month: "Meses",
      quarter: "Trimestres",
      year: "Años",
    },
    comments: {
      deletedMessage: "El comentario ha sido eliminado",
      savedMessage: "El comentario ha sido guardado",
      delete: "Eliminar",
      yourComment: "Tu comentario",
      addComment: "Agregar comentario",
    },
    sidebar: {
      avatar: {
        alt: "Avatar de usuario",
        roles: {
          admin: "Administrador",
          moderator: "Moderador",
        },
      },
      items: {
        dashboard: "Panel de control",
        users: "Usuarios",
        groups: "Grupos",
        topics: "Temas",
        courses: "Cursos",
        certification: "Certificación",
        accountInfo: "Información de la cuenta",
        api: "API",
        companySettings: "Configuración",
        whiteLabeling: "Marca blanca",
        webHooks: "WebHooks",
        documentation: "Documentación",
        moderatorCompanies: "Empresas",
        contentSharing: "Compartir contenido",
        questionAnalytics: "Análisis de preguntas",
        feedback: "Comentarios",
        shareOverview: "Resumen de comparticiones",
        myProfile: "Mi perfil",
        signupURLs: "URL de registro",
        logout: "Cerrar sesión",
        newTag: "¡Nuevo!",
        proTag: "PRO",
        shareLink: "Enlace de compartición",
        library: "Biblioteca",
        disabledItemNotice:
          "El moderador de la empresa no puede ver este elemento porque la función está desactivada",
        contentLibrary: "Biblioteca de contenido",
        reporting: "Informes",
        activityLog: "Registro de actividad",
        enterpriseStats: "Estadísticas de la empresa",
        externalTraining: "Entrenamiento externo",
        marketplace: "Mercado",
        messages: "Mensajes",
      },
    },
    table: {
      controls: {
        filterDropdown: {
          filterBy: "Filtrar por",
          clearFilters: "Borrar filtros",
          filters: {
            sent: "Enviados",
            scheduled: "Programados",
          },
        },
        actionsDropdown: {
          label: "Acciones",
          delete: "Eliminar seleccionados",
        },
        searchBox: {
          search: "Buscar",
        },
      },
      actions: {
        delete: "Eliminar",
      },
      noSearchResults: "No hay resultados de búsqueda para: {0}",
      noData: "No hay datos",
      moreItems:
        "{0} más elementos. Haz clic aquí para abrir todos los elementos en una nueva pestaña.",
    },
    charts: {
      week: "Semana",
      quarter: "Trimestre",
    },
    submissionConfirmation: {
      successMessage: "¡Guardado!",
    },
    statusIcon: {
      published: "Publicado",
      unpublished: "No publicado",
      changed: "Cambiado",
      incomplete: "Borrador incompleto",
    },
    cropper: {
      errors: {
        unsupportedFileType: "Tipo de archivo no compatible",
      },
      buttons: {
        upload: "Subir",
        delete: "Eliminar",
        confirm: "Confirmar",
      },
    },
    form: {
      buttons: {
        save: "Guardar",
        loading: "Cargando",
        actionAndClose: "{0} y cerrar",
      },
      fields: {
        repeatSelect: {
          every: "Cada",
          days: "Días",
          weeks: "Semanas",
          months: "Meses",
          daily: "Diario",
          weekly: "Semanal",
          monthly: "Mensual",
          custom: "Personalizado",
        },
        weekdaySelect: {
          mon: "Lun",
          tue: "Mar",
          wed: "Mié",
          thu: "Jue",
          fri: "Vie",
          sat: "Sáb",
          sun: "Dom",
        },
      },
      validators: {
        required: "Campo requerido",
        invalidUrl: "URL no válida",
      },
    },
    richTextEditor: {
      errors: {
        textTooLong: "El texto ingresado es demasiado largo",
      },
      toolbox: {
        bold: "Negrita",
        italic: "Cursiva",
        underline: "Subrayado",
        strikethrough: "Tachado",
        superscript: "Superíndice",
        subscript: "Subíndice",
        link: "Enlace",
        fontSize: "Tamaño de fuente",
        fontColor: "Color de fuente",
        highlightColor: "Color de resaltado",
        clearFormatting: "Borrar formato",
      },
    },
    downloader: {
      title: "Descargar",
      generatingFile:
        "El archivo solicitado se está generando. Por favor, espere...",
      fileReady:
        "¡Tu archivo está listo! Haz clic en un botón a continuación para guardarlo en tu computadora.",
      loading: "Cargando",
      download: "Descargar",
      close: "Cerrar",
    },
    confirm: {
      confirm: "Confirmar",
      cancel: "Cancelar",
    },
    certification: {
      unknown: "Desconocido",
      certified: "Certificado",
      on_track: "En camino",
      expiring: "Expirando",
      expired: "Expirado",
      overdue: "Fecha límite perdida",
      uncertified: "No certificado",
    },
    mediaComponent: {
      type: "Tipo",
      text: "Texto",
      image: "Imagen",
      video: "Video",
      videoUrl: "URL del video",
      thumbnail: "Miniatura",
      videoPreview: "Vista previa del video",
      videoWarning: "No se pudo cargar el video",
    },
    topicInfo: {
      assignedGroups: "Grupos asignados",
      assignedCourses: "Cursos asignados",
      noAssignedGroups: "No hay grupos asignados",
      noAssignedCourses: "No hay cursos asignados",
      topicOwner: "Propietario del tema",
    },
    datePicker: {
      hour: "Hora",
      minutes: "Minutos",
      date: "Fecha",
    },
    input: {
      originalText: "Texto original",
      errorWhileTranslating:
        "Ocurrió un error al traducir el contenido. Por favor, inténtelo de nuevo más tarde.",
    },
    excel: {
      firstRowContainsHeaders: "La primera fila contiene encabezados",
      errors: {
        headerNotSet: "Encabezado no establecido",
        headerInUse: "Encabezado ya en uso",
        invalidHeader: "Encabezado no válido",
        missingHeader: "Falta el encabezado requerido: {0}",
        fieldExists: "El campo ya existe",
      },
    },
  },

  inputs: {
    startDate: {
      label: "Fecha de inicio",
    },
    endDate: {
      label: "Fecha de finalización",
    },
    frequency: {
      label: "Frecuencia",
    },
    weekday: {
      label: "Día de la semana",
    },
    includeDisabledUsers: {
      label: "Incluir usuarios desactivados",
    },
    includeMetaFields: {
      label: "Incluir campos meta",
    },
    ignoreMinAnswers: {
      label: "Incluir preguntas sin métricas",
      warning:
        "Algunas métricas relacionadas con el Análisis de preguntas pueden no ser confiables debido a una baja exposición a los jugadores.",
    },
    name: {
      label: "Nombre",
    },
    repeatSelect: {
      daily: "Diario",
      weekly: "Semanal",
      monthly: "Mensual",
    },
    enabled: {
      label: "Habilitado",
      description:
        "Puede desmarcar este campo para suspender el envío de correos electrónicos a los destinatarios seleccionados.",
    },
    monthSelect: {
      label: "Seleccionar mes",
    },
    filters: {
      name: "Nombre",
      created: "Creado",
      updated: "Actualizado",
      published: "Publicado",
      num_questions: "# Preguntas",
      num_topics: "# Temas",
      num_courses: "# Cursos",
      num_tips: "# Consejos",
      num_theories: "# Introducción",
    },
    noFiltersAvailable: "No hay filtros disponibles",
    url: {
      placeholder: "Ingrese URL",
    },
  },

  errors: {
    resourceUpdated:
      "El recurso ha sido actualizado mientras se realiza la acción. Actualiza la página e inténtalo de nuevo.",
    topicIsShared:
      "No se puede eliminar el tema compartido. Revoca todas las comparticiones existentes e inténtalo de nuevo.",
    tryAgain: "Inténtalo de nuevo",
  },

  errorBoundary: {
    updateAvailableHeader: "Actualización disponible",
    updateAvailableContent:
      "Hay una nueva versión del panel de control de Knowingo disponible. Haz clic en un botón a continuación para recargar la página.",
    reloadButton: "Recargar",
    somethingWentWrongHeader: "Vaya... Algo salió mal",
    somethingWentWrongContent:
      "Parece que tenemos algunos problemas internos. Nuestro equipo ha sido notificado.",
    pleaseReload:
      "Por favor, recarga la página e intenta nuevamente. Si el problema persiste, contacta con el soporte en {0}",
    eventIdentifier: "Identificador de evento",
  },

  form: {
    errors: {
      start_in_past: "La fecha de inicio no puede ser establecida en el pasado",
      end_before_start:
        "La fecha de finalización no puede ser establecida antes de la fecha de inicio",
      "Missing data for required field.":
        "Falta información en el campo requerido",
      "Length must be between 1 and 35.": "La longitud debe estar entre 1 y 35",
      "Length must be between 3 and 120.":
        "La longitud debe estar entre 3 y 120",
      unknownError: "Error desconocido",
      "Not a valid URL.": "URL no válida",
      "Invalid domain": "Dominio de correo electrónico no válido.",
    },
  },

  loginPage: {
    htmlTitle: "Knowingo | Iniciar sesión",
    header: "Inicia sesión en Knowingo{0}",
    emailAddress: "Correo electrónico",
    password: "Contraseña",
    rememberMe: "Recuérdame",
    loginBtn: "Iniciar sesión",
    loginWithSSOBtn: "Iniciar sesión mediante SSO",
    forgotPassword: "¿Olvidó su contraseña?",
    goBack: "Volver",
    here: "aquí",
    inputs: {
      domain: {
        placeholder: "Dominio",
      },
    },
    errors: {
      invalidCredentials:
        "La dirección de correo electrónico o la contraseña proporcionada no son correctas.",
      disabledAccount:
        "Tu cuenta está desactivada. Ponte en contacto con support@knowingo.com para obtener más información.",
      deletedAccount:
        "Tu cuenta ha sido eliminada. Ponte en contacto con support@knowingo.com para obtener más información.",
      tooManyAttempts:
        "Demasiados intentos de inicio de sesión fallidos. Vuelve a intentarlo en 5 minutos.",
      permissionError:
        "El inicio de sesión solo está permitido para moderadores de la empresa. Descarga la aplicación Knowingo+ y juega en tu dispositivo móvil. Ponte en contacto con support@knowingo.com para obtener más información.",
      cookiesError:
        "Parece que tu navegador bloquea las cookies de terceros. Habilita las cookies en la configuración de seguridad de tu navegador para este sitio.",
      invalidEmail: "Dirección de correo electrónico no válida",
      invalidPassword: "Contraseña no válida",
      notConfirmed:
        "Tu cuenta no está confirmada. Por favor, verifica tu dirección de correo electrónico y haz clic en el enlace de activación",
      ssoAccount:
        "Tu cuenta está conectada a otro proveedor de inicio de sesión. Utiliza SSO como método de inicio de sesión.",
      companyAccessDenied: "No tienes acceso a esta empresa.",
      emailNotConfirmed:
        "Verifica tu correo electrónico y confirma la dirección antes de iniciar sesión. Haz clic aquí para reenviar el correo electrónico con el enlace de confirmación.",
      noSeats: "La empresa no tiene plazas disponibles",
      alreadyLoggedIn:
        "Ya has iniciado sesión. Haz clic aquí para abrir el panel de control.",
      unknownError: "Error desconocido",
    },
    confirmationRequestSent:
      "Se ha enviado un correo electrónico con el enlace de confirmación",
    successLogin: "¡Has iniciado sesión!",
  },
  notFound: {
    header: "No encontrado",
    textCompany:
      "Parece que el dominio de la empresa ingresado no existe en Knowingo. Por favor, verifica la URL ingresada.",
  },
  ssoLoginPage: {
    header: "Seleccionar proveedor SSO",
    errors: {
      ssoNotSupported:
        "El inicio de sesión único (SSO) no es compatible con su organización. Por favor, póngase en contacto con su administrador para obtener más detalles.",
    },
  },
  domainPage: {
    errors: {
      companyNotFound:
        "Empresa no encontrada. Por favor verifica el nombre de dominio ingresado.",
    },
  },
  forgotPassword: {
    htmlTitle: "Knowingo | Olvidé la contraseña",
    formHeader: "Restablecer contraseña",
    formEmail: "Correo electrónico",
    formSubmit: "Restablecer contraseña",
    backButton: "Volver al inicio de sesión",
    success: "Por favor, revise su correo electrónico",
    errors: {
      invalidEmail: "Dirección de correo electrónico no válida",
      alreadyLoggedIn: "Ya ha iniciado sesión",
      userNotFound: "Usuario no encontrado",
      unknownError: "Error desconocido",
    },
    inputs: {
      email: {
        placeholder: "Dirección de correo electrónico",
      },
    },
  },
  dashboard: {
    htmlTitle: "Knowingo | Panel de control",
    toggleNavigation: "Alternar navegación",
    welcome: "Hola {0}",
  },
  pageNotFound: "Page no encontrada",
  overview: {
    title: "Panel de control",
  },
  activityLog: {
    columns: {
      activity: "Actividad",
      created: "Creado",
      user: "Usuario",
    },
  },
};

module.exports = { es };
