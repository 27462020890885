import { INFO } from "constants/info.constants";

const initialState = {
  name: "",
  background: null,
  background_light: null,
  loading: false,
  found: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INFO.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case INFO.SUCCESS:
      return {
        ...state,
        loading: false,
        found: true,
        ...action.data,
      };
    case INFO.FAILURE:
      return {
        ...state,
        found: false,
        loading: false,
      };
    default:
      return state;
  }
};
