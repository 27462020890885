import { UNAUTHORIZED } from "constants/auth.constants";
import * as constants from "constants/invitation.constants";

const initialState = {
  loading: false,
  submitted: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.INVITATION_CREATE.REQUEST:
      return {
        ...state,
        loading: true,
        submitted: false,
        error: null,
      };
    case constants.INVITATION_CREATE.SUCCESS: {
      const data = action.data;

      return {
        ...state,
        ...data,
        loading: false,
        submitted: true,
      };
    }
    case constants.INVITATION_CREATE.FAILURE: {
      const error = action.data;

      return {
        ...state,
        error,
        loading: false,
        submitted: true,
      };
    }
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};
