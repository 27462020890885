import { paginationActions, requestActions } from "../utils";

export const LIST = requestActions("ExternalTraining/List");
export const LIST_UPDATE = requestActions("ExternalTraining/List/Update");
export const ITEM = requestActions("ExternalTraining/Item");
export const ITEM_RESET = "ExternalTraining/Item/Reset";
export const PAGINATION = paginationActions("ExternalTraining/Paginate");
export const SELECT = "ExternalTraining/Select";
export const SELECT_ALL = "ExternalTraining/Select/All";
export const SHOW_COLUMNS = "ExternalTraining/ShowColumns";
export const SHOW_PANEL = "ExternalTraining/Panel/Show";
export const HIDE_PANEL = "ExternalTraining/Panel/Hide";
