import * as constants from "constants/downloader.constants";

const initialState = {
  loading: false,
  error: false,
  url: null,
  isOpen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.DOWNLOAD.REQUEST:
      return {
        ...state,
        url: null,
        loading: true,
        error: false,
        isOpen: true,
      };
    case constants.DOWNLOAD.SUCCESS: {
      const data = action.data;

      return {
        ...state,
        url: data.result,
        loading: false,
      };
    }
    case constants.DOWNLOAD.FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case constants.OPEN: {
      return {
        ...state,
        isOpen: true,
        isDetached: false,
      };
    }
    case constants.CLOSE: {
      return {
        ...state,
        isOpen: false,
        isDetached: false,
      };
    }
    case constants.RESET: {
      return initialState;
    }
    default:
      return state;
  }
};
