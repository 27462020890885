import { requestActions } from "./utils";

export const COMPANY_INFO = requestActions("Company/Info");
export const COMPANY_INFO_TODAY = requestActions("Company/Info/Today");
export const COMPANY_INFO_ALLTIME = requestActions("Company/Info/Alltime");

export const KNOWLEDGE = requestActions("Overview/Knowledge");
export const TRAINING_TIME = requestActions("Overview/TrainingTime");
export const QUESTIONS_MASTERED = requestActions("Overview/QuestionsMastered");
export const LEARNING_PROGRESS = requestActions("Overview/LearningProgress");
