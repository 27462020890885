import * as constants from "constants/dashboard.constants";
import { combineReducers } from "redux";

import { getItemsById } from "./utils";

const initialState = {
  name: "",
  id: null,
  is_default: false,
  editable: false,
  loading: false,
  error: false,
  readonly: false,
  widgets: [],
};

const currentDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_NEW_DASHBOARD: {
      return {
        ...state,
        id: null,
        is_default: false,
        readonly: false,
        name: "",
        widgets: [],
      };
    }
    case constants.SAVE_DASHBOARD.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case constants.SAVE_DASHBOARD.SUCCESS: {
      const { data } = action;
      return {
        ...state,
        ...data,
      };
    }
    case constants.SAVE_DASHBOARD.FAILURE: {
      return {
        ...state,
        found: false,
        loading: false,
        error: true,
      };
    }
    case constants.COPY_DASHBOARD: {
      return {
        ...state,
        id: null,
      };
    }
    case constants.SELECT_DASHBOARD: {
      const dashboard = action.data;

      return {
        ...state,
        ...dashboard,
      };
    }
    case constants.TOGGLE_EDITABLE: {
      const newState =
        typeof action.data !== "undefined" ? action.data : !state.editable;

      return {
        ...state,
        editable: newState,
      };
    }
    case constants.ADD_WIDGET: {
      let widget = action.data;
      const widgets = state.widgets;

      const lastWidget = widgets.reduce(
        (it, acc) => {
          return it.y + it.h < acc.y + acc.h ? acc : it;
        },
        { x: 0, y: 0, w: 0, h: 0 },
      );

      const totalHeight = lastWidget.y + lastWidget.h;

      const arr = Array(totalHeight)
        .fill(0)
        .map(() => Array(12).fill(0));
      widgets.forEach((it) => {
        for (let iy = it.y; iy < it.y + it.h; iy++) {
          for (let ix = it.x; ix < it.x + it.w; ix++) {
            arr[iy][ix] = 1;
          }
        }
      });

      const widgetHeight = widget.h;
      const widgetWidth = widget.w;

      let coordinates = null;

      for (let row = 0; row < arr.length; row++) {
        for (let col = 0; col < arr[row].length; col++) {
          const occupied = arr[row][col];
          if (!occupied) {
            if (12 - col <= widgetWidth) {
              let tmp = [];
              for (
                let t = row;
                t < Math.min(row + widgetHeight, totalHeight);
                t++
              ) {
                tmp = [...tmp, ...arr[t].slice(col, widgetWidth)];
              }

              if (!tmp.length) {
                coordinates = {
                  x: col,
                  y: row,
                };
              }
            }
          }

          if (coordinates) break;
        }
        if (coordinates) break;
      }

      if (!arr.length || !coordinates) {
        coordinates = {
          x: 0,
          y: 0,
        };
      }

      widget = {
        ...widget,
        ...coordinates,
      };

      return {
        ...state,
        widgets: [...widgets, widget],
      };
    }
    case constants.REMOVE_WIDGET: {
      const { data: index } = action;

      const widgets = state.widgets.filter((it) => it.i !== index);
      return {
        ...state,
        widgets,
      };
    }
    case constants.UPDATE_DASHBOARD: {
      const { data } = action;

      return {
        ...state,
        ...data,
      };
    }
    default:
      return state;
  }
};

const initialListState = {
  loading: false,
  error: false,
  found: false,
  items: [],
  itemsById: {},
  count: 0,
};

const dashboardListReducer = (state = initialListState, action) => {
  switch (action.type) {
    case constants.GET_DASHBOARDS.REQUEST: {
      return {
        ...state,
        loading: true,
        found: false,
      };
    }
    case constants.GET_DASHBOARDS.SUCCESS: {
      const { results: items, count } = action.data;
      const data = items.map((it) => ({
        ...it,
        readonly: false,
      }));

      return {
        ...state,
        count,
        items: data,
        itemsById: getItemsById(data),
        loading: false,
        found: true,
      };
    }
    case constants.GET_DASHBOARDS.FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        found: false,
      };
    }
    default:
      return state;
  }
};

const initialConfigState = {
  foldSidebar: false,
  showMobileSidebar: false,
  showNotification: false,
  showCompanySelectionDialog: false,

  showSelectWidgetsDialog: false,
  showSettingsDialog: false,
  language: "en",
};

const dashboardConfigState = (state = initialConfigState, action) => {
  switch (action.type) {
    case constants.TOGGLE_NOTIFICATION: {
      const newState = action.data || !state.showNotification;

      return {
        ...state,
        showNotification: newState,
      };
    }
    case constants.TOGGLE_SIDEBAR:
      return {
        ...state,
        foldSidebar: !state.foldSidebar,
      };
    case constants.TOGGLE_MOBILE_SIDEBAR: {
      const newState =
        typeof action.data !== "undefined"
          ? action.data
          : !state.showMobileSidebar;

      return {
        ...state,
        showMobileSidebar: newState,
      };
    }
    case constants.TOGGLE_COMPANY_SELECTION_DIALOG: {
      const newState =
        typeof action.data !== "undefined"
          ? action.data
          : !state.showCompanySelectionDialog;

      return {
        ...state,
        showCompanySelectionDialog: newState,
      };
    }
    case constants.TOGGLE_SELECT_WIDGETS_DIALOG: {
      const newState =
        typeof action.data !== "undefined"
          ? action.data
          : !state.showSelectWidgetsDialog;

      return {
        ...state,
        showSelectWidgetsDialog: newState,
      };
    }
    case constants.TOGGLE_SETTINGS_DIALOG: {
      const newState =
        typeof action.data !== "undefined"
          ? action.data
          : !state.showSettingsDialog;

      return {
        ...state,
        showSettingsDialog: newState,
      };
    }
    case constants.CHANGE_LANGUAGE: {
      const language = action.data;

      return {
        ...state,
        language,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  current: currentDashboardReducer,
  list: dashboardListReducer,
  config: dashboardConfigState,
});
