import moment from "moment";
import LocalizedStrings from "react-localization";

import { en } from "./en";
import { es } from "./es";
import { nl } from "./nl";
import { ur } from "./ur";

const strings = new LocalizedStrings({
  en,
  nl,
  ur,
  es,
});

strings.setLanguage("en");

const isRTL = (language) => {
  const rtlLanguageCodes = ["ur"];
  return rtlLanguageCodes.includes(language);
};

const setMomentLocale = (language) => {
  let locale;
  switch (language.toLowerCase()) {
    case "en":
      locale = "en-gb";
      break;
    case "nl":
      locale = "nl";
      break;
    case "ur":
      locale = "ur";
      break;
    default:
      locale = "en-gb";
  }
  import(`moment/locale/${locale}`);
  moment.locale(locale);
};

const toggleRtlLayout = (state) => {
  const htmlElement = document.getElementsByTagName("html")[0];
  if (state) {
    htmlElement.setAttribute("dir", "rtl");
  } else {
    htmlElement.removeAttribute("dir");
  }
};

export const setLanguage = (language) => {
  strings.setLanguage(language);
  setMomentLocale(language);
  toggleRtlLayout(isRTL(language));
};

export const pluralize = (msgSingular, msgPlural, val) => {
  if (val === 1) return msgSingular;
  return msgPlural;
};

export default strings;
