import { paginationActions, requestActions } from "./utils";

export const FEEDBACK = requestActions("Feedback/List");
export const FEEDBACK_DELETE = requestActions("Feedback/List/Delete");
export const PAGINATION = paginationActions("Feedback/Paginate");
export const SELECT = "Feedback/Select";
export const SELECT_ALL = "Feedback/Select/All";
export const SHOW_COLUMNS = "Feedback/ShowColumns";
export const FEEDBACK_ITEM = requestActions("Feedback/Item/Get");
export const FEEDBACK_ITEM_DELETE = requestActions("Feedback/Item/Delete");
export const FEEDBACK_ITEM_UPDATE = requestActions("Feedback/Item/Update");
export const FEEDBACK_RESPONSES = requestActions(
  "Feedback/Item/Responses/List",
);
export const FEEDBACK_RESPONSES_CREATE = requestActions(
  "Feedback/Item/Responses/Create",
);
export const FEEDBACK_RESPONSES_UPDATE = requestActions(
  "Feedback/Item/Responses/Update",
);
export const FEEDBACK_RESPONSES_DELETE = requestActions(
  "Feedback/Item/Responses/Delete",
);
export const FEEDBACK_RESPONSES_PAGINATION = paginationActions(
  "Feedback/Item/Responses/Pagination",
);
