import fetch from "lib/fetch";
import moment from "moment";

import { baseUrl, jsonOrReject } from "./utils";

export const getCompanyInfo = () => {
  return fetch(`${baseUrl}/stats/company/info`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const getCompanyInfoDay = (day) => {
  return fetch(`${baseUrl}/stats/company/?at=${day}&fmt=day`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const getCompanyInfoToday = () => {
  const today = moment.utc().format("YYYY-MM-DD");
  return getCompanyInfoDay(today);
};

export const getCompanyInfoMonth = (month) => {
  return fetch(`${baseUrl}/stats/company/day/${month}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

// export const getCompanyInfoThisMonth = () => {
//   const thisMonth = moment.utc().format('YYYY-MM');
//   return getCompanyInfoMonth(companyId, thisMonth);
// };

export const getCompanyInfoAlltime = () => {
  return fetch(`${baseUrl}/stats/company?fmt=alltime`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const getCompanyInfoDaily = () => {
  const startDate = moment().utc().subtract(6, "days").format("YYYY-MM-D");
  const endDate = moment().utc().format("YYYY-MM-D");

  return fetch(
    `${baseUrl}/stats/company/?from=${startDate}&to=${endDate}&fmt=day`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    },
  ).then(jsonOrReject);
};

export const getCompanyInfoWeekly = () => {
  const startDate = moment().utc().subtract(3, "weeks").format("YYYY-WW");
  const endDate = moment().utc().format("YYYY-WW");

  return fetch(
    `${baseUrl}/stats/company/?from=${startDate}&to=${endDate}&fmt=week`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    },
  ).then(jsonOrReject);
};

export const getCompanyInfoMonthly = () => {
  const startDate = moment().utc().subtract(5, "months").format("YYYY-MM");
  const endDate = moment().utc().format("YYYY-MM");

  return fetch(
    `${baseUrl}/stats/company/?from=${startDate}&to=${endDate}&fmt=month`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    },
  ).then(jsonOrReject);
};

export const getCompanyInfoQuarterly = () => {
  const startDate = moment().utc().subtract(3, "quarters").format("YYYY-QQ");
  const endDate = moment().utc().format("YYYY-QQ");

  return fetch(
    `${baseUrl}/stats/company/?from=${startDate}&to=${endDate}&fmt=quarter`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    },
  ).then(jsonOrReject);
};

export const getCompanyInfoYearly = () => {
  const startDate = moment().utc().subtract(5, "years").format("YYYY");
  const endDate = moment().utc().format("YYYY");

  return fetch(
    `${baseUrl}/stats/company/?from=${startDate}&to=${endDate}&fmt=year`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    },
  ).then(jsonOrReject);
};
