import { paginationActions, requestActions } from "./utils";

export const SIGNUP_URLS = requestActions("SignupURLs/List");
export const SIGNUP_URLS_BULK_UPDATE = requestActions("SignupURLs/List/Update");
export const PAGINATION = paginationActions("Tags/Paginate");
export const SELECT = "SignupURLs/List/Select";
export const SELECT_ALL = "SignupURLs/List/SelectAll";
export const SHOW_COLUMNS = "SignupURLs/List/ShowColumns";
export const SIGNUP_URL_CREATE = requestActions("SignupURL/Create");
export const SIGNUP_URL = requestActions("SignupURL/Get");
export const SIGNUP_URL_UPDATE = requestActions("SignupURL/Update");
export const SIGNUP_URL_DELETE = requestActions("SignupURL/Delete");
export const SIGNUP_URL_RESET = "SignupURL/Reset";
export const SIGNUP_URL_CONFIG = requestActions("SignupURL/Config");
