import { paginationActions, requestActions } from "../utils";

export const GROUP_TRAININGS = requestActions("GroupTrainings/List/Get");
export const GROUP_TRAININGS_INDIRECT = requestActions(
  "GroupTrainings/Indirect/List/Get",
);
export const GROUP_TRAININGS_INDIRECT_RESET =
  "GroupTrainings/Indirect/List/Reset";
export const GROUP_TRAININGS_RESET = "GroupTrainings/List/Reset";
export const PAGINATION = paginationActions("GroupTrainings/Paginate");
