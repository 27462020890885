import { UNAUTHORIZED } from "constants/auth.constants";
import {toast} from "react-toastify";
import strings from "../l10n";

export const createAction = (type, data) => ({ type, data });

export const handleErrors = (props) => (dispatch) => {
  const { name, ...rest } = props;

  if (name === "AuthError") {
    dispatch(createAction(UNAUTHORIZED, rest));
  }

  if (name==="CsrfError") {
    toast.error(strings.messages.csrfError);
  }
};

export const TaskStatuses = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  RECEIVED: "RECEIVED",
  RETRY: "RETRY",
  REVOKED: "REVOKED",
  STARTED: "STARTED",
};

export const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

export const statusInProgress = (status) => {
  return [
    TaskStatuses.PENDING,
    TaskStatuses.RECEIVED,
    TaskStatuses.RETRY,
    TaskStatuses.STARTED,
  ].includes(status);
};

export const getSelectedItems = (list, fieldName="selected") => list.filter(it => it[fieldName]);