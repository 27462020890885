import * as constants from "constants/questions.constants";
import * as api from "services/api/questions";

import { createAction, handleErrors } from "./utils";

export const getQuestions =
  (topicId, rethrowOnError = false) =>
  (dispatch, getState) => {
    const {
      questions: { pagination },
    } = getState();
    dispatch(createAction(constants.QUESTION_LIST_GET.REQUEST));

    return api
      .getQuestions(topicId, pagination)
      .then((questions) => {
        return dispatch(
          createAction(constants.QUESTION_LIST_GET.SUCCESS, questions),
        );
      })
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.QUESTION_LIST_GET.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const getCurrentTopicQuestions = () => (dispatch, getState) => {
  const {
    topics: {
      current: {
        topic: { id: topicId },
      },
    },
  } = getState();

  return dispatch(getQuestions(topicId));
};

export const getFullQuestion = (questionId) => async (dispatch, getState) => {
  // Fetch question plus all translations if it's meta question

  const {
    questions: {
      current: {
        translations: {
          list: { items: translations },
        },
      },
    },
  } = getState();

  const translation = translations.find((it) => it.id === questionId);

  if (translation) {
    dispatch(createAction(constants.QUESTION.REQUEST));
    dispatch(createAction(constants.QUESTION.SUCCESS, translation));
    return;
  }

  dispatch(resetCurrentQuestion());

  const fetchQuestion = async (questionId) => {
    try {
      return await api.getQuestion(questionId);
    } catch (e) {
      dispatch(handleErrors(e));
    }
  };

  const fetchTranslations = async (questionId) => {
    try {
      dispatch(createAction(constants.TRANSLATION_LIST.REQUEST));
      const translations = await api.getQuestionTranslations(questionId);
      dispatch(createAction(constants.TRANSLATION_LIST.SUCCESS, translations));

      return translations;
    } catch (e) {
      dispatch(handleErrors(e));
      dispatch(createAction(constants.TRANSLATION_LIST.FAILURE));
    }
  };

  const fetchMetaQuestion = async (questionId) => {
    try {
      dispatch(createAction(constants.META_QUESTION.REQUEST));
      const metaQuestion = await api.getQuestion(questionId);
      dispatch(createAction(constants.META_QUESTION.SUCCESS, metaQuestion));

      return metaQuestion;
    } catch (e) {
      dispatch(handleErrors(e));
      dispatch(createAction(constants.META_QUESTION.FAILURE));
    }
  };

  dispatch(createAction(constants.QUESTION.REQUEST));

  const question = await fetchQuestion(questionId);
  if (!question) {
    dispatch(createAction(constants.QUESTION.FAILURE));
    return;
  }

  const { meta, is_meta: isMeta } = question;

  if (isMeta) {
    // Set current question as meta question to avoid fetching same question twice
    dispatch(createAction(constants.META_QUESTION.REQUEST));
    dispatch(createAction(constants.META_QUESTION.SUCCESS, question));
    await fetchTranslations(questionId);
  }

  if (meta) {
    await fetchMetaQuestion(meta);
    await fetchTranslations(meta);
  }

  dispatch(createAction(constants.QUESTION.SUCCESS, question));
};

export const createNewTranslations = () => (dispatch, getState) => {
  dispatch(createAction(constants.TRANSLATION_LIST.REQUEST));

  const {
    topics: {
      current: {
        meta,
        localizations: {
          list: { items: localizations },
        },
      },
    },
  } = getState();

  const { translations, base_translation } = meta;

  const emptyTranslations = Object.keys(translations).map((language) => ({
    language,
    title_html: "",
    answers: [
      { id: 0, text_html: "" },
      { id: 1, text_html: "" },
      { id: 2, text_html: "" },
      { id: 3, text_html: "" },
    ],
    tags: localizations.find((it) => it.language === language)?.tags || [],
    is_base: base_translation === language,
    image: null,
    use_base_image: false,
    use_base_tip_image: false,
    tip: {
      enable_background_information: false,
      title_html: "",
      text_html: "",
      type: "image",
    },
  }));

  return dispatch(
    createAction(constants.TRANSLATION_LIST.SUCCESS, emptyTranslations),
  );
};

export const updateQuestion =
  (questionId, data, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.QUESTION_UPDATE.REQUEST));

    return api
      .updateQuestion(questionId, data)
      .then((question) =>
        dispatch(createAction(constants.QUESTION_UPDATE.SUCCESS, question)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.QUESTION_UPDATE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const patchQuestion =
  (questionId, data, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.QUESTION_LIST_UPDATE_ITEM.REQUEST));
    return api
      .patchQuestion(questionId, data)
      .then((question) =>
        dispatch(
          createAction(constants.QUESTION_LIST_UPDATE_ITEM.SUCCESS, question),
        ),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.QUESTION_LIST_UPDATE_ITEM.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const updateTranslation =
  (questionId, data, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.TRANSLATION_UPDATE.REQUEST));
    return api
      .updateQuestion(questionId, data)
      .then((question) =>
        dispatch(createAction(constants.TRANSLATION_UPDATE.SUCCESS, question)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.TRANSLATION_UPDATE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const patchTranslation =
  (questionId, data, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.TRANSLATION_UPDATE.REQUEST));
    return api
      .patchQuestion(questionId, data)
      .then((question) =>
        dispatch(createAction(constants.TRANSLATION_UPDATE.SUCCESS, question)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.TRANSLATION_UPDATE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const createQuestion =
  (topicId, data, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.QUESTION_CREATE.REQUEST));
    return api
      .createQuestion(topicId, data)
      .then((question) => {
        dispatch(createAction(constants.QUESTION_CREATE.SUCCESS, question));
        return question;
      })
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.QUESTION_CREATE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const createMultiLanguageQuestion =
  (topicId, data, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.META_QUESTION_CREATE.REQUEST));
    return api
      .createMultiLanguageQuestion(topicId, data)
      .then((question) => {
        dispatch(
          createAction(constants.META_QUESTION_CREATE.SUCCESS, question),
        );
        return question;
      })
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.META_QUESTION_CREATE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const remove =
  (questionId, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.QUESTION_DELETE.REQUEST));
    return api
      .deleteQuestion(questionId)
      .then((question) =>
        dispatch(createAction(constants.QUESTION_DELETE.SUCCESS, question)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.QUESTION_DELETE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const publish =
  (questionId, data, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.QUESTION_UPDATE.REQUEST));
    return api
      .publishQuestion(questionId, data)
      .then((res) => {
        dispatch(createAction(constants.QUESTION_UPDATE.SUCCESS, res));
        return res;
      })
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.QUESTION_UPDATE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const publishMeta =
  (questionId, data, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.QUESTION_UPDATE.REQUEST));
    return api
      .publishMetaQuestion(questionId, data)
      .then((res) => {
        dispatch(createAction(constants.QUESTION_UPDATE.SUCCESS, res));
        return res;
      })
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.QUESTION_UPDATE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const unpublish =
  (questionId, data, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.QUESTION_UPDATE.REQUEST));
    return api
      .unpublishQuestion(questionId, data)
      .then((res) =>
        dispatch(createAction(constants.QUESTION_UPDATE.SUCCESS, res)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.QUESTION_UPDATE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const unpublishMeta =
  (questionId, data, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.QUESTION_UPDATE.REQUEST));
    return api
      .unpublishMetaQuestion(questionId, data)
      .then((res) =>
        dispatch(createAction(constants.QUESTION_UPDATE.SUCCESS, res)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.QUESTION_UPDATE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const unpublishSelected =
  (data, rethrowOnError = false) =>
  (dispatch, getState) => {
    const {
      questions: {
        list: { items: questions },
      },
      topics: {
        current: { topic: currentTopic },
      },
    } = getState();
    const selectedQuestionIds = questions
      .filter((item) => item.selected)
      .map((it) => it.id);
    dispatch(createAction(constants.QUESTION_LIST_UPDATE.REQUEST));

    return api
      .bulkUnpublishQuestions(selectedQuestionIds, data)
      .then((res) => {
        dispatch(createAction(constants.QUESTION_LIST_UPDATE.SUCCESS));
        return res;
      })
      .then(() => dispatch(getQuestions(currentTopic.id)))
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.QUESTION_LIST_UPDATE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const publishSelected =
  (data, rethrowOnError = false) =>
  (dispatch, getState) => {
    const {
      questions: {
        list: { items: questions },
      },
    } = getState();
    const selectedQuestionIds = questions
      .filter((item) => item.selected)
      .map((it) => it.id);
    dispatch(createAction(constants.QUESTION_LIST_UPDATE.REQUEST));

    return api
      .bulkPublishQuestions(selectedQuestionIds, data)
      .then((res) => {
        dispatch(createAction(constants.QUESTION_LIST_UPDATE.SUCCESS));
        return res;
      })
      .catch(error => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.QUESTION_LIST_UPDATE.FAILURE));
        if (rethrowOnError) throw error;
      })
  };

export const removeSelected =
  (data, rethrowOnError = false) =>
  (dispatch, getState) => {
    const {
      questions: {
        list: { items: questions },
      },
      topics: {
        current: { topic: currentTopic },
      },
    } = getState();
    const selectedQuestionIds = questions
      .filter((item) => item.selected === true)
      .map((it) => it.id);
    dispatch(createAction(constants.QUESTION_LIST_UPDATE.REQUEST));

    return api
      .bulkDeleteQuestions(selectedQuestionIds, data)
      .then(() =>
        dispatch(createAction(constants.QUESTION_LIST_UPDATE.SUCCESS)),
      )
      .then(() => dispatch(getQuestions(currentTopic.id)))
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.QUESTION_LIST_UPDATE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const questionsSelect = (question) => (dispatch) => {
  return dispatch(createAction(constants.SELECT, question));
};

export const questionsSelectAll = () => (dispatch) => {
  return dispatch(createAction(constants.SELECT_ALL));
};

export const questionsOrderBy = (orderBy) => (dispatch, getState) => {
  const {
    topics: {
      current: { topic: currentTopic },
    },
  } = getState();

  dispatch(createAction(constants.PAGINATION.ORDER, orderBy));
  return dispatch(getQuestions(currentTopic.id));
};

export const questionsPageSize = (size) => (dispatch, getState) => {
  const {
    topics: {
      current: { topic: currentTopic },
    },
  } = getState();

  dispatch(createAction(constants.PAGINATION.PAGE_SIZE, size));
  return dispatch(getQuestions(currentTopic.id));
};

export const questionsFilter = (filters) => (dispatch, getState) => {
  const {
    topics: {
      current: { topic: currentTopic },
    },
  } = getState();

  dispatch(createAction(constants.PAGINATION.FILTERS, filters));
  return dispatch(getQuestions(currentTopic.id));
};

export const questionsFilterTags = (tags) => (dispatch, getState) => {
  const {
    topics: {
      current: { topic: currentTopic },
    },
  } = getState();

  dispatch(createAction(constants.PAGINATION.FILTER_TAGS, tags));
  return dispatch(getQuestions(currentTopic.id));
};

export const questionsPage = (page) => (dispatch, getState) => {
  const {
    topics: {
      current: { topic: currentTopic },
    },
  } = getState();

  dispatch(createAction(constants.PAGINATION.PAGE, page));
  return dispatch(getQuestions(currentTopic.id));
};

export const questionsSearch = (search) => (dispatch, getState) => {
  const {
    topics: {
      current: { topic: currentTopic },
    },
  } = getState();

  dispatch(createAction(constants.PAGINATION.SEARCH, search));
  return dispatch(getQuestions(currentTopic.id));
};

export const questionsShowColumns = (name) => (dispatch, getState) => {
  const {
    topics: {
      current: { topic: currentTopic },
    },
  } = getState();

  dispatch(createAction(constants.SHOW_COLUMNS, name));
  return dispatch(getQuestions(currentTopic.id));
};

export const resetCurrentQuestion = () => (dispatch, getState) => {
  const {
    topics: {
      current: {
        topic: { tags },
      },
    },
  } = getState();

  const defaultTags = tags || [];

  dispatch(createAction(constants.QUESTION_RESET, { tags: defaultTags }));
  dispatch(createAction(constants.META_QUESTION_RESET));
  dispatch(createAction(constants.QUESTION_COMMENTS_RESET));
  return dispatch(createAction(constants.TRANSLATION_LIST_RESET));
};

export const copySelectedQuestions =
  (destinationTopic) => (dispatch, getState) => {
    const {
      questions: {
        list: { items },
      },
    } = getState();

    const selectedQuestionIds = items
      .filter((it) => it.selected)
      .map((it) => it.id);
    return api.bulkCopyQuestions(selectedQuestionIds, destinationTopic);
  };

export const moveSelectedQuestions =
  (destinationTopic) => (dispatch, getState) => {
    const {
      questions: {
        list: { items },
      },
    } = getState();

    const selectedQuestionIds = items
      .filter((it) => it.selected)
      .map((it) => it.id);
    return api.bulkMoveQuestions(selectedQuestionIds, destinationTopic);
  };

export const copySelectedMultiLanguageQuestions =
  (destinationTopic) => (dispatch, getState) => {
    const {
      questions: {
        list: { items },
      },
    } = getState();

    const selectedQuestionIds = items
      .filter((it) => it.selected)
      .map((it) => it.meta);
    return api.bulkCopyMultiLanguageQuestions(
      selectedQuestionIds,
      destinationTopic,
    );
  };

export const moveSelectedMultiLanguageQuestions =
  (destinationTopic) => (dispatch, getState) => {
    const {
      questions: {
        list: { items },
      },
    } = getState();

    const selectedQuestionIds = items
      .filter((it) => it.selected)
      .map((it) => it.meta);
    return api.bulkMoveMultiLanguageQuestions(
      selectedQuestionIds,
      destinationTopic,
    );
  };

export const autoTranslateQuestions =
  (languages, questions) => () => {
    const questionIds = questions.map((it) => it.id);
    return api.bulkTranslateQuestions(questionIds, languages);
  };

export const showQuestionPanel = () => (dispatch) => {
  return dispatch(createAction(constants.QUESTIONS_SHOW_PANEL));
};

export const showCopyModal = () => (dispatch) => {
  return dispatch(createAction(constants.COPY_MODAL.SHOW));
};

export const hideCopyModal = () => (dispatch) => {
  return dispatch(createAction(constants.COPY_MODAL.HIDE));
};

export const showMoveModal = () => (dispatch) => {
  return dispatch(createAction(constants.MOVE_MODAL.SHOW));
};

export const hideMoveModal = () => (dispatch) => {
  return dispatch(createAction(constants.MOVE_MODAL.HIDE));
};

export const showAddTagsModal = () => (dispatch) => {
  return dispatch(createAction(constants.ADD_TAGS_MODAL.SHOW));
};

export const hideAddTagsModal = () => (dispatch) => {
  return dispatch(createAction(constants.ADD_TAGS_MODAL.HIDE));
};

export const showRemoveTagsModal = () => (dispatch) => {
  return dispatch(createAction(constants.REMOVE_TAGS_MODAL.SHOW));
};

export const hideRemoveTagsModal = () => (dispatch) => {
  return dispatch(createAction(constants.REMOVE_TAGS_MODAL.HIDE));
};

export const hideQuestionPanel = () => (dispatch) => {
  return dispatch(createAction(constants.QUESTIONS_HIDE_PANEL));
};

export const showTranslateModal = () => (dispatch) => {
  return dispatch(createAction(constants.AUTO_TRANSLATE_MODAL.SHOW));
};

export const hideTranslateModal = () => (dispatch) => {
  return dispatch(createAction(constants.AUTO_TRANSLATE_MODAL.HIDE));
};

export const getCurrentQuestionComments =
  (rethrowOnError = false) =>
  (dispatch, getState) => {
    const {
      questions: {
        current: {
          question,
          meta,
          comments: { pagination },
        },
      },
    } = getState();

    const isMultiLanguage = meta.found;
    const questionId = isMultiLanguage ? meta.id : question.id;

    dispatch(createAction(constants.QUESTION_COMMENTS.REQUEST));

    const func = isMultiLanguage
      ? api.getMetaQuestionComments
      : api.getQuestionComments;

    return func(questionId, pagination)
      .then((items) =>
        dispatch(createAction(constants.QUESTION_COMMENTS.SUCCESS, items)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.QUESTION_COMMENTS.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const createCurrentQuestionComment =
  (data, rethrowOnError = false) =>
  (dispatch, getState) => {
    const {
      questions: {
        current: { question, meta },
      },
    } = getState();

    const isMultiLanguage = meta.found;
    const questionId = isMultiLanguage ? meta.id : question.id;

    dispatch(createAction(constants.QUESTION_COMMENT_CREATE.REQUEST));

    const func = isMultiLanguage
      ? api.createMetaQuestionComment
      : api.createQuestionComment;

    return func(questionId, data)
      .then((comment) => {
        return dispatch(
          createAction(constants.QUESTION_COMMENT_CREATE.SUCCESS, comment),
        );
      })
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.QUESTION_COMMENT_CREATE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const deleteCurrentQuestionComment =
  (commentId, rethrowOnError = false) =>
  (dispatch, getState) => {
    const {
      questions: {
        current: { question, meta },
      },
    } = getState();

    const isMultiLanguage = meta.found;
    const questionId = isMultiLanguage ? meta.id : question.id;

    dispatch(createAction(constants.QUESTION_COMMENT_DELETE.REQUEST));

    const func = isMultiLanguage
      ? api.deleteMetaQuestionComment
      : api.deleteQuestionComment;

    return func(questionId, commentId)
      .then(() =>
        dispatch(createAction(constants.QUESTION_COMMENT_DELETE.SUCCESS)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.QUESTION_COMMENT_DELETE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const questionCommentsPage = (page) => (dispatch) => {
  dispatch(createAction(constants.QUESTION_COMMENTS_PAGINATION.PAGE, page));
  return dispatch(getCurrentQuestionComments());
};

export const addTagsToSelected =
  (tagIds, rethrowOnError = false) =>
  (dispatch, getState) => {
    const {
      questions: {
        list: { items: questions },
      },
    } = getState();
    const selectedQuestionIds = questions
      .filter((item) => item.selected)
      .map((it) => it.id);
    dispatch(createAction(constants.QUESTION_LIST_UPDATE.REQUEST));

    return api
      .bulkAddTags({ ids: selectedQuestionIds, tags: tagIds })
      .then(() =>
        dispatch(createAction(constants.QUESTION_LIST_UPDATE.SUCCESS)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.QUESTION_LIST_UPDATE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const removeTagsFromSelected =
  (tagIds, rethrowOnError = false) =>
  (dispatch, getState) => {
    const {
      questions: {
        list: { items: questions },
      },
    } = getState();
    const selectedQuestionIds = questions
      .filter((item) => item.selected)
      .map((it) => it.id);
    dispatch(createAction(constants.QUESTION_LIST_UPDATE.REQUEST));

    return api
      .bulkRemoveTags({ ids: selectedQuestionIds, tags: tagIds })
      .then(() =>
        dispatch(createAction(constants.QUESTION_LIST_UPDATE.SUCCESS)),
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.QUESTION_LIST_UPDATE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const resetPagination = () => (dispatch) => {
  dispatch(createAction(constants.PAGINATION.PAGE, 0));
  dispatch(createAction(constants.PAGINATION.FILTERS, []));
  dispatch(createAction(constants.PAGINATION.SEARCH, null));
};
