import { paginationActions, requestActions } from "./utils";

export const QUESTIONS_ANALYTICS = requestActions("QuestionsAnalytics/List");
export const PAGINATION = {
  ...paginationActions("QuestionsAnalytics/Paginate"),
  CATEGORY: "QuestionsAnalytics/Paginate/Category",
  GROUP: "QuestionsAnalytics/Paginate/Group",
  LANGUAGE: "QuestionsAnalytics/Paginate/Language",
  SHOW_ALL: "QuestionsAnalytics/Paginate/ShowAll",
};
export const QUESTION_ANALYTICS = requestActions("QuestionAnalytics/Get");
export const QUESTION_ANALYTICS_RESET = "QuestionAnalytics/Reset";
export const SHOW_COLUMNS = "QuestionsAnalytics/ShowColumns";