import {BaseModel} from "./base";
import {ScheduledReport} from "./reports";

const TaskTypes = {
  scheduledReport: "scheduled_report"
}

export class TaskSchedule extends BaseModel  {
  constructor({
    frequency="",
    start_date=null,
    end_date=null,
    weekdays=[]
  } = {}) {
    super();

    this.frequency = frequency;
    this.start_date = start_date;
    this.end_date = end_date;
    this.weekdays = weekdays;
  }
}

export class Task extends BaseModel {
  constructor({
    id=null,
    name="",
    created=null,
    updated=null,
    enabled=true,
    schedule=null,
    args={},
    users=[]
  } = {}) {
    super();

    this.id = id;
    this.name = name;
    this.created = created;
    this.updated = updated;
    this.enabled = enabled;
    this.schedule = new TaskSchedule(schedule || {});
    this.task = TaskTypes.scheduledReport;
    this.args = new ScheduledReport(args || {});
    this.users = users;
  }

  get user_ids() {
    return this.users.map(it => it.id);
  }

  serialize({omit, only} = {}) {
    const serialized = super.serialize({omit, only});

    if (serialized?.users) {
      serialized.users = this.user_ids;
    }

    return serialized;
  }
}